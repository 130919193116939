.analytic-card-wrapper{
    flex-basis: 20%;
    width: 100%;
    @media (max-width: 1280px){
        flex-basis: 25%;
    }
    @media (max-width: 768px){
        flex-basis: 30%;
    }
    @media (max-width: 576px){
        flex-basis: 40%;
    }
    @media (max-width: 450px){
        flex-basis: 60%;
    }
    .analytic-card{
        background: #6F8BDF;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 214px;
        min-height: 100%;
        padding: 10px;
        .icon-wrapper{
            background-color: #3D59AB;
            height: 64px;
            width: 64px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px;
        }
        .analytic-details{
            color: #fff;
            text-align: center;
            .title{
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 0;
                max-width: 181px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
            }
            .sub-title{
                font-size: 14px;
                opacity: 0.72;
                font-weight: 600;
            }
        }
    }
}