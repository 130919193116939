.ant-table-wrapper {
    padding-right: 0;

    .ant-table {
        font-family: 'Lato' !important;

        p {
            font-family: "Lato" !important;
        }

        .ant-table-container {
            .ant-table-content {
                table {
                    .ant-table-thead {
                        th {
                            background-color: #EFEFEF;
                            border-color: #303134;
                            font-size: 18px;
                            font-weight: 600;

                            &::before {
                                display: none;
                            }
                        }
                    }

                    .ant-table-tbody {
                        tr {
                            background-color: #EFEFEF;
                            border-color: #A2A2A2;

                            td {
                                font-weight: 600;
                                font-size: 16px;
                            }

                            &:last-child {
                                border-bottom-left-radius: 6px;
                                border-bottom-right-radius: 6px;
                            }
                        }

                        .ant-table-row {
                            &:last-child {
                                border-radius: 0 0 8px 8px;
                                // outline: auto #efefef;

                                td {
                                    border-bottom-color: transparent;
                                }
                            }
                        }

                        .ant-table-cell {
                            border-radius: unset;
                            border-bottom: 1px solid #A2A2A2;

                            &.ant-table-cell-row-hover {
                                background-color: transparent;
                                border-radius: unset !important;
                            }

                            .phone-details {
                                display: flex;
                                justify-content: space-between;

                                .ant-space-item {
                                    font-size: 16px !important;

                                    p {
                                        font-weight: 400;
                                    }

                                    .options {
                                        height: 40px;
                                        width: 40px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 12px;
                                        background-color: #6F8BDF;
                                        cursor: pointer;
                                        position: relative;

                                        &.active {
                                            background-color: #3D59AB;
                                        }

                                        .option-items {
                                            position: absolute;
                                            right: calc(100% + 20px);
                                            background-color: #fff;
                                            border: 1px solid #2b292d;
                                            padding: 13px 16px;
                                            min-width: 145px;
                                            border-radius: 8px;

                                            .item {
                                                display: flex;
                                                align-items: center;
                                                margin-bottom: 14px;

                                                &:last-of-type {
                                                    margin-bottom: 0;
                                                }

                                                .text {
                                                    font-size: 12px;
                                                    margin-left: 14px;
                                                }

                                                .active-img {
                                                    display: none;
                                                }

                                                &.active-flag {
                                                    .active-img {
                                                        display: unset;
                                                    }

                                                    .img {
                                                        display: none;
                                                    }

                                                    .text {
                                                        color: #6F8BDF;
                                                    }
                                                }

                                                &:hover {
                                                    .active-img {
                                                        display: unset;
                                                    }

                                                    .img {
                                                        display: none;
                                                    }

                                                    .text {
                                                        color: #6F8BDF;
                                                    }
                                                }
                                            }

                                            &::before {
                                                content: '';
                                                display: block;
                                                position: absolute;
                                                background-image: url('../../../assets/images/tooltip-left-icon.svg');
                                                background-position: center;
                                                background-size: 100% 100%;
                                                background-repeat: no-repeat;
                                                left: calc(100% - 12px);
                                                top: 50%;
                                                transform: translateY(-50%);
                                                width: 30px;
                                                height: 30px;
                                            }
                                        }
                                    }
                                }
                                &.user-phone-details{
                                    justify-content: flex-end;
                                }
                            }

                            .name-message-field {
                                display: block;

                                .date {
                                    color: #5B5A78;
                                    font-size: 12px;
                                }

                                p {
                                    color: #303134;
                                    font-weight: 700;
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .radius-btn {
                        min-width: 93px;
                        min-height: 40px;
                    }
                }
            }
        }
    }

    .ant-spin {
        max-height: unset !important;

        .ant-spin-dot {
            font-size: 60px !important;
            width: 40px !important;
            height: 40px !important;

            .ant-spin-dot-item {
                height: 15px !important;
                width: 15px !important;
            }
        }
    }
}