.board-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #6F8BDF;
    border-radius: 13px;
    width: auto;
    max-width: 100%;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    outline: none;
    box-shadow: unset !important;
    border-style: unset;
    font-family: 'Lato';
    min-height: 50px;

    &:active {
        background-color: #6F8BDF !important;
        color: #fff !important;
    }

    &:hover {
        color: white;
        background-color: #6F8BDF;
    }

    &.full-btn {
        width: 100%;
    }

    &.transparent-btn {
        background: transparent;
        color: #6F8BDF;
        border: 2px solid #6F8BDF;

        &:hover {
            color: #6F8BDF;
        }

        &:active {
            background-color: transparent !important;
            color: #6F8BDF !important;
        }
    }

    &.dark-purple {
        background-color: #5B5A78;
        &:active {
            background-color: #5B5A78 !important;
            color: #fff !important;
        }
    }

    &.orange-btn {
        background-color: #F6B21A;
        color: #fff;
        &:active {
            background-color: #F6B21A !important;
            color: #fff !important;
        }
    }

    &.black-btn {
        background-color: #2B292D;
        color: #fff;
        &:active {
            background-color: #2B292D !important;
            color: #fff !important;
        }
    }

    &.radius-btn {
        border-radius: 56px;
        font-size: 16px;
        padding: 8px 30px;
    }

    &.inactive-btn {
        background-color: #5B5A78;
        color: #fff;
        &:active {
            background-color: #5B5A78 !important;
            color: #fff !important;
        }
    }

    &.no-cursor{
        pointer-events: none;
    }

    &.red-btn {
        background-color: #830909;
        &:active {
            background-color: #830909 !important;
            color: #fff !important;
        }
    }
    &.white-btn {
        background-color: #fff !important;
        border-color: #6F8BDF !important;
        color: #6F8BDF !important;
        &:active {
            background-color: #fff !important;
            color: #6F8BDF !important;
            border-color: #6F8BDF !important;
        }
    }

    span {
        img {
            margin-right: 16px;

            @media (max-width: 1366px) {
                margin-right: 10px;
            }
        }
    }

    @media (max-width:1366px) {
        line-height: 18px;
        font-size: 16px;
        min-height: 40px;
    }

    @media (max-width: 1024px) {
        min-height: unset;
        font-size: 14px;
        padding: 8px 16px;
        border-radius: 10px;
    }

    @media (max-width:768px) {
        border-radius: 8px;
        padding: 6px 0;
        font-weight: 500;
        min-height: 34px;
    }
}