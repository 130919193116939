.content-section {
    overflow: hidden;
    &.application-section {
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .container-fluid {
            @media (max-width: 1366px) {
                padding: 0;
            }
        }

        .side-img {
            text-align: right;
            padding-right: 55px;

            // @media (max-width:576px) {
            //     display: none;
            // }

            img {
                @media (max-height:950px) {
                    height: 350px;
                }

                @media (max-height:750px) {
                    height: 300px;
                }

                @media (max-width:576px) {
                    height: 200px;
                }
            }
        }

        .application-details-section {
            max-width: 100%;

            .applicant-wrapper {
                h3 {
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 30px;

                    @media (max-width: 1366px) {
                        margin-bottom: 10px;
                    }

                    @media (max-width:1024px) {
                        font-size: 20px;
                        margin-left: 20px;
                        margin-bottom: 20px;
                    }
                }

                h4 {
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 0;

                    @media (max-width:1024px) {
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }

                .download-sample-row {
                    margin-top: 30px;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    scrollbar-width: none;

                    @media (max-width: 1366px) {
                        margin-top: 20px;
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .download-sample-card-wrapper {
                        flex-basis: 12.5%;
                        @media (max-width: 1024px) {
                            flex-basis: 19.5%;
                        }
                        @media (max-width: 576px) {
                            flex-basis: 30%;
                        }


                        .download-sample-card {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            min-height: 160px;
                            background-color: #EFEFEF;
                            border: 1px solid #6F8BDF;
                            border-radius: 8px;
                            cursor: pointer;
                            @media (max-width: 1024px) {
                                margin-left: 15px;
                                padding: 10px;
                            }

                            .title {
                                text-align: center;
                                font-size: 16px;
                                margin-top: 8px;
                                font-weight: 600;

                                @media (min-width: 1366px) {
                                    font-size: 13px;
                                    line-height: 17px;
                                }
                                @media (max-width: 1024px) {
                                    font-size: 14px;
                                    line-height: 12px;
                                    
                                }
                               
                            }
                        }
                    }
                }


                .button-wrapper {

                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;


                    @media (max-width:1024px) {

                        margin-left: 20px;

                    }

                    .board-btn {
                        min-width: 248px;
                        margin-top: 42px;

                        @media (max-width: 1366px) {
                            min-width: 220px;
                        }

                        @media (max-width:1024px) {
                            min-width: 200px;
                        }

                        &.orange-btn {
                            margin: 0 30px;
                            margin-top: 42px !important;

                            @media (max-width: 1366px) {
                                margin: 0 20px;
                            }
                            @media (max-width: 1024px) {
                                margin: 0 10px;
                            }
                            @media (max-width: 576px) {
                                margin: 0 0px;
                            }
                        }
                    }
                }
            }
        }

    }
}