.warning-modal{
    .warning-modal-body{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
        position: relative;
        .close-icon{
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            z-index: 9;
        }
        .warning-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            h3{
                margin-top: 28px;
                margin-bottom: 0;
                font-family: 'PT Serif';
                font-weight: 700;
            }
            .button-wrapper{
                display: flex;
                justify-content: center;
                margin-top: 60px;
                @media (max-width: 1366px){
                    margin-top: 30px;
                }
                .board-btn {
                    min-width: 281px;
                }
            }
        }
    }
}