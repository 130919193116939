.perspective-section {
    padding-bottom: 0;
    position: relative;

    @media (max-width: 576px) {
        padding: 37px 3px 0;
    }

    .chapter-button-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .see-more {
            width: 150px;
            border-radius: 13px;
            background-color: #6F8BDF;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #EFEFEF;
            margin: 0 10px;
            cursor: pointer;
            font-family: "Lato";
            min-height: 50px;
            font-weight: 500;

            img {
                margin-left: 5px;
                width: auto;
            }
        }
    }

    .perspective-section-container {
        
        .top-actions{
            margin-bottom: 32px;
            .button-wrapper{
                gap: 8px;
            }
        }

        .perspective-details-left,
        .perspective-details-right {
            font-weight: 400;
            color: #5B5A78;
            line-height: 21px;
            // max-width: 573px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }

            // @media (min-width:1600px) {
            //     width: 40%;
            //     max-width: 100%;
            // }

            .page-number {
                text-align: center;
                color: #5B5A78;
                font-weight: 700;
                margin-top: 10px;
            }

            p {
                // text-align: justify;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
            .img-row{
                display: flex;
                flex-wrap: wrap;
                img{
                    width: 95%;
                    height: 216px;
                    object-fit: cover;
                    border-radius: 16px;
                }
            }
        }

        .perspective-details-right {
            margin: 0 auto;

            @media (max-width: 768px) {
                margin: 20px 0;
            }

            .perspective-details {
                .image-wrapper {
                    .image {
                        img {
                            width: 100%;
                            max-height: 216px;
                            object-fit: cover;
                            border-radius: 16px;
                        }
                    }
                }
            }

            .image-wrapper {
                .image {
                    min-height: 216px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #EFEFEF;
                    border-radius: 16px;
                    margin-bottom: 16px;
                    margin-top: 8px;
                }

                .img-subtitle {
                    font-style: italic;
                    color: #5B5A78;
                    text-align: center;
                }
            }

            .button-wrapper {
                margin: 18px 0;
                display: flex;
                justify-content: space-between;

                @media (max-width: 1200px) {
                    flex-direction: column;
                }

                @media (max-width: 768px) {
                    flex-direction: unset;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                }

                @media (min-width:1600px) {
                    justify-content: unset;
                }

                .board-btn {
                    flex-basis: 48%;

                    &.dark-purple {
                        @media (max-width: 1200px) {
                            margin-top: 15px;
                        }

                        @media (max-width: 768px) {
                            margin-top: 0;
                        }

                        @media (max-width: 576px) {
                            margin-top: 15px;
                        }

                        @media (min-width:1600px) {
                            margin-left: 25px;
                        }
                    }

                    @media (max-width:1440px) and (max-height: 900px) {
                        min-width: 200px;
                    }
                }
            }
        }
    }
}

.reading-controls-group {
    position: fixed;
    // top: 50%;
    bottom: 70px;
    // transform: translateY(-50%);
    width: 100%;

    @media (max-width: 768px) {
        transform: unset;
        top: 100% !important;
    }

    .control {
        background-color: #6F8BDF;
        height: 50px;
        width: 50px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        cursor: pointer;
        // opacity: 0;
        transition: all 0.5s;

        @media (max-width: 992px) {
            height: 40px !important;
            width: 40px !important;
        }

        &.left-control {
            left: calc(100% - 430px);
            right: unset;
            bottom: 10px;

            @media (max-width: 1366px) {
                left: calc(100% - 328px);
            }

            // @media (max-width: 768px) {
            //     right: 15px !important;
            //     left: unset !important;
            // }
            &.toggle-right-control {
                left: calc(100% - 205px);

                @media (max-width: 1366px) {
                    left: calc(100% - 158px);
                }
            }
        }

        &.right-control {
            left: calc(100% - 430px);
            right: unset;

            @media (max-width: 1366px) {
                left: calc(100% - 328px);
            }

            // @media (max-width: 768px) {
            //     right: 15px !important;
            //     left: unset !important;
            // }
            &.toggle-right-control {
                left: calc(100% - 205px);

                @media (max-width: 1366px) {
                    left: calc(100% - 158px);
                }
            }
        }
    }
}