.content-section {
    &.favorite-section {
        // height: 100%;
        padding-right: 30px !important;
        @media (max-width: 1366px){
            padding-right: 25px !important;
        }
        @media (max-width: 768px){
            padding-right: 15px !important;
        }
        .author-wrapper {
            // height: 100%;
            // .home-card-container{
            //     height: 100%;
            // }
            .home-card-row {
                &.author-card-row {
                    // height: 100%;
                    flex-wrap: wrap;
                    .profile-card-wrapper {
                        margin-bottom: 40px;
                        flex-basis: 12.4%;
                        @media (max-width: 1200px){
                            flex-basis: 16.66%;
                        }
                        @media (max-width: 1024px){
                            flex-basis: 20%;
                        }
                        @media (max-width: 768px){
                            flex-basis: 25%;
                        }
                        @media (max-width: 576px){
                            flex-basis: 33.33%;
                        }
                        .profile-card{
                            animation: zoomIn 0.6s forwards;
                        }
                    }

                    .card-data{
                        flex-basis: 12.5%;
                        margin-bottom: 20px;
                        @media (max-width: 1280px){
                            flex-basis: 14.28%;
                        }
                        @media (max-width: 1200px){
                            flex-basis: 16.66%;
                        }
                        @media (max-width: 1100px){
                            flex-basis: 20%;
                        }
                        @media (max-width: 768px){
                            flex-basis: 20%;
                        }
                        @media (max-width: 576px){
                            flex-basis: 25%;
                        }
                        @media (max-width: 450px){
                            flex-basis: 33.33%;
                        }
                        .slider-card {
                            border-radius: 16px;
                            width: 100%;
                            animation: zoomIn 0.6s forwards;
                        }
                    }

                }
            }
        }
    }
}

// Animation
@keyframes zoomIn {
    0% {
       transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}