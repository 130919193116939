.chat-messages-section {
    min-width: calc(100% - 330px);
    width: 100%;
    @media (max-width: 1366px){
        min-width: calc(100% - 300px);
    }
    @media (max-width: 1200px){
        min-width: calc(100% - 270px);
    }
    @media (max-width: 992px){
        min-width: calc(100% - 250px);
    }
    @media (max-width: 768px){
        min-width: calc(100% - 50px);
    }
    @media (max-width: 400px){
        min-width: calc(100% - 40px);
    }

    .chat-window {
        background-color: transparent;
        padding: 10px 30px;
        padding-left: 0;
        @media (max-width: 768px){
            padding: 10px 20px;
        }
        @media (max-width: 576px){
            padding: 10px 15px;
        }
    }

    .chat-window-wrapper {
        padding-left: 80px;

        @media (max-width: 768px){
            padding-left: 0;
        }

        .message-item {
            margin: 30px 0 0;
            display: flex;
            flex-direction: column;

            @media (max-width: 576px){
                margin: 20px 0 0;
            }

            .message-info {
                display: flex;
                position: relative;
                align-items: center;

                .img {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    overflow: hidden;
                    position: absolute;
                    top: 0px;
                    left: -50px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .time {
                    margin-bottom: 8px;
                    @media (max-width: 576px){
                     font-size: 10px;
                     margin-bottom: 4px;   
                    }
                }
            }

            .message-item-bubble {
                min-width: 100px;
                max-width: calc(50% + 50px);
                padding: 15px;
                border-radius: 10px;
                @media (max-width: 576px){
                    font-size: 14px;
                    padding: 10px;
                    border-radius: 6px;
                }
                @media (max-width: 400px){
                    font-size: 12px;
                    padding: 8px;
                }
            }

            &.own-message-item {
                align-items: flex-end;
            }
        }
        .create-chat{
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            @media (max-width: 576px){
                font-size: 12px;
            }
        }
    }
}