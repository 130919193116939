.top-grt-card-wrapper {
    margin-top: 24px;

    .top-grt-card {
        background-color: #efefef;
        padding-top: 24px;
        border-radius: 16px;

        .grt-card-title {
            padding: 0 24px;

            h3 {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 30px;
            }
        }

        .grt-card-body {
            padding: 0 24px;
            max-height: 350px;
            overflow-y: auto;
            scrollbar-width: none;
            .likes-skele-body{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .like-detail{
                    display: flex;
                    gap: 10px;
                    .name-sub{
                        display: flex;
                        gap: 10px
                    }
                }

            }
            @media (max-width: 576px){
                padding: 0 15px;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .grt-avatar-details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 18px;

                .left-details {
                    display: flex;
                    align-items: center;

                    .avatar {
                        img {
                            height: 48px;
                            width: 48px;
                            margin-right: 6px;

                            @media (max-width: 576px) {
                                height: 40px;
                                width: 40px;
                            }
                        }
                    }

                    .avatar-details {
                        .name {
                            font-weight: 600;
                            margin-bottom: 6px;
                        }

                        .action-wrapper {
                            display: flex;
                            color: #F6B21A;

                            @media (max-width: 576px) {
                                flex-direction: column;
                            }

                            .action {
                                display: flex;
                                align-items: center;
                                margin-right: 15px;

                                @media (max-width: 576px) {
                                    margin-top: 8px;
                                }

                                img {
                                    margin-right: 6px;
                                }

                                p {
                                    font-family: 'Inter';
                                    margin-bottom: 0;
                                    font-size: 12px;
                                    font-weight: 600;
                                    @media (max-width: 576px){
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .right-details {
                    .number {
                        font-size: 24px;
                        font-weight: 600;
                        @media (max-width: 576px){
                            font-size: 18px;
                        }
                    }
                }
            }

            .no-data-div {
                min-height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;

                .text-info-span {
                    font-size: large;
                }
            }
        }

        .grt-card-footer {
            padding: 24px;
            background-color: #6F8BDF;
            border-radius: 0px 0px 16px 16px;
            @media (max-width: 576px){
                padding: 15px;
            }

            .footer-action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                p {
                    margin-bottom: 0;
                    color: #fff;
                }
            }
        }
    }
}