.create-EditChracters-modal {
    .modal-dialog {
        width: 726px;
        max-width: 100%;

        @media (max-width: 768px) {
            margin: 15px auto;
            max-width: 90%;
        }

        @media (min-width:1920px) {
            width: 1440px;
        }

    }

    .EditChracters-modal-body {

        padding: 50px 0;
        position: relative;

        @media (max-width: 1366px) {
            padding: 25px 0;
        }

        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            z-index: 9;
        }

        .EditChracters-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h3 {
                margin: 35px 0;
                font-family: 'PT Serif';
                font-weight: 700;
                font-size: 32px;

                @media (max-width: 1366px) {
                    margin: 15px 0;
                    font-size: 26px;
                }
            }

            .top-img {
                @media (max-width: 1366px) {
                    height: 120px;
                }
            }

            .submission-process-details {
                width: 560px;
                max-width: 100%;
                margin: auto;

                @media (max-width: 992px) {
                    width: 90%;
                }

                @media (min-width: 1920px) {
                    width: 1100px;
                }

                .input-field {
                    label {
                        display: none;
                    }
                }




                .button-wrapper {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;

                    @media (max-width: 1366px) {
                        margin-top: 20px;
                    }

                    .board-btn {
                        min-width: 281px;
                    }

                    &.bottom-button-wrapper {
                        margin-top: 50px;

                        @media (max-width: 1366px) {
                            margin-top: 25px;
                        }
                    }

                }

            }


        }

    }

    .EditChracters-details {
        margin-right: 30px;
        margin-left: 30px;

        .select-user {
            .ant-select-selection-search-input {
                height: 100%;
                margin-left: 40px;
            }
        }
    }

    .field-wrapper {
        max-height: 250px;
        overflow: auto;
        scrollbar-width: none;

        @media (max-width: 1366px) {
            max-height: 150px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .field-row {
            display: flex;
            justify-content: space-between;

            @media (max-width: 576px) {
                justify-content: unset;
            }

            .field-column-wrapper {
                display: flex;
                width: calc(100% - 2px);
                flex-direction: column;

                @media (max-width: 1366px) {
                    width: calc(100% - 60px);
                }

                @media (max-width: 576px) {
                    width: calc(100% - 45px);
                }
            }

            .field-column {
                display: flex;
                flex-basis: 100%;
                justify-content: space-between;
                margin-left: 30px;
                margin-right: 30px;

                &.added-field {
                    margin-top: 20px;
                }

                @media (max-width: 576px) {
                    .ant-select-selector {
                        border-radius: 5px !important;
                    }
                }

                .left {
                    flex-basis: calc(50% + 26px);
                    width: 100%;

                    @media (max-width: 1366px) {
                        flex-basis: calc(50% + 21px);
                    }

                    @media (max-width: 992px) {
                        flex-basis: calc(50% + 12px);
                    }

                    @media (max-width: 768px) {
                        flex-basis: calc(50% + 17px);
                    }

                    @media (max-width: 576px) {
                        flex-basis: calc(50% + 13px);
                    }

                    @media (max-width: 450px) {
                        flex-basis: calc(50% + 17px);
                    }

                    @media (max-width: 330px) {
                        flex-basis: calc(50% + 18px);
                    }
                }

                .right {
                    flex-basis: calc(50% - 39px);


                    @media (max-width: 1366px) {
                        flex-basis: calc(50% - 39px);
                    }

                    @media (max-width: 992px) {
                        flex-basis: calc(50% - 47px);
                    }

                    @media (max-width: 768px) {
                        flex-basis: calc(50% - 44px);
                    }

                    @media (max-width: 576px) {
                        flex-basis: calc(50% - 32px);
                    }

                    @media (max-width: 450px) {
                        flex-basis: calc(50% - 31px);
                    }
                }
            }


            .left {
                display: flex;

                .board-select-wrapper {

                    .ant-select {
                        width: 100% !important;
                    }

                    .ant-select-selection-item {
                        position: relative;

                        color: #a2a2a2;
                        font-family: 'Lato' !important;

                        &::before {
                            background-image: url('../../assets/images/role.svg');

                            @media (max-width: 1366px) {
                                height: 14px;
                                width: 14px;
                                top: 8px;
                            }
                        }
                    }
                }
            }

            label {
                // display: none;
            }

        }
    }

    .button-Submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

    }

}