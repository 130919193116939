.content-section {

    &.feedback-section {
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 768px){
            justify-content: unset;
        }

        @media (max-width: 576px){
            padding-left: 10px !important;
        }

        .container-fluid {
            @media (max-width: 1366px) {
                padding: 0;
            }
        }

        .side-img {
            text-align: right;
            padding-right: 55px;

            @media (max-width:768px) {
                padding-right: 35px;
                margin-top: 70px;
            }

            img {
                @media (max-height:950px) {
                    height: 350px;

                }

                @media (max-width:1024px) {
                    height: 350px;
                }

                @media (max-width:768px) {
                    height: 270px;
                }

                @media (max-width:450px) {
                    height: 230px;
                }
            }
        }

        .feedback-details-section {
            max-width: 90%;
            padding-left: 10px;

            @media (max-width: 576px){
                padding-left: 3px;
            }

            .information-wrapper {
                h3 {
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 20px;

                    @media (max-width:1024px) {
                        font-size: 20px;
                    }
                }

                .input-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    .input-field {
                        flex-basis: 49%;
                        width: 100%;

                        @media (max-width:1024px) {
                            flex-basis: unset;
                        }
                        input {
                            margin-bottom: 10px;

                        }
                    }
                }


                .button-wrapper {
                    margin-top: 50px;

                    .board-btn {
                        min-width: 281px;

                        @media (max-width:1024px) {

                            min-width: 200px;

                        }
                    }
                }

                .text-area-field {
                    width: 100%;
                    position: relative;

                    @media (max-width:1024px) {
                        flex-basis: unset;
                        max-width: unset;
                        margin: unset;

                    }

                    span {
                        position: absolute;
                        top: 6px;
                        left: 16px;

                        @media (max-width:1366px) {
                            top: 4px;
                        }

                        @media (max-width:1366px) {
                            img {
                                height: 14px;

                            }
                        }
                    }

                    textarea {
                        width: 49%;
                        resize: none;
                        min-height: 116px;
                        max-height: 116px;
                        height: 100%;
                        background-color: #EFEFEF;
                        border-radius: 8px;
                        border: none;
                        padding: 6px 52px 6px 52px;
                        scrollbar-width: none;

                        @media (max-width:1024px) {
                            width: 100%;
                        }

                        &:focus {
                            outline: none;
                            border: none;
                            box-shadow: none;
                        }

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        &::placeholder {
                            font-family: 'Lato';
                            font-weight: 600;
                            color: #A2A2A2;

                            @media (max-width:1366px) {
                                font-size: 14px;
                            }
                        }
                    }

                    .feedback-error {
                        width: 100%;
                        padding: 0 10px;
                        color: red;
                        font-size: 12px;
                    }
                }
            }
        }

    }
}