.feature-novel-card-wrapper {
    flex-basis: 43%;
    max-width: 403px;
    display: flex;

    
    @media (max-width:1156px) {
        flex-basis: 50%;
        min-width: unset;
    }

    @media (max-width:650px) {
        flex-basis: 100%;
    }

    .feature-novel-card {
        display: flex;

        .feature-novel-card-left {
            display: flex;
            background-color: #A2A2A2;
            min-height: 247px;
            max-width: 217px;
            width: 100%;
            border-radius: 16px;
            padding: 0 15px;

            @media (max-width: 1366px){
                max-height: 300px;
            }

            .feature-novel-card-img {
                width: calc(100% - 20px);
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                @media (max-width: 576px){
                    width: calc(100% - 30px);
                }

                img {
                    opacity: 1;
                    height: 100%;
                    min-width: 197px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 16px 0 0 16px;
                    @media (max-width: 576px){
                        min-width: 140px;
                    }
                }
            }
        }

        .feature-novel-card-right {
            margin-left: -44px;
            background-color: #efefef;
            box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
            padding: 24px 0;
            min-height: 247px;
            position: relative;
            width: 326px;

            @media (max-width:1366px) {
                padding: 15px 0;
                max-height: 300px;
            }
            @media (max-width:1200px) {
               width: 260px;
            }
            @media (max-width:1100px) {
               width: 230px;
            }
            @media (max-width:1024px) {
               width: 210px;
            }
            @media (max-width:992px) {
               width: 230px;
            }
            @media (max-width:768px) {
               width: unset;
               min-width: 200px;
            }

            @media (min-width:1680px) {
                width: 440px;
            }
            @media (min-width:1920px) {
                min-width: 480px;
                width: 100%;
            }

            .text-details {
                display: flex;
                flex-direction: column;
                height: 100%;
                max-width: 90%;
                margin: auto;
            }

            .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                .novel-name {
                    color: #2B292D;
                    font-weight: 700;
                    font-size: 24px;
                    max-width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    @media (max-width:1366px) {
                        font-size: 20px;
                    }
                }

                img {
                    cursor: pointer;
                }

            }

            .description {
                p {
                    color: #5B5A78;
                    line-height: 20px;
                    margin-bottom: 0;
                    min-height: 100px;
                    max-height: 160px;
                    padding-right: 20px;
                    overflow: auto;
                    scrollbar-width: thin;
                    max-height: 120px;

                    @media (max-width:1366px) {
                        font-size: 14px;
                        line-height: 19px;
                    }
                    @media (max-width:576px) {
                        font-size: 12px;
                        line-height: 15px;
                        max-height: 80px;
                        min-height: unset;
                    }

                    &::-webkit-scrollbar {
                        background-color: #5B5A78;
                        width: 6px;
                        border-radius: 4px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #6F8BDF;
                        border-radius: 4px;
                        width: 6px;
                    }
                }
            }

            .bottom-details-wrapper {
                // position: absolute;
                bottom: 20px;

                .read-details-wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 15px;
                    margin-bottom: 20px;

                    .icon-wrapper {
                        &:not(:first-of-type) {
                            padding-left: 10px;

                            @media (max-width:1350px) {
                                padding-left: 5px;
                            }
                        }

                        span {
                            margin-left: 5px;
                            color: #F6B21A;
                            font-size: 12px;
                            font-weight: 600;
                            font-family: 'Inter';
                            letter-spacing: -0.03em;

                            @media (max-width:1366px) {
                                font-size: 12px;
                                margin-left: 5px;
                            }

                            @media (max-width:1100px) {
                                font-size: 8px;
                                margin-left: 3px;
                            }
                        }
                    }
                }

                .action-btn {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .text {
                        color: #6F8BDF;
                        font-weight: 700;
                        margin-bottom: 0;

                        @media (max-width:1366px) {
                            font-size: 14px;
                        }
                        @media (max-width:576px) {
                            font-size: 12px;
                        }
                    }

                    .image-wrapper {
                        margin-left: 12px;
                        margin-top: -3px;

                        @media (max-width:1366px) {
                            img {
                                margin-top: -2px;
                            }
                        }
                    }
                }
            }

        }
    }
}