.date-picker-space {
    max-height: 52px;

    @media (max-width: 1600px) and (max-height: 800px) {
        max-height: 46px;
    }

    @media (max-width:1366px) {
        max-height: 40px;
    }
    .ant-space{
        width: 100%;
    }

    .ant-picker {
        height: 52px;
        background-color: #EFEFEF;
        color: #A2A2A2;
        border: none;
        width: 100%;
        font-family: 'Lato';
        font-size: 16px;
        font-weight: 600;

        @media (max-width: 1600px) and (max-height: 800px) {
            height: 46px;
        }

        @media (max-width:1366px) {
            font-size: 14px;
            height: 38px;
        }
        @media (max-width: 576px) {
            font-size: 11px;
            height: 35px;
        }

        // @media (min-width: 1600px) {
        //     width: 350px;
        // }

        // @media (min-width: 1680px) {
        //     width: 380px;
        // }

        .ant-picker-input {
            input {
                padding-left: 41px;
                position: relative;

                &::placeholder {
                    font-size: 16px;
                    color: #A2A2A2;
                    font-weight: 600;

                    @media (max-width:1366px) {
                        font-size: 14px !important;
                    }
                    @media (max-width:576px) {
                        font-size: 11px !important;
                    }
                }

                &:focus {
                    outline: none;
                    box-shadow: unset !important;
                    background-color: #efefef;
                }
            }

            &::before {
                content: '';
                left: 6px;
                top: 0px;
                position: absolute;
                background-image: url('../../assets/images/calendar.svg');
                background-position: center;
                background-size: cover;
                height: 21px;
                width: 21px;
                z-index: 999;

                @media (max-width: 1366px) {
                    height: 14px;
                    width: 14px;
                    top: 4px;
                }
                @media (max-width: 576px) {
                    height: 10px;
                    width: 10px;
                    top: 7px;
                }
            }
        }
    }
    .ant-picker-focused{
        box-shadow: unset !important;
    }
}