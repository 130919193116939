.chat-sidebar-section {
    width: 330px;
    max-width: 100%;
    padding: 26px 0;
    border-right: 1px solid #6F8BDF;
    position: relative;
    transition: all 0.2s;
    height: calc(100vh - 91px);

    @media (max-width: 1366px) {
        width: 300px;
        padding: 20px 0;
        height: calc(100vh - 89px);
    }

    @media (max-width: 1280px) {
        height: calc(100vh - 81px);
    }

    @media (max-width: 1200px) {
        width: 270px;
    }

    @media (max-width: 992px) {
        width: 250px;
    }

    .no-chat{
        display: flex;
    }

    .close-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        display: none;

        img {
            height: 15px;
            width: 15px;
        }
    }

    .chat-toggle {
        display: none;
    }

    @media (max-width: 768px) {
        width: 50px;
        padding: 0;

        .spinner-border {
            display: none;
        }

        .chat-sidebar-wrapper {
            .chat-toggle {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
                cursor: pointer;

                img {
                    height: 30px;
                    width: 30px;
                }
            }

            .search-wrapper,
            .channel-list-wrapper,
            .no-channel,
            .no-chat {
                display: none;
            }
        }

        &.fixed-chat-sidebar {
            position: fixed;
            width: 250px;
            left: 38px;
            background-color: #fff;
            top: 80px;
            height: calc(100vh - 80px);
            z-index: 99;
            display: unset;
            padding: 20px;
            transition: all 0.2s;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 100%;
                background-color: rgba($color: #000000, $alpha: 0.5);
                height: 100%;
                width: 100vw;
            }

            .close-icon {
                display: flex;
            }

            .chat-toggle {
                display: none;
            }

            .chat-sidebar-wrapper {

                .channel-list-wrapper,
                .search-wrapper,
                .no-channel,
                .no-chat {
                    display: block;
                }
            }

            .spinner-border {
                display: inline-block;
            }
        }
    }

    @media (max-width: 576px) {
        height: calc(100vh - 65px);

        &.fixed-chat-sidebar {
            top: 114px;
            height: calc(100vh - 65px);
        }
    }

    @media (max-width: 430px){
        &.fixed-chat-sidebar{
            top: 111px;
        }
    }

    @media (max-width: 400px) {
        width: 40px;
        height: calc(100vh - 60px);

        &.fixed-chat-sidebar {
            top: 101px;
            padding: 15px;
            width: 220px;
        }

        .chat-sidebar-wrapper {
            .chat-toggle {
                margin-top: 20px;

                img {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    .chat-sidebar-wrapper {
        .suggested-wrapper {
            display: none;
        }

        .search-wrapper {
            margin-top: 0;
            margin-bottom: 30px;
            padding: 0 25px;

            @media (max-width: 1366px){
                padding: 0 20px;
            }

            @media (max-width: 992px) {
                margin-bottom: 20px;

                .search-field {
                    .input-field {
                        input {
                            padding-right: 10px;
                        }
                    }
                }
            }

            @media (max-width: 768px) {
                margin-top: 10px;
            }
        }

        .chat-sidebar-message-wrapper {
            color: #2B292D;

            .selected-user-wrapper {
                display: none;
            }

            .channel-list-wrapper {
                height: calc(100vh - 200px);
                max-height: 100%;
                overflow-y: auto;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                @media (max-width: 576px) {
                    height: calc(100vh - 165px);
                }

                .channel-lists {
                    display: flex;
                    flex-direction: column;

                    .channel-list-item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 14px;
                        cursor: pointer;
                        padding: 10px 10px 10px 25px;
                        transition: all 0.2s;
                        @media (max-width: 1366px){
                            padding: 10px 10px 10px 20px;
                        }

                        .list-details {
                            display: flex;
                            align-items: center;

                            .img-wrapper {
                                height: 48px;
                                width: 48px;
                                border-radius: 50%;
                                overflow: hidden;

                                @media (max-width:1366px) {
                                    height: 36px;
                                    width: 36px;
                                    min-width: unset;
                                }

                                @media (max-width:1200px) {
                                    height: 30px;
                                    width: 30px;
                                }

                                img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }

                            .list-item-details {
                                margin-left: 16px;
                                display: flex;
                                flex-direction: column;
                                align-self: center;

                                @media (max-width: 576px) {
                                    margin-left: 10px;
                                }

                                h3 {
                                    font-size: 14px;
                                    font-weight: 600;
                                    margin-bottom: 0;
                                    max-width: 160px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;

                                    @media (max-width: 1366px){
                                        max-width: 140px;
                                    }

                                    @media (max-width: 992px) {
                                        font-size: 12px;
                                    }
                                }

                                p {
                                    color: #5B5A78;
                                    margin-bottom: 0;
                                    font-size: 14px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 150px;

                                    @media (max-width: 1200px) {
                                        font-size: 12px;
                                        max-width: 130px;
                                    }

                                    @media (max-width: 992px) {
                                        font-size: 10px;
                                    }

                                }
                            }
                        }

                        .updated-time {
                            font-size: 12px;
                            color: #5B5A78;
                            margin-top: 4px;

                            @media (max-width: 1200px) {
                                font-size: 10px;
                            }
                        }
                        &.selected-chat{
                            background-color: rgba($color: #a2a2a2, $alpha: 0.5);
                        }
                    }
                }
            }



            .pn-channel {
                padding: 0;
                margin-bottom: 24px;

                &.pn-channel--active {
                    background-color: #efefef;

                    &:hover {
                        background-color: #efefef;
                    }
                }

                &:hover {
                    background-color: transparent;
                }

                .pn-channel__title {
                    .pn-channel__name {
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 0;
                        color: #2B292D;
                    }

                    .pn-channel__description {
                        color: #5B5A78;
                        margin-bottom: 0;
                        font-size: 14px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 180px;
                        padding-top: 4px;
                    }
                }

                .sender-wrapper {
                    display: flex;
                    align-items: flex-start;

                    .img-wrapper {
                        height: 48px;
                        width: 48px;
                        border-radius: 50%;
                        overflow: hidden;

                        @media (max-width:1366px) {
                            height: 36px;
                            width: 36px;
                        }

                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    .sender-details {
                        margin-left: 16px;
                        display: flex;
                        flex-direction: column;
                        align-self: center;

                        h3 {
                            font-size: 14px;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        p {
                            color: #5B5A78;
                            margin-bottom: 0;
                            font-size: 14px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 180px;
                        }
                    }
                }
            }

            .time {
                font-size: 12px;
                color: #5B5A78;
            }
        }
    }
}