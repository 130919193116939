.content-section {
    &.admin-notification-section {
        padding-right: 20px !important;
        @media (max-width: 1280px) {
            padding-right: 10px !important;
        }
        .admin-notification-wrapper {
            .admin-notification-container {
                .admin-notification-row {
                    .table-wrapper {
                        .notification-title-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 20px;

                            .title-wrapper {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                max-width: 220px;
                                width: 100%;

                                h3 {
                                    font-size: 24px;
                                    font-weight: 700;
                                    margin-bottom: 0;
                                }

                                .filter-wrapper {
                                    border: 1px solid #2B292D;
                                    height: 50px;
                                    width: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 12px;
                                    cursor: pointer;
                                    position: relative;

                                    @media (max-width: 1366px) {
                                        height: 40px;
                                        width: 40px;
                                        border-radius: 8px;
                                    }

                                    .active-icon {
                                        display: none;
                                    }

                                    &.active {
                                        border-color: #6F8BDF;

                                        .icon {
                                            display: none;
                                        }

                                        .active-icon {
                                            display: block;
                                        }
                                    }

                                    .option-items {
                                        position: absolute;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        top: calc(100% + 15px);
                                        background-color: #fff;
                                        border: 1px solid #2b292d;
                                        padding: 13px 16px;
                                        min-width: 133px;
                                        border-radius: 8px;
                                        z-index: 9;

                                        .item {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 14px;

                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }

                                            label {
                                                font-size: 12px;
                                            }
                                        }

                                        &::before {
                                            content: '';
                                            display: block;
                                            position: absolute;
                                            background-image: url('../../../assets/images/tooltip-left-icon.svg');
                                            background-position: center;
                                            background-size: 100% 100%;
                                            background-repeat: no-repeat;
                                            left: 50%;
                                            bottom: calc(100% - 12px);
                                            transform: translateX(-50%) rotate(-90deg);
                                            width: 30px;
                                            height: 30px;
                                        }
                                    }
                                }
                            }

                            .button-wrapper {
                                .board-btn {
                                    min-width: 276px;
                                    @media (max-width: 992px) {
                                        min-height: 40px;
                                        min-width: 236px;
                                    }
                            
                                    @media (max-width: 576px) {
                                        min-width: 200px;
                                    }
                            
                                    @media (max-width: 400px) {
                                        min-width: 180px;
                                        min-height: 35px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.push-notification-header {
    .search-wrapper {
        min-width: 276px;
        @media (max-width: 992px) {
            min-width: unset;
        }
    }
}