.board-select-wrapper {
  height: 50px;
  width: 100%;
  display: flex;
  z-index: 9999;
  @media (max-width: 1366px) {
    height: 40px;
  }
  @media (max-width: 576px) {
    height: 35px;
  }

  .ant-select-selection-placeholder {
    color: #a2a2a2;
    font-weight: 600;
    font-family: 'Lato';
  }

  .ant-select,
  .ant-select-selector {
    height: 100% !important;
    align-items: center;
    border: none !important;
    border-radius: 8px;
    background-color: #efefef !important;
  }

  .ant-select {
    width: 100% !important;
    &.ant-select-allow-clear{
      &.ant-select-show-arrow{
        &:hover{
          .ant-select-arrow{
            display: none;
          }
          .ant-select-selection-search{
            &::after{
              display: none;
            }
          }
          .ant-select-clear{
            background-color: transparent;
          }
        }
      }
    }
  }

  .ant-select-selection-item {
    position: relative;
    padding-left: 44px !important;

    &::before {
      content: '';
      left: 6px;
      top: 7px;
      position: absolute;
      background-image: url('../../assets/images/role.svg');
      background-position: center;
      background-size: cover;
      height: 20px;
      width: 20px;
      z-index: 999;
      @media (max-width: 1366px) {
        height: 14px;
        width: 14px;
      }
    }
  }

  .ant-select-item {
    margin-bottom: 5px;
  }
  

  .ant-select-selection-search {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-image: url('../../assets/images/down-icon.svg');
      background-position: center;
      background-size: 100% 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 9px;
      width: 9px;
      right: 0;
      z-index: 999;
    }
  }

  .ant-select-selection-placeholder {
    position: relative;
    padding-left: 44px !important;
    font-family: 'Lato' !important;
    color: #A2A2A2;
    font-weight: 600;
    @media (max-width: 576px){
      font-size: 11px;
      padding-left: 30px !important;
    }

    &::before {
      content: '';
      left: 6px;
      top: 7px;
      position: absolute;
      background-image: url('../../assets/images/role.svg');
      background-position: center;
      background-size: cover;
      height: 20px;
      width: 20px;
      z-index: 999;
      @media (max-width: 1366px) {
        height: 14px;
        width: 14px;
      }
      @media (max-width: 576px) {
        height: 10px;
        width: 10px;
        top: 9px;
      }
    }
  }
}

.ant-select-dropdown{z-index: 99999999 !important;}