.workroom-card-wrapper {
    flex-basis: max-content;
    max-width: 403px;

    @media (max-width: 1180px) {
        flex-basis: 40%;
    }

    @media (max-width: 768px) {
        flex-basis: 52%;
    }

    @media (max-width: 576px) {
        flex-basis: 70%;
    }

    @media (max-width: 450px) {
        flex-basis: 80%;
    }

    .workroom-card {
        display: flex;

        .workroom-card-left {
            display: flex;
            background-color: #a2a2a2;
            max-width: 217px;
            width: 100%;
            border-radius: 16px;
            height: 247px;
            max-height: 100%;

            @media (max-width: 576px) {
                height: 217px;
            }

            .workroom-card-img {
                // width: calc(100% - 44px);
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 16px 0 0 16px;
                    object-fit: cover;
                }

                &.center-img {
                    width: calc(100% - 36px);

                    img {

                        // height: 50px;
                        // width: 50px;
                        // opacity: 0.5;
                        @media (max-width: 1366px) {
                            // height: 40px;
                            // width: 40px;
                        }
                    }
                }
            }
        }

        .workroom-card-right {
            max-width: 220px;
            width: 100%;
            margin-left: -44px;
            background-color: #efefef;
            box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
            padding: 24px 0;
            height: 247px;
            max-height: 100%;

            @media (max-width: 576px) {
                height: 217px;
            }

            @media (max-width:1366px) {
                padding: 15px 0;
            }

            .text-details {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 100%;
                max-width: 80%;
                margin: auto;
            }

            .top {
                color: #2B292D;
                font-weight: 700;
                font-size: 24px;

                @media (max-width:1366px) {
                    font-size: 20px;
                }

                @media (max-width:576px) {
                    font-size: 16px;
                }
            }

            .bottom {
                display: flex;
                align-items: center;
                cursor: pointer;

                .text {
                    color: #6F8BDF;
                    font-size: 700;
                    margin-bottom: 0;

                    @media (max-width:1366px) {
                        font-size: 14px;
                    }

                    @media (max-width:576px) {
                        font-size: 10px;
                    }
                }

                .image-wrapper {
                    margin-left: 12px;

                    @media (max-width:1366px) {
                        img {
                            margin-top: -2px;
                        }
                    }

                    @media (max-width: 576px) {
                        img {
                            height: 10px;
                            width: 10px;
                        }
                    }
                }
            }
        }
    }
}

.ant-tag {
    width: 80px;
}