.reading-section {
    padding: 37px 30px 0;
    overflow: auto;
    max-height: calc(100vh - 107px);
    scrollbar-width: none;

    @media (max-width: 1366px) {
        padding: 37px 20px 0;
    }

    @media (max-width: 576px) {
        padding: 37px 12px 0;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .reading-section-container {
        .reading-card {
            padding: 34px 20px;
            background-color: #EFEFEF;
            border-radius: 16px;
            margin-bottom: 30px;

            .reading-card-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: 'Lato';
                margin-bottom: 33px;

                @media (max-width: 1366px) {
                    margin-bottom: 25px;
                }

                @media (max-width: 768px) {
                    flex-direction: column;
                    justify-content: unset;
                    align-items: unset;
                }

                .character-title {
                    display: flex;
                    align-items: center;
                    &.workroom-character-title{
                    justify-content: space-between;
                    }   
                    width: 100%;
                    .pro-edit-btn{
                        .edit-btn{
                            background-color: #6F8BDF;
                            padding: 16px;
                            border-radius: 12px;
                            width: 50px;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                    @media (max-width: 1200px) {
                        align-items: unset;
                    }

                    .title-text {
                        font-weight: 700;
                        color: #2B292D;
                        font-size: 24px;
                        line-height: 1;
                        max-width: 333px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        @media (max-width:1366px) {
                            font-size: 22px;
                        }
                    }

                    .author {
                        font-weight: 700;
                        font-size: 18px;
                        color: #6F8BDF;
                        font-family: 'Inter';
                        margin-left: 25px;
                        position: relative;

                        @media (max-width:1366px) {
                            font-size: 16px;
                        }

                        @media (max-width:1366px) {
                            font-size: 14px;
                        }

                        @media (max-width:1200px) {
                            margin-top: 8px;
                            margin-left: 0;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: -13px;
                            transform: translateY(-50%);
                            height: 4px;
                            width: 4px;
                            border-radius: 50%;
                            background-color: #6F8BDF;

                            @media (max-width: 1200px) {
                                display: none;
                            }
                        }
                    }
                }

                .button-wrapper {
                    @media (max-width: 768px) {
                        margin-top: 10px;
                    }

                    .board-btn {
                        padding-right: 40px;
                        padding-left: 40px;

                        @media (max-width: 1366px) {
                            font-size: 16px;
                            padding-right: 30px;
                            padding-left: 30px;
                        }

                        @media (max-width: 1024px) {
                            font-size: 14px;
                        }

                        @media (max-width: 768px) {
                            padding-right: 20px;
                            padding-left: 20px;
                            font-size: 12px;
                        }
                    }
                }
            }

            .reading-description {
                @media (max-width: 768px) {
                    &:first-of-type {
                        margin-bottom: 12px;
                    }
                }

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    font-family: 'Lato';
                    margin-bottom: 10px;
                }

                .text-description {
                    color: #5B5A78;
                    line-height: 18px;

                    @media (max-width: 768px) {
                        font-size: 15px;
                    }
                }
            }

            .divider {
                height: 1px;
                width: calc(100% - 25px);
                background-color: #6F8BDF;
                margin: 30px auto;
            }

            .chapter-details-row {
                align-items: self-end;
                flex-wrap: wrap;

                .col-md-3 {
                    padding-right: 0;
                }

                .chapter-details-wrapper {
                    @media (max-width: 1200px) {
                        margin-bottom: 20px;
                    }
                }

                .chapter-details {


                    h3 {
                        font-size: 18px;
                        font-weight: 600;
                        font-size: 16px;
                        margin-bottom: 0;
                    }

                    .date {
                        color: #5B5A78;
                        margin: 10px 0;

                        @media (max-width:1366px) {
                            font-size: 14px;
                            margin: 5px 0;
                        }
                    }

                    .chapter-description {
                        display: flex;
                        align-items: center;

                        @media (max-width: 576px) {
                            flex-direction: column;
                            align-items: unset;
                        }

                        .chapter-number {
                            font-size: 18px;
                            color: #2B292D;
                            font-weight: 700;
                            max-width: 50%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            @media (max-width:1366px) {
                                font-size: 15px;
                                margin-top: 0;
                                letter-spacing: -0.5px;
                            }

                            @media (max-width: 576px) {
                                max-width: 100%;
                            }
                        }

                        .read-chapter {
                            color: #6F8BDF;
                            margin-left: 12px;
                            cursor: pointer;
                            letter-spacing: -1px;
                            font-weight: 600;
                            font-size: 18px;
                            font-weight: 700;

                            @media (max-width:1366px) {
                                font-size: 13px;
                            }

                            @media (max-width: 576px) {
                                margin-left: 0;
                                margin-top: 8px;
                            }

                            img {
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}