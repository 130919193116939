.search-wrapper {
    position: relative;
    margin-top: 30px;
    @media (max-width: 1366px){
        margin-top: 5px;
    }

    label {
        display: none !important;
    }

    .input-field{
        .left-icon{
            @media (max-width: 1366px){
                top: 9px;
            }
            @media (max-width: 576px){
                top: 6px;
            }
        }
    }
    .close-icon{
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
}