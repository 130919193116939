.book-detail-card-wrapper {
    &.character-card-wrapper {
        flex-basis: 22%;
        max-width: 295px;
        display: unset;

        .card-details {
            background-color: #EFEFEF;
            border-radius: 16px;
            font-family: 'Lato';
            padding: 20px 0;

            .card-description {
                max-width: 90%;
                margin: auto;

                h3 {
                    font-weight: 600;
                    font-size: 18px;
                    color: #5B5A78;
                    margin-bottom: 0;
                    max-width: 90%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    @media (max-width:1366px) {
                        font-size: 16px;
                    }
                }

                p {
                    font-weight: 600;
                    font-size: 24px;
                    color: #2B292D;
                    margin-bottom: 0;

                    @media (max-width:1366px) {
                        font-size: 20px;
                        margin-bottom: 4px;
                    }

                    &:nth-of-type(2) {
                        text-decoration: none;
                        color: #6F8BDF;
                        font-family: 'Inter';
                        font-size: 16px;

                        @media (max-width:1366px) {
                            font-size: 14px;
                            margin-bottom: 0;
                        }
                    }

                    &.para-title {
                        max-width: 90%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }

                .accordion-wrapper {

                    .accordion {

                        .accordion-item {
                            border: unset;
                            background-color: transparent;

                            .accordion-header {
                                margin-bottom: 0;

                                .accordion-button {
                                    font-weight: 600;
                                    padding: 0;
                                    background-color: transparent;
                                    font-family: "Inter" !important;
                                    font-size: 14px;
                                    display: unset;
                                    position: relative;
                                    color: #6F8BDF;

                                    &:not(.collapsed) {
                                        box-shadow: unset;
                                        background-color: unset;
                                    }

                                    &:focus {
                                        box-shadow: unset;
                                    }

                                    &::after {
                                        background-image: url('../../assets/images/greater-blue-icon.svg');
                                        background-size: 8px;
                                        background-position: center;
                                        // height: 20px;
                                        // width: 20px;
                                        transform: rotate(270deg);
                                        content: '';
                                        position: absolute;
                                        margin-left: 10px;
                                        top: -1px;
                                        transform: rotate(270deg);
                                    }
                                }

                                .collapsed {
                                    &::after {
                                        transform: rotate(90deg);
                                    }
                                }
                            }

                            .accordion-body {
                                padding: 15px 0 0 0;

                                .author-details {
                                    border-top: 1px solid #a2a2a2;
                                    padding-top: 15px;
                                    display: flex;
                                    margin-bottom: 10px;

                                    p {
                                        color: #6F8BDF;
                                        font-family: 'Inter';
                                        font-size: 14px;
                                        font-weight: 700;
                                        margin-bottom: 0;

                                        &:nth-of-type(2) {
                                            margin-left: 20px;
                                            position: relative;

                                            &::before {
                                                content: '';
                                                position: absolute;
                                                top: 50%;
                                                left: -13px;
                                                transform: translateY(-50%);
                                                height: 4px;
                                                width: 4px;
                                                border-radius: 50%;
                                                background-color: #6F8BDF;
                                            }
                                        }
                                    }
                                }

                                .content-description {
                                    p {
                                        color: #5B5A78;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 1.2;

                                        @media (max-width:1366px) {
                                            font-size: 13px;
                                        }
                                    }

                                    .board-btn {
                                        margin: 20px 0 0;
                                    }
                                }
                                .novalDetails-about{
                                    p {
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 30px;
                                        color: #000000;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.active-card {
                background-color: #6F8BDF;

                h3 {
                    color: #efefef;
                }

                p {
                    color: #fff;
                }

                .accordion-button {
                    color: #fff !important;

                    &::after {
                        background-image: url('../../assets/images/greater-white.svg') !important;
                    }
                }

                .author-details {
                    border-color: #fff !important;

                    p {
                        color: #fff !important;

                        &:nth-of-type(2) {
                            &::before {
                                background-color: #fff !important;
                            }
                        }
                    }
                }

                .content-description {
                    p {
                        color: #fff !important;
                    }

                    .board-btn {
                        margin: 20px 0 0;
                        background-color: #fff !important;
                        color: #6F8BDF !important;
                    }
                }
            }
        }
    }
}