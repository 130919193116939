.board-time-picker {
    background-color: #efefef;
    border: 1px solid #6F8BDF;
    border-radius: 15px;
    padding: 44px 32px;
    padding-top: 70px;
    min-width: 390px;
    width: 100%;
    position: absolute;
    bottom: calc(100% - 52px);
    left: calc(100% + 15px);
    z-index: 999;
    @media (max-width: 1366px){
        padding: 60px 25px 30px;
        min-width: 320px;
        left: calc(100% + 5px);
        bottom: calc(100% - 40px);
    }
    @media (max-width: 1280px){
        left: 0;
        bottom: calc(100% + 20px);
        min-width: 290px;
    }

    .react-timerange-picker {
        display: flex;

        .react-timerange-picker__wrapper {
            border: unset;
            display: flex;
            flex-direction: column;
            align-items: unset;

            .react-timerange-picker__inputGroup {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 26px;
                position: relative;

                &:first-of-type {
                    &::before {
                        content: 'From';
                        position: absolute;
                        bottom: calc(100% + 20px);
                        left: 0;
                        height: 20px;
                        width: fit-content;
                        color: #000;
                    }
                }

                &:not(:first-of-type) {
                    margin-bottom: 0;
                }

                .react-timerange-picker__inputGroup__input {
                    background-color: #fff;
                    padding: 8px 24px;
                    border-radius: 8px;
                    max-width: 87px;
                    @media (max-width: 1366px){
                        padding: 8px 18px;
                    }
                }
            }
        }

        .react-timerange-picker__range-divider {
            margin: 0 0 20px;
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        margin: 20px 0 0 !important;

        .board-btn {
            margin-bottom: 0 !important;
            min-width: 230px;
        }
    }

    // .react-timerange-picker__clock{
    //     display: none;
    // }

}

.ant-picker-focused {
    box-shadow: unset !important;
}