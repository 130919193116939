.content-section {
    &.current-novels-section {
        padding-right: 20px !important;
        @media (max-width: 1366px){
            padding-left: 5px !important;
            padding-right: 30px !important;
        }
        @media (max-width: 1200px){
            padding-right: 15px !important;
        }
        @media (max-width: 768px){
            padding-right: 20px !important;
        }
        @media (max-width: 576px){
            padding-right: 15px !important;
        }
        .current-novels-wrapper {
            .home-card-row {
                &.current-novels-card-row {
                    flex-wrap: wrap;
                    .card-data{
                        flex-basis: 12.5%;

                        margin-bottom: 20px;
                        @media (max-width: 1200px){
                            flex-basis: 16.5%;
                        }
                        @media (max-width: 992px){
                            flex-basis: 16.5%;
                            max-width: unset;
                        }
                        @media (max-width: 768px){
                            flex-basis: 20%;
                        }
                        @media (max-width: 676px){
                            flex-basis: 25%;
                        }
                        @media (max-width: 500px){
                            flex-basis: 33.33%;
                        }
                        @media (max-width: 400px){
                            flex-basis: 50%;
                        }
                        .slider-card {
                            height: 208px;
                            border-radius: 16px;
                            width: 100%;
                            max-width: 142px;
                            animation: zoomIn 0.6s forwards;
                            @media (max-width: 1280px){
                                height: 200px;
                            }
                            @media (max-width: 576px){
                                height: 180px;
                            }
                            @media (max-width: 400px){
                                height: 200px;
                            }
                        }
                    }

                }
            }
        }
    }
}

// Animation
@keyframes zoomIn {
    0% {
       transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}