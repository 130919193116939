.content-section {
    &.new-chapter-section {

        h2 {
            font-family: 'Lato';
            font-weight: 700;
            font-size: 24px;
            color: #2B292D;
            margin-bottom: 30px;

            @media (max-width: 1366px) {
                font-size: 20px;
                margin-bottom: 20px;
            }

            @media (max-width: 1024px) {
                margin: 0 0 20px 0;
                font-size: 18px;
            }

        }

        .fill-box-section,

        .character-submission-section {
            padding-left: 20px;
            max-width: 90%;


            @media (max-width: 1366px) {
                padding-left: 10px;
            }

            @media (max-width: 1280px) {
                max-width: 95%;
            }

            @media (max-width: 1024px) {
                max-width: 98%;
            }
        }

        .fill-box-section {
            .fill-box-container {
                .fill-box-left {
                    padding-left: 0;
                }

                .fill-box-left,
                .fill-box-right {
                    input {
                        margin-bottom: 10px;

                        @media (max-width: 1366px) {
                            margin-bottom: 20px;
                        }
                    }

                    label{
                        // display: none;
                    }

                    .text-area-field {
                        textarea {
                            min-height: 133px;

                            @media (max-width: 1366px) {
                                min-height: 128px;
                            }

                            @media (max-width: 576px) {
                                min-height: 98px;
                            }
                        }
                    }
                }

                .fill-box-right {
                    @media (max-width: 992px) {
                        margin-top: 15px;
                        padding: 0;
                    }

                    .right-details {
                        display: flex;

                        .box-upload-wrapper {
                            flex-basis: 50%;
                            padding: 0 10px;

                            &:first-of-type {
                                @media (max-width: 992px) {
                                    padding-left: 0;
                                }
                            }

                            .upload-file-wrapper {
                                min-height: 216px;
                                cursor: pointer;

                                @media (max-width: 1366px) {
                                    min-height: 210px;
                                }

                                img {
                                    @media (max-width: 576px) {
                                        height: 16px;
                                        width: 16px;
                                    }
                                }

                                .sample {
                                    @media (max-width: 992px) {
                                        font-size: 14px;
                                    }

                                    @media (max-width: 576px) {
                                        font-size: 12px;
                                        margin-top: 0;
                                    }
                                }
                                .selected-file-name{
                                    word-break: break-all;
                                    line-height: 16px;
                                    font-size: 14px;
                                    margin-bottom: 0;
                                    margin: 0 15px;
                                    @media (max-width: 1366px){
                                        font-size: 12px;
                                    }
                                }
                            }

                            p {

                                // color: red;
                                font-size: 14px;


                                @media (max-width:1680px) {
                                    font-size: 12px;
                                    line-height: 14px;
                                    margin: 6px 0;
                                }

                                @media (max-width: 1366px) {
                                    margin-top: 3px;
                                    min-height: 14px;
                                }

                                @media (max-width:1366px) and (max-height:630px) {
                                    font-size: 10px;
                                    margin-bottom: 6px;
                                }

                                @media(max-width: 576px) {
                                    font-size: 10px;
                                    margin: 4px 0 6px;
                                }
                            }

                        }
                    }
                }

            }

        }

        .character-submission-section {
            max-width: 100%;

            h2 {
                margin-top: 50px;
                margin-bottom: 30px;

                @media (max-width: 1366px) {
                    margin-top: 30px;
                    margin-bottom: 20px;
                }
            }

            .character-submission-container {
                padding-left: 0;

                .character-submission-row {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .character-submission-card-wrapper {
                        flex-basis: 36.7%;

                        @media (max-width: 1024px) {
                            flex-basis: 40%;
                        }

                        @media (max-width: 576px) {
                            flex-basis: 45%;
                        }

                        @media (max-width: 450px) {
                            flex-basis: 55%;
                        }

                        @media (max-width: 400px) {
                            flex-basis: 70%;
                        }

                        .character-submission-card {
                            padding: 24px;
                            background-color: #efefef;
                            border-radius: 16px;
                            min-height: 98px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            @media (max-width:  1366px) {
                                border-radius: 10px;
                                padding: 16px;
                            }

                            @media (max-width: 1100px) {
                                padding: 16px;
                                align-items: flex-start;
                                flex-direction: column;
                            }

                            @media (max-width: 576px) {
                                padding: 12px;
                                justify-content: center;
                            }

                            h3 {
                                font-size: 18px;
                                font-weight: 700;
                                margin-bottom: 0;
                                flex-basis: 32%;
                                max-width: 120px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                cursor: pointer;

                                @media (max-width: 1366px) {
                                    font-size: 16px;
                                }
                            }

                            .board-btn {
                                font-size: 16px;
                                width: 100%;
                                flex-basis: 68%;

                                @media (max-width: 1366px) {
                                    font-size: 14px;
                                }

                                @media (max-width: 1100px) {
                                    max-width: 200px;
                                    margin-top: 16px;
                                }

                                @media (max-width: 576px) {
                                    font-size: 12px;
                                    margin-top: 12px;
                                    max-width: 130px;
                                }
                            }
                            &.selected-submission{
                                background-color: #6F8BDF;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .button-wrapper {
                margin-top: 50px;
                display: flex;
                align-items: center;

                @media (max-width: 1366px) {
                    margin-top: 40px;
                }

                @media (max-width: 992px) {
                    margin-top: 40px;
                }

                @media (max-width: 576px) {
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 20px;
                }

                .board-btn {
                    min-width: 220px;

                    @media (max-width: 992px) {
                        min-width: 200px;
                    }

                    @media (max-width: 576px) {
                        min-width: 170px;
                        height: 34px;
                    }

                    &.transparent-btn {
                        margin-left: 30px;

                        @media (max-width: 1366px) {
                            margin-left: 20px;
                        }

                        @media(max-width: 576px) {
                            margin-left: 0;
                            margin-top: 12px;
                        }
                    }

                }
            }
        }
    }
}