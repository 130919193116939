.content-section {
    &.new-novel-section {

        h2 {
            font-family: 'Lato';
            font-weight: 700;
            font-size: 24px;
            color: #2B292D;
            margin-bottom: 30px;

            @media (max-width: 1366px) {
                font-size: 20px;
                margin-bottom: 20px;
            }

            @media (max-width: 1024px) {
                margin: 0 0 20px 0;
                font-size: 18px;
            }

        }

        .fill-box-section,
        .character-involve-section {
            padding-left: 20px;
            max-width: 90%;

            @media (max-width: 1366px) {
                padding-left: 10px;
            }

            @media (max-width: 1280px) {
                max-width: 95%;
            }

            @media (max-width: 1024px) {
                max-width: 98%;
            }
        }

        .fill-box-section {

            .fill-box-container {
                .fill-box-left {
                    padding-left: 0;
                }

                .date-picker-row {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 28px 0;
                    padding: 0;

                    @media (max-width: 1366px) {
                        margin: 0px 0 20px 0;
                    }

                    @media (max-width: 576px) {
                        flex-direction: column;
                        margin-top: 0;

                        .date-picker-space {
                            &:not(:first-of-type) {
                                margin-top: 20px;
                            }
                        }
                    }

                    .date-picker-space {
                        flex-basis: 50%;
                        width: 100%;

                        &:first-of-type {
                            padding-left: 0;
                        }

                        .ant-space {
                            width: 100%;
                        }

                        .ant-space-item {
                            flex-basis: 48%;
                            width: 100%;
                        }
                    }

                    .board-date-picker-item {
                        &.first {
                            .ant-picker-input {
                                &::before {
                                    opacity: 0.6;
                                }
                            }
                        }
                    }

                }

                .fill-box-left,
                .fill-box-right {
                    input {
                        margin-bottom: 6px;

                        @media (max-width: 1366px) {
                            margin-bottom: 10px;
                        }
                    }

                    .input-row {
                        input {
                            margin-bottom: 6px;
                            height: 100%;
                            margin-left: 40px;

                            @media (max-width: 1366px) {
                                margin-bottom: 10px;
                            }
                        }
                    }

                    label {
                        // display: none;
                    }

                    .text-area-field {
                        textarea {
                            min-height: 133px;

                            @media (max-width: 1366px) {
                                min-height: 98px;
                            }
                        }
                    }
                }

                .fill-box-right {
                    padding-left: 0;

                    @media (max-width: 992px) {
                        padding-left: 0;
                    }

                    .check-box {
                        .ant-checkbox {
                            margin-top: -6px;
                            margin-right: 10px;
                        }

                        span {
                            padding: 0;
                            font-size: 20px;
                        }

                        .ant-checkbox-inner {
                            width: 25px;
                            height: 25px;
                        }
                    }

                    .right-details {
                        input {
                            margin-bottom: 0;
                        }

                        .field-wrapper {
                            max-height: 250px;
                            overflow: auto;
                            scrollbar-width: none;

                            @media (max-width: 1366px) {
                                max-height: 150px;
                            }

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            .field-row {
                                display: flex;
                                justify-content: space-between;

                                @media (max-width: 576px) {
                                    justify-content: unset;
                                }

                                .field-column-wrapper {
                                    display: flex;
                                    width: calc(100% - 70px);
                                    flex-direction: column;

                                    @media (max-width: 1366px) {
                                        width: calc(100% - 60px);
                                    }

                                    @media (max-width: 576px) {
                                        width: calc(100% - 45px);
                                    }
                                }

                                .field-column {
                                    display: flex;
                                    flex-basis: 100%;
                                    justify-content: space-between;
                                    align-items: center;

                                    &.added-field {
                                        margin-top: 20px;
                                    }

                                    @media (max-width: 576px) {
                                        .ant-select-selector {
                                            border-radius: 5px !important;
                                        }
                                    }

                                    .left {
                                        flex-basis: calc(50% + 26px);
                                        width: 100%;
                                        margin-left: 10px;

                                        .check-box {
                                            .ant-checkbox {
                                                margin-top: -6px;
                                                margin-right: 10px;
                                            }

                                            span {
                                                padding: 0;
                                                font-size: 20px;
                                            }

                                            .ant-checkbox-inner {
                                                width: 25px;
                                                height: 25px;
                                            }
                                        }

                                        @media (max-width: 1366px) {
                                            flex-basis: calc(50% + 21px);
                                        }

                                        @media (max-width: 992px) {
                                            flex-basis: calc(50% + 12px);
                                        }

                                        @media (max-width: 768px) {
                                            flex-basis: calc(50% + 17px);
                                        }

                                        @media (max-width: 576px) {
                                            flex-basis: calc(50% + 13px);
                                        }

                                        @media (max-width: 450px) {
                                            flex-basis: calc(50% + 17px);
                                        }

                                        @media (max-width: 330px) {
                                            flex-basis: calc(50% + 18px);
                                        }
                                    }

                                    .right {
                                        flex-basis: calc(50% - 44px);

                                        @media (max-width: 1366px) {
                                            flex-basis: calc(50% - 39px);
                                        }

                                        @media (max-width: 992px) {
                                            flex-basis: calc(50% - 47px);
                                        }

                                        @media (max-width: 768px) {
                                            flex-basis: calc(50% - 44px);
                                        }

                                        @media (max-width: 576px) {
                                            flex-basis: calc(50% - 32px);
                                        }

                                        @media (max-width: 450px) {
                                            flex-basis: calc(50% - 31px);
                                        }
                                    }
                                }


                                .left {
                                    display: flex;

                                    .board-select-wrapper {

                                        .ant-select {
                                            width: 100% !important;
                                        }

                                        .ant-select-selection-item {
                                            position: relative;
                                            padding-left: 44px;
                                            color: #a2a2a2;
                                            font-family: 'Lato' !important;

                                            &::before {
                                                background-image: url('../../assets/images/role.svg');
                                                display: block;

                                                @media (max-width: 1366px) {
                                                    height: 14px;
                                                    width: 14px;
                                                    top: 8px;
                                                }
                                            }
                                        }
                                    }
                                }

                                label {
                                    // display: none;
                                }

                            }
                        }
                    }
                }

                .fill-box-bottom {
                    padding-left: 0;

                    // @media (max-width: 1366px) {
                    //     margin-top: 20px;
                    // }
                }
            }
        }

        .character-involve-section {
            h2 {
                margin-top: 10px;
                margin-bottom: 30px;

                @media (max-width: 1366px) {
                    margin-bottom: 20px;
                }
            }

            .character-involve-container,
            .additional-info-container {
                padding-left: 0;
            }

            .character-involve-container {
                margin-bottom: 30px;

                .character-involve-row {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .role-card-wrapper {
                        flex-basis: 22.3%;
                        display: flex;
                        width: 100%;

                        @media (max-width: 1024px) {
                            flex-basis: 26%;
                        }

                        @media (max-width: 768px) {
                            flex-basis: 33%;
                        }

                        @media (max-width: 576px) {
                            flex-basis: 40%;
                        }

                        @media (max-width: 450px) {
                            flex-basis: 70%;
                        }

                        .role-card {
                            padding: 24px;
                            background-color: #efefef;
                            border-radius: 16px;
                            // cursor: pointer;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            @media (max-width: 1024px) {
                                border-radius: 10px;
                                padding: 16px;
                            }

                            @media (max-width: 576px) {
                                padding: 12px;
                            }

                            h3 {
                                font-size: 18px;
                                font-weight: 700;
                                margin-bottom: 10px;
                                max-width: 140px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                margin-right: 5px;
                                cursor: pointer;

                                @media (max-width: 1024px) {
                                    font-size: 16px;
                                    margin-bottom: 8px;
                                }
                            }

                            p {
                                color: #6F8BDF;
                                font-weight: 600;
                                font-family: 'Inter';
                                margin-bottom: 0;

                                @media (max-width: 1024px) {
                                    font-size: 14px;
                                }
                            }

                            &.active-card {
                                background-color: #6F8BDF;
                                cursor: pointer;

                                h3,
                                p {
                                    color: #ffffff;
                                }
                            }

                            .role-card-actions {
                                padding-left: 17px;
                                border-left: 2px solid #6F8BDF;

                                .action {
                                    margin-bottom: 20px;
                                    cursor: pointer;

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .additional-info-container {
                .additional-info-row {
                    .additional-left {
                        .left-details {
                            .upload-wrapper {
                                max-width: 100%;
                            }

                            .input-field {
                                input {

                                    @media (max-width: 1366px) {
                                        margin-top: 20px;
                                    }
                                }

                                label {
                                    // display: none;
                                }
                            }
                        }
                    }

                    .additional-right {
                        @media (max-width: 768px) {
                            margin-top: 20px;
                        }

                        .right-details {
                            textarea {
                                min-height: 198px;

                                @media (max-width: 1366px) {
                                    min-height: 178px;
                                }
                            }
                        }
                    }
                }

                .button-wrapper {
                    margin-top: 50px;

                    @media (max-width: 1366px) {
                        margin-top: 30px;
                    }

                    @media (max-width: 576px) {
                        display: flex;
                        justify-content: center;
                    }

                    .board-btn {
                        min-width: 281px;

                        @media (max-width: 768px) {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }

    .add {
        background-color: #6F8BDF;
        height: 50px;
        min-width: 50px;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;

        @media (max-width: 1366px) {
            height: 40px;
            min-width: 40px;
            border-radius: 10px;
        }

        @media (max-width: 576px) {
            height: 35px;
            min-width: 35px;
            border-radius: 8px;
            margin-left: 10px;
        }

        &.added-field {
            margin-top: 20px;
            color: #efefef;
            font-weight: 700;
            font-size: 22px;
        }
    }
}