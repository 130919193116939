.header-section {
	border-bottom: 1px solid #6F8BDF;
	padding: 20px 31px;
	background-color: #fff;
	width: 100%;
	max-height: 117px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	@media (max-width:1366px) {
		padding: 20px 35px 20px 20px;
	}

	@media (max-width: 1280px) {
		padding: 20px;
	}

	@media (max-width: 576px) {
		padding: 15px 13px;
		flex-direction: column;
		align-items: unset;
		max-height: 150px;
	}

	.add {
		background-color: #6F8BDF;
		padding: 13px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		cursor: pointer;
	}

	.main-text {
		font-family: 'PT Serif';
		font-size: 32px;
		font-weight: 700;
		display: flex;
		align-items: center;

		@media (max-width: 1280px) {
			font-size: 30px;
		}

		@media (max-width: 1200px) {
			font-size: 26px;
		}

		@media (max-width: 768px) {
			font-size: 26px;
		}

		@media (max-width: 576px) {
			font-size: 22px;
		}

		@media (max-width: 450px) {
			font-size: 16px;
			padding-right: 10px;
		}

		@media (max-width: 350px) {
			font-size: 14px;
		}

		.back {
			min-height: 50px;
			min-width: 50px;
			background-color: #6F8BDF;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 12px;
			margin-right: 30px;
			cursor: pointer;

			@media (max-width: 1366px) {
				min-height: 40px;
				min-width: 40px;
				margin-right: 20px;
			}

			@media (max-width: 1024px) {
				min-height: 34px;
				min-width: 34px;
				border-radius: 8px;
			}

			@media (max-width: 576px) {
				min-height: 30px;
				min-width: 30px;
				margin-right: 15px;
				border-radius: 6px;
			}

			@media (max-width: 400px) {
				min-height: 20px;
				min-width: 20px;
				margin-right: 10px;
				border-radius: 4px;
			}
		}


		.title {
			min-width: 350px;
			max-width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			@media (max-width: 1200px) {
				min-width: 250px;
			}

			@media (max-width: 768px) {
				min-width: 120px;
			}
		}
	}

	.search-wrapper {
		@media (max-width: 576px) {
			max-width: 150px;
		}

		@media (max-width: 450px) {
			max-width: 120px;
		}

		.input-group {
			input {
				background-color: #EFEFEF;
				border: unset;
				border-radius: 0 8px 8px 0;
				padding: 16px 10px;
				font-family: "Lato";
				height: 52px;

				@media (max-width: 1366px) {
					height: 40px;
				}

				@media (max-width: 1280px) {
					padding: 12px 10px;
				}

				@media (max-width: 1024px) {
					padding-top: 7px;
					height: 35px;
				}

				@media (max-width: 576px) {
					padding: 0 8px;
					max-height: 100%;
				}


				&::placeholder {
					font-weight: 600;
					color: #A2A2A2;
					font-size: 16px;
					font-family: "Lato";

					@media (max-width: 1024px) {
						font-size: 12px;
					}
				}
			}
		}

		.input-group-text {
			background-color: #EFEFEF;
			border: unset;
			border-radius: 8px 0 0 8px;
			padding-right: 0;

			@media (max-width: 576px) {
				padding: 3px 0 0px 10px;
			}

			img {
				@media (max-width: 1366px) {
					height: 16px;
					width: 16px;
				}

				@media (max-width: 1024px) {
					height: 13px;
					width: 13px;
				}

				@media (max-width: 450px) {
					height: 12px;
					width: 12px;
				}
			}
		}
	}

	.right-wrapper {
		display: flex;
		align-items: center;

		@media (max-width: 576px) {
			flex-direction: column;
			align-items: unset;
			margin-top: 10px;
		}

		.icon-wrapper {
			min-height: 50px;
			width: 50px;
			background-color: transparent;
			border: 1px solid #2B292D;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 12px;
			cursor: pointer;

			@media (max-width: 1366px) {
				min-height: 45px;
				width: 45px;
			}

			@media (max-width: 1200px) {
				min-height: 40px;
				width: 40px;
			}

			@media (max-width: 992px) {
				min-height: 35px;
				width: 35px;
				border-radius: 8px;
			}

			@media (max-width: 576px) {
				min-height: 30px;
				width: 30px;
				border-radius: 6px;
			}
		}

		.board-btn {
			font-size: 16px;
			margin-right: 10px;

			@media (max-width: 1050px) {
				font-size: 12px;

				img {
					height: 20px;
				}
			}
		}
	}

	.button-wrapper {
		.board-btn {
			min-width: 230px;

			@media (max-width: 768px) {
				min-width: 200px;
			}

			@media (max-width: 576px) {
				min-width: 150px;
				margin: 8px 0;
			}

			@media (max-width: 400px) {
				font-size: 12px;
				min-height: 30px;
			}
		}
	}

	.showSreach {
		padding-right: 30px;
		margin: unset;

		@media (max-width: 1024px) {
			padding-right: 10px;
			margin: unset;
		}

		@media (max-width: 576px) {
			padding-right: 0;
		}
	}

	&.perspective-header-section {

		.main-text {
			.text{
				width: 90%;
			}
			.title {
				max-width: 500px;
				@media (max-width: 1024px){
					max-width: 300px;
				}
			}
		}
		@media (max-width: 768px) {
			flex-direction: column;
			align-items: unset;
		}

		.main-text {
			.title {
				@media (max-width: 768px) {
					min-width: unset;
				}
			}
		}

		.right-wrapper {
			align-self: flex-end;
			margin: 6px 0;

			@media (max-width: 576px) {
				align-self: unset;
			}

			.icon-wrapper {
				@media (max-width: 576px) {
					margin: 10px 0 0;
				}

				img {
					height: 18px;
				}
			}
		}
	}

	&.application-header-section {
		.main-text {
			.title {
				@media (max-width: 768px) {
					min-width: 180px;
				}

				@media (max-width: 400px) {
					min-width: unset;
				}
			}
		}
	}


	&.chapter-header {
		.main-text {
			.text {
				.title {
					@media (max-width: 350px) {
						white-space: pre-wrap;
						max-width: 80%;
					}
				}
			}
		}
	}
}