.reading-section {
    &.workroom-section {
        .card-title {
            font-weight: 700;
            color: #2B292D;
            font-size: 24px;
            line-height: 1;
            margin-bottom: 33px;
            white-space: nowrap;

            @media (max-width:576px) {
                font-size: 22px;
                white-space: unset;
            }
        }

        .workroom-card {
            .chapter-details {

                font-family: 'Lato';

                @media (max-width: 1366px) {
                    margin-bottom: 25px;
                }
            }

            .chapter-description {
                white-space: nowrap;
            }
        }

        .Workroom-card {
            .card-title {
                font-weight: 700;
                color: #2B292D;
                font-size: 24px;
                line-height: 1;
                margin-bottom: 33px;
                white-space: nowrap;

                @media (max-width:768px) {
                    font-size: 22px;
                    white-space: unset;
                }

                ;
            }
            .pro-edit-btn{
                .edit-btn{
                    background-color: #6F8BDF;
                    padding: 16px;
                    border-radius: 12px;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }

            .text-area-field {
                span {
                    img {
                        display: none;
                    }
                }
                textarea {
                    background-color: #fff;
                    border: 1px solid #6F8BDF;
                    border-radius: 8px;
                }
            }
        }

        .button-wrapper {
            display: flex;
            width: 100%;
            margin-top: 10%;
            justify-content: space-between;

            @media (max-width: 1440px) and (max-height: 900px) {
                justify-content: unset;
            }

            @media (max-width: 992px) {
                justify-content: center;
            }

            @media (max-width: 576px) {
                margin-top: 10px;
            }

            .board-btn {
                // min-width: 40%;
                // flex-basis: 48%;
                width: 280px;

                @media (max-width: 992px) {
                    width: 220px;
                    font-size: 14px;
                    margin-left: 20px;

                    img {
                        width: 20px;
                        height: 20px;

                        margin-right: 10px;
                    }
                }

                @media (max-width: 768px) {
                    width: 220px;
                    padding: 6px 0;
                    font-size: 14px;


                    img {
                        width: 20px;
                        height: 20px;

                        margin-right: 10px;
                    }
                }

                @media (max-width: 576px) {

                    margin-top: 20px;
                    font-size: 9px;
                    margin-bottom: 10px;

                    img {
                        width: 15px;
                        height: 15px;

                        margin-right: 10px;
                    }

                }

                &.transparent-btn {
                    width: 200px;
                    margin-left: 10px;

                    @media (max-width: 1440px) and (max-height: 900px) {
                        margin-left: 20px;
                        width: 150px;
                    }

                    @media (max-width: 1100px) {
                        margin-left: 10px;
                        width: 150px;
                    }

                    @media (max-width: 768px) {
                        margin-left: 12px;
                    }
                }
            }







        }

        .workroom-description {
            .title {
                @media (max-width: 768px) {
                    margin-top: 1.5rem;
                }
            }

            textarea{
                padding: 12px 20px;
            }
        }
    }
}