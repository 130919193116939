.content-section {
    &.my-profile-section {
        .profile-detail-section {
            .profile-detail-container {
                color: #2B292D;

                .profile-referral-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .referrer-name {
                        h3 {
                            font-weight: 700;
                        }
                        font-size: 16px;
                        margin-bottom: 20px;
                        color: #A2A2A2;
                        display: flex;
                        align-items: baseline;
                        gap: 5px;

                        h3 {
                            font-size: 16px;

                        }
                    }
                }

                .token-detail-wrapper {
                    display: flex;
                    flex-wrap: wrap;

                    .referrer-name {
                        text-align: right;
                        flex-grow: 1;
                        padding-right: 2em;
                        color: #A2A2A2;
                        font-family: 'Lato';
                        .anticon{
                            svg{
                                width: 20px;
                                height: 20px;
                                cursor: pointer;
                            }
                        }
                    }

                    @media (max-width:420px) {
                        justify-content: center;
                    }

                    .token-details {
                        background-color: #6F8BDF;
                        max-width: 170px;
                        width: 100%;
                        min-height: 110px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        border-radius: 16px;
                        margin-bottom: 20px;

                        h3 {
                            color: #EFEFEF;
                            font-size: 14px;

                            @media (max-width:576px) {
                                font-size: 12px;
                            }
                        }

                        .token-icon {
                            display: flex;
                            align-items: center;

                            span {
                                color: #fff;
                                font-size: 30px;
                                line-height: 1;
                                margin-left: 10px;
                                max-width: 130px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                cursor: pointer;

                                @media (max-width:576px) {
                                    font-size: 20px;
                                }
                            }
                        }

                        &:not(:first-of-type) {
                            margin-left: 30px;

                            @media (max-width:576px) {
                                margin-left: 10px;
                            }
                        }
                    }
                }

                .profile-form-wrapper {
                    margin-top: 30px;
                    max-width: 90%;

                    @media (max-width:1024px) {
                        max-width: unset;
                    }

                    .basic-info-wrapper,
                    .password-info-wrapper {
                        width: 100%;

                        h3 {
                            font-size: 24px;
                            font-weight: 700;
                            margin-bottom: 20px;

                            @media (max-width:1024px) {
                                font-size: 17px;
                            }
                        }

                        .input-row {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;

                            @media (max-width:1024px) {
                                flex-wrap: wrap;

                                justify-content: unset;
                            }

                            ;

                            .input-field {
                                flex-basis: 49%;
                                width: 100%;

                                @media (max-width:1024px) {
                                    flex-basis: 100%;
                                }

                                @media (max-width:857px) {
                                    flex-basis: unset;
                                    margin-left: 0;
                                }

                                ;

                                input {
                                    margin-bottom: 10px;


                                }

                            }
                        }

                        .text-area-field {
                            width: 100%;
                            position: relative;
                            max-width: unset;
                            margin: unset;

                            span {
                                position: absolute;
                                top: 10px;
                                left: 16px;

                                @media (max-width:1366px) {
                                    img {
                                        height: 14px;
                                    }
                                }
                            }

                            textarea {
                                width: 100%;
                                resize: none;
                                min-height: 116px;
                                max-height: 116px;
                                height: 100%;
                                background-color: #EFEFEF;
                                border-radius: 8px;
                                border: none;
                                padding: 12px 52px;
                                // color: #A2A2A2;
                                scrollbar-width: none;

                                &:focus {
                                    outline: none;
                                    border: none;
                                    box-shadow: none;
                                }

                                &::-webkit-scrollbar {
                                    display: none;
                                }

                                &::placeholder {
                                    font-family: 'Lato';
                                    font-weight: 600;
                                    color: #A2A2A2;

                                    @media (max-width:1366px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .profile-picture {
                            width: 211px;
                            max-width: 100%;
                            height: auto;
                            text-align: center;

                            img {
                                width: 100px;
                                height: 100px;
                                border: 6px solid #6F8BDF;
                                border-radius: 100px;
                                cursor: pointer;
                            }

                            p {
                                margin-top: 10px;
                                font-family: 'Lato';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 120%;
                                color: #A2A2A2;
                                cursor: pointer;
                            }
                        }
                    }

                    .password-info-wrapper {
                        margin-top: 20px;

                        .button-wrapper {
                            flex-basis: 49%;
                            width: 100%;
                            align-self: flex-start;

                            @media (max-width:1024px) {
                                margin-bottom: 20px;
                            }

                            .board-btn {
                                min-width: 281px;

                                @media (max-width:1024px) {
                                    min-width: 200px;

                                }
                            }
                        }
                    }

                    .bank-info-wrapper {
                        h3 {
                            font-size: 24px;
                            font-weight: 700;
                            margin-bottom: 0;

                            @media (max-width:1024px) {
                                font-size: 18px;
                            }

                            @media (max-width:576px) {
                                font-size: 14px;

                            }
                        }

                        .top {
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;

                            @media (max-width:576px) {
                                min-width: 160px;
                                margin-left: 5px;
                                justify-content: space-between;

                            }

                            .transparent-btn {
                                min-width: 281px;
                                margin-left: 30px;

                                @media (max-width:1024px) {
                                    min-width: 160px;
                                }

                            }
                        }

                        .button-wrapper {
                            margin-top: 30px;

                            @media (max-width:1024px) {
                                display: flex;
                                justify-content: center;

                            }

                            .orange-btn {
                                min-width: 281px;

                                @media (max-width:1024px) {
                                    min-width: 200px;

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}