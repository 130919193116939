.upload-file-wrapper {
    background-color: #efefef;
    border-radius: 8px;
    min-height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    cursor: pointer;

    .selected-file-name{
        word-break: break-all;
        line-height: 16px;
        font-size: 14px;
        margin-bottom: 0;
        margin: 0 15px;
        @media (max-width: 1366px){
            font-size: 12px;
        }
    }

    .upload-file {
        text-align: center;
        width: 100%;

        .input-field {
            width: 100%;
            flex-basis: 49%;

            input {
                width: 100%;
                padding: 0;
                height: 100%;
                max-height: 116px;
                background-color: unset;
            }

            label {
                display: none;
            }
        }

        .sample{
            font-family: 'Lato';
            color: #a2a2a2;
            margin-top: 7px;
        }

        .custom-file-input::-webkit-file-upload-button {
            visibility: hidden;
        }

        .custom-file-input::before {
            content: '';
            display: inline-block;
            background-color: #efefef;
            border-radius: 3px;
            padding: 5px 8px;
            outline: none;
            white-space: nowrap;
            -webkit-user-select: none;
            cursor: pointer;
            text-shadow: 1px 1px #fff;
            font-weight: 700;
            font-size: 10pt;
            width: 100%;
            min-height: 116px;
        }

        .custom-file-input:hover::before {
            border-color: black;
        }

        .custom-file-input:active::before {
            background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
        }
    }
}