.content-section {
    .home-card-wrapper{
        .home-card-row{
            &.character-card-row{
                align-items: unset !important;
            }
        }
    }
    &.writer-section {
        position: relative;
        @media (max-width: 576px){
            padding-left: 10px !important;
        }
        .container-fluid{
            @media (max-width: 1366px){
                padding: 0;
            }
        }
        .writer-details-section {
            max-width: 90%;

            .information-wrapper {
                h3 {
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 20px;
                }

                .input-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    flex-wrap: wrap;

                    .input-field {
                        flex-basis: 49%;
                        width: 100%;
                        @media (max-width: 576px){
                            flex-basis: 100%;
                        }

                        input {
                            margin-bottom: 10px;
                        }
                    }
                }

                .upload-file-wrapper {
                    max-width: 49%;
                    @media (max-width: 576px){
                        max-width: 100%;
                    }
                    cursor: pointer;
                    .upload-file{
                        .selected-file-name{
                            word-break: break-all;
                            line-height: 16px;
                            font-size: 14px;
                            margin-bottom: 0;
                            margin: 0 15px;
                            @media (max-width: 1366px){
                                font-size: 12px;
                            }
                        }
                    }
                    .selected-file-name{
                        word-break: break-all;
                        line-height: 16px;
                        font-size: 14px;
                        margin-bottom: 0;
                        margin: 0 15px;
                        @media (max-width: 1366px){
                            font-size: 12px;
                        }
                    }
                }

                .button-wrapper {
                    display: flex;
                    max-width: 49%;
                    width: 100%;

                    justify-content: space-between;
                    @media (max-width: 576px){
                        max-width: 100%;
                    }
                    .board-btn {
                        max-width: 281px;
                        margin-top: 50px;
                        flex-basis: 48%;
                    }
                }
            }
        }

        .open-character-section {
            padding-bottom: 20px;
            .home-card-wrapper{
                margin-bottom: 20px;
            }
        }
    }
}