.chat-input-section {
    border-top: 1px solid #6F8BDF;
    max-height: 94px;
    height: 100%;
    padding: 34px !important;

    @media (max-width: 1366px) {
        padding: 25px !important;
        max-height: 75px;
    }

    @media (max-width: 1200px) {
        padding: 20px !important;
    }

    @media (max-width: 576px) {
        max-height: 60px;
        padding: 20px 10px !important;
    }

    @media (max-width: 400px) {
        max-height: 50px;
        padding: 15px 10px !important;
    }

    .chat-input-wrapper {
        .left-details {
            position: relative;
            display: flex;
            align-items: center;

            .emoji {
                margin-right: 35px;
                cursor: pointer;

                @media (max-width: 1200px) {
                    margin-right: 25px;
                }

                @media (max-width: 576px) {
                    margin-right: 10px;
                }

                img {
                    @media (max-width: 1200px) {
                        height: 25px;
                        width: 25px;
                    }

                    @media (max-width: 576px) {
                        height: 20px;
                        width: 20px;
                    }

                    @media (max-width: 400px) {
                        height: 15px;
                        width: 15px;
                    }
                }
            }

            .emoji-picker-wrapper {
                position: absolute;
                bottom: calc(100% + 10px);
            }

            .input-wrapper {
                width: 100%;

                @media (max-width: 1200px) {
                    width: 90%;
                }

                .message-input-field {
                    border: none;
                    width: "inherit";
                }

                textarea {
                    border: none;
                    width: 100%;
                    background: none;

                    &:focus {
                        outline: none;
                    }
                }

                .disabled {
                    background: unset;
                }

                input {
                    &::placeholder {
                        @media (max-width: 576px) {
                            font-size: 12px;
                        }
                    }
                }

                .chat-selected-file {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    background-color: #fff;
                    width: 97%;
                    overflow: auto;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .file {
                        background-color: #efefef;
                        border: 1px solid #a2a2a2;
                        color: #6F8BDF;
                        border-radius: 4px;
                        padding: 2px 6px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: fit-content;
                        position: relative;

                        &:hover {
                            .remove-file {
                                display: flex;
                            }
                        }

                        &:not(:first-of-type) {
                            margin-left: 6px;
                        }

                        .remove-file {
                            position: absolute;
                            top: 0;
                            right: 0;
                            background-color: rgba($color: #000000, $alpha: 0.6);
                            cursor: pointer;
                            min-height: 18px;
                            width: 18px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            display: none;
                            span{
                                font-size: 12px;
                                color: #fff;
                                line-height: 1;
                                margin-top: -3px;
                            }
                        }

                        p {
                            font-size: 14px;
                            margin-bottom: 0;

                            @media (max-width: 1366px) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .right-details {
            .action-wrapper {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                label {
                    img {
                        @media (max-width: 1200px) {
                            height: 25px;
                            width: 25px;
                        }

                        @media (max-width: 576px) {
                            height: 20px;
                            width: 20px;
                        }

                        @media (max-width: 400px) {
                            height: 15px;
                            width: 15px;
                        }
                    }
                }

                .mic-icon-wrapper {
                    position: relative;

                    .mic-options {
                        position: absolute;
                        bottom: calc(100% + 6px);
                        right: 90%;
                        min-height: 50px;
                        min-width: max-content;
                        background-color: #efefef;
                        border: 1px solid #6F8BDF;
                        border-radius: 4px;
                        padding: 6px 10px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        p {
                            margin-bottom: 6px;
                            margin-right: 20px;

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }

                        button {
                            padding-left: 0;
                        }
                    }

                    img {
                        @media (max-width: 1200px) {
                            height: 25px;
                            width: 25px;
                        }

                        @media (max-width: 576px) {
                            height: 20px;
                            width: 20px;
                        }

                        @media (max-width: 400px) {
                            height: 15px;
                            width: 15px;
                        }
                    }
                }

                img {
                    margin: 0 16px;
                    cursor: pointer;

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 20px;

                        @media (max-width: 1366px) {
                            margin-right: 15px;
                        }

                        @media (max-width: 1200px) {
                            margin-right: 10px;
                        }

                        @media (max-width: 400px) {
                            margin-right: 5px;
                        }
                    }
                }

                .last-action {
                    border-left: 1px solid #6F8BDF;

                    img {
                        margin: 0;
                        padding-left: 25px;

                        @media (max-width: 1366px) {
                            padding-left: 15px;
                        }

                        @media (max-width: 1200px) {
                            padding-left: 10px;
                            height: 30px;
                        }

                        @media (max-width: 576px) {
                            height: 20px;
                        }

                        @media (max-width: 400px) {
                            height: 15px;
                            padding-left: 5px;
                        }
                    }
                }

                .send-spinner {
                    margin-top: 5px;
                }
            }
        }
    }
}