.home-genre-modal {
    &.producer-home-modal {
        .modal-body-right {
            &.producer-modal-body-right {
                min-height: 516px;

                @media (max-width: 920px) {
                    height: unset !important;
                }

                .producer-modal-right {
                    .control-button-wrapper {
                        display: flex;
                        align-items: center;

                        h3 {
                            margin-bottom: 0;
                            font-size: 18px;
                            width: 120px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .board-btn {
                            margin: 0 21px;
                        }

                        .control-btn {
                            width: 50px;
                            margin: 0 6px;

                            &:first-of-type {
                                margin-left: 0;
                            }

                            span {
                                img {
                                    margin-right: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                    }

                    overflow: unset !important;

                    .body-text-wrapper {
                        max-height: 180px;
                        overflow: auto;

                        @media (min-height: 900px) {
                            max-height: 350px;
                        }
                    }
                }
            }
        }

        .modal-body-left {
            &.producer-modal-body-left {
                min-height: 516px;
            }
        }
    }
    .modal-footer {
        
    
        
      
        display: flex;
        justify-content:space-around;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
      width: 100%;
    
        gap: 5px;

        @media (max-width: 768px) {
            padding: 5px 15px;

            .text {
                font-size: 12px;
            }
        }
    }
}