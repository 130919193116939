.landing-page-section {
    @media (max-width: 768px) {
        padding: -6px 0;
    }

    &.policy-page-section {
        justify-content: unset;
        overflow: hidden;

        @media (max-width:1024px) {
            padding-bottom: 15%;
        }

        @media (max-width:576px) {
            padding-bottom: 35%;
        }

        .policy-content-row {
            max-width: 90%;
            margin: auto;
            // @media (max-width:1680px) and (max-height:900px) {
            //     padding: 0 40px;
            // }
            // @media (max-width:1024px)  {
            //     padding: 0 30px;

            // }
            @media (max-width:576px) {
                padding: 0 10px;
                max-width: 100%;
                margin: 10px;
            }
        }

        .policy-wrapper {
            margin-top: 40px;
            max-height: 80vh;
            overflow: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar{
                display: none;
            }

            @media (max-width:1920px) and (max-height:900px) {
                margin-top: 30px;
            }

            @media (max-width:1680px) and (max-height:900px) {
                margin-top: 25px;
            }

            @media (max-width:1440px) and (max-height:800px) {
                margin-top: 20px;
            }

            @media (max-width:1440px) and (max-height:800px) {
                margin-top: 20px;
            }



            .header {
                .back-icon {
                    background-color: #6F8BDF;
                    height: 50px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 12px;
                    cursor: pointer;

                    @media (max-width:1920px) and (max-height:900px) {
                        height: 40px;
                        width: 40px;
                    }

                    @media (max-width:768px) {
                        height: 30px;
                        width: 30px;
                        border-radius: 8px;

                        img {
                            height: 10px;
                            width: 10px;
                        }
                    }
                }

                h2 {
                    font-size: 64px;
                    font-weight: 700;
                    color: #1A1B22;
                    margin: 30px 0;

                    @media (max-width:1920px) and (max-height:900px) {
                        margin: 20px 0 15px;
                    }

                    @media (max-width:1680px) and (max-height:900px) {
                        margin: 15px 0;
                        font-size: 56px;
                    }

                    @media (max-width:1440px) and (max-height:800px) {
                        margin: 12px 0;
                        font-size: 50px;
                    }

                    @media (max-width:1366px) {
                        font-size: 40px;
                    }



                    @media (max-width: 768px) {
                        font-size: 34px;
                    }

                    @media (max-width: 576px) {
                        font-size: 28px;
                    }

                }

                h3 {
                    font-size: 24px;
                    color: #000;
                    position: relative;
                    max-width: 327px;
                    font-weight: 400;
                    margin-bottom: 30px;

                    @media (max-width:1680px) and (max-height:900px) {
                        font-size: 20px;
                        max-width: 290px;
                    }

                    @media (max-width:1440px) and (max-height:800px) {
                        font-size: 18px;
                    }

                    @media (max-width:1024px) and (max-height:800px) {
                        font-size: 16px;
                    }

                    @media (max-width:576px) and (max-height:800px) {
                        font-size: 15px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        width: 157px;
                        height: 5px;
                        background-color: #F6B21A;
                        border-radius: 56px;

                        @media (max-width:576px) and (max-height:800px) {
                            width: 100px;
                        }
                    }
                }
            }

            h3 {
                font-size: 24px;
                margin: 20px 0;

                @media (max-width:1680px) and (max-height:900px) {
                    font-size: 20px;
                    max-width: 290px;
                    margin: 15px 0;
                }

                @media (max-width:1600px) and (max-height:750px) {
                    margin: 10px 0;
                }

                @media (max-width:1440px) and (max-height:800px) {
                    font-size: 18px;
                }
            }   

            .policy-content {
                margin-top: 20px;
                max-width: 95%;

                p {
                    line-height: 19.2px;
                    font-weight: 400;

                    font-family: 'Lato';

                    @media (max-width:1680px) and (max-height:900px) {
                        font-size: 15px;
                    }

                    @media (max-width:1600px) and (max-height:750px) {
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: 10px;
                    }

                    @media (max-width:1440px) and (max-height:800px) {
                        font-size: 13px;
                    }

                    @media (max-width:1024px) {
                        font-size: 11px;
                        line-height: 15px;
                    }
                }
            }

            &.terms-wrapper {
                .header {
                    h3 {
                        &::after {
                            width: 94px;

                            @media (max-width:1680px) and (max-height:900px) {
                                width: 85px;
                            }
                        }
                    }
                }
            }
        }
    }
}