.modal-backdrop {
    &.show {
        z-index: 9 !important;
    }
}

.admin-modal {
    z-index: 9 !important;

    .modal-content {
        border-radius: 16px !important;
    }

    .modal-body {
        padding: 0 30px;

        @media (max-width:768px) {
            padding: 0 10px;
        }
    }

    .modal-dialog {
        width: 726px;
        max-width: 100%;

        @media (max-width: 768px) {
            max-width: 90%;
        }

        @media (max-width: 576px) {
            margin: auto;
        }

        @media (min-width:1600px) {
            width: 900px;
        }

        @media (min-width:1680px) {
            width: 950px;
        }
    }

    .modal-body {
        @media (max-width: 1600px) and (max-height: 800px) {
            padding: 0;
        }
    }

    .add-user-section {
        .add-user-header {
            padding-top: 50px;

            @media (max-width: 1920px) and (max-height: 900px) {
                padding-top: 30px;
            }

            @media (max-width: 1600px) and (max-height: 850px) {
                padding-top: 20px;
            }

            @media (max-width: 1366px) {
                padding-top: 15px;
            }

            @media (max-width: 1366px) and (max-height: 630px) {
                padding-top: 10px;
            }

            text-align: center;

            img {
                height: 130px;

                @media (max-width: 1920px) and (max-height: 900px) {
                    height: 120px;
                }

                @media (max-width: 1600px) and (max-height: 850px) {
                    height: 110px;
                }

                @media (max-width: 1366px) {
                    height: 80px;
                }

                @media (max-width: 1280px) {
                    height: 60px;
                }
            }

            h3 {
                font-size: 24px;
                font-family: 'PT Serif';
                font-weight: 700;
                font-size: 32px;
                color: #2B292D;
                margin: 45px 0;

                @media (max-width: 1920px) and (max-height: 900px) {
                    margin: 20px 0;
                }

                @media (max-width: 1680px) and (max-height: 950px) {
                    font-size: 26px;
                    margin: 10px 0;
                }

                @media (max-width:1600px) and (max-height: 730px) {
                    font-size: 24px;
                }

                @media (max-width:1366px) {
                    font-size: 24px;
                    margin: 20px 0;
                }

                @media (max-width: 1366px) and (max-height: 630px) {
                    font-size: 20px;
                    margin-top: 5px;
                }

                @media (max-width: 768px) {
                    margin: 15px 0;
                }
            }
        }

        .add-user-input-wrapper {
            padding: 0 53px;

            @media (max-width: 768px) {
                padding: 0 40px;
            }

            @media (max-width: 576px) {
                padding: 0 20px;
            }

            @media (max-width: 400px) {
                padding: 0 10px;
            }

            .error-message {
                display: block;
                color: red;
                font-size: 14px;
                margin: 6px 0;
                line-height: 16px;
                margin-bottom: 10px;
                min-height: 16px;

                @media (max-width:1680px) {
                    font-size: 12px;
                    line-height: 14px;
                    margin: 6px 0;
                }

                @media (max-width:1440px) {
                    font-size: 11px;
                    line-height: 13px;
                }

                @media (max-width: 1366px) {
                    margin-top: 3px;
                    min-height: 14px;
                }

                @media (max-width:1366px) and (max-height:630px) {
                    font-size: 10px;
                    margin-bottom: 6px;
                }

                @media(max-width: 576px) {
                    font-size: 10px;
                    margin: 4px 0 6px;
                }
            }

        }

        .checkbox-wrapper {
            margin: 30px auto 25px;
            display: flex;
            justify-content: center;

            label {
                img {
                    margin: 0 7px;
                }
            }
        }

        .button-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 87px;
            padding: 0 53px;

            @media (max-width: 1366px) {
                margin-bottom: 30px;
            }

            @media (max-width: 768px) {
                padding: 0 40px;
            }

            @media (max-width: 576px) {
                padding: 0 20px;
            }

            @media (max-width: 400px) {
                padding: 0 10px;
            }

            .board-btn {
                font-weight: 500;
                font-size: 16px;
                flex-basis: 48%;
                margin: 10px 0;

                @media (max-width:1600px) and (max-height:850px) {
                    margin-top: 0;
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width:1366px) {
                    margin: 0;
                    font-size: 14px;
                    max-width: 250px;
                }

                @media (max-width: 1366px) and (max-height:630px) {
                    font-size: 12px;
                    line-height: 16px;
                }

                @media (max-width: 768px) {
                    max-width: 180px;
                }

                @media (max-width:576px) {
                    max-width: 140px;
                }

                &.transparent-btn {
                    margin-right: 30px;
                }
            }
        }

        &.new-push-notification-section {
            .add-user-header {
                h3 {
                    @media (max-width: 1366px) {
                        margin: 40px 0 50px;
                    }
                }

                img {
                    height: 130px;

                    @media (max-width: 768px) {
                        height: 100px;
                    }
                }
            }

            .button-wrapper {
                .board-btn {
                    &.transparent-btn {
                        margin-left: 30px;
                        margin-right: 0;
                    }
                }
            }
        }

        &.create-push-notification-section,
        &.create-group-notification-section {
            .notification-creation-wrapper {
                width: 600px !important;
                max-width: 100%;
                margin: auto;

                @media (max-width: 768px) {
                    max-width: 90%;
                }

                @media (min-width: 1600px) {
                    width: 800px !important;
                }

                .search-wrapper {
                    margin-top: 0 !important;
                    margin-bottom: 20px;
                }

                h3 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }

                .field-row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 30px;

                    @media (max-width: 576px) {
                        flex-direction: column;
                        margin-bottom: 10px;
                    }

                    .field {
                        flex-basis: 48%;
                        position: relative;

                        @media (max-width: 576px) {
                            margin-bottom: 20px;
                        }

                        label {
                            display: none;
                        }

                        &.time-picker-field {
                            position: relative;
                        }
                    }
                }

                .text-area-field {
                    label {
                        display: none;
                    }
                }

                .search-wrapper {
                    margin-top: 30px;
                }

                .selected,
                .suggested-wrapper {
                    margin-bottom: 15px;
                    display: flex;

                    .avatar-img {
                        height: 32px;
                        width: 32px;

                        @media (max-width: 1366px) {
                            height: 26px;
                            width: 26px;
                        }

                        &:not(:first-of-type) {
                            margin-left: 10px;
                        }

                        &.selected-avatar {
                            position: relative;

                            @media (max-width: 1366px) {
                                height: 26px;
                                width: 26px;
                            }

                            &::before {
                                position: absolute;
                                content: '';
                                background-image: url('../../../assets/images/list-check.svg');
                                background-position: center;
                                background-size: 80% 80%;
                                background-repeat: no-repeat;
                                background-color: #2B292D;
                                opacity: 0.8;
                                height: 100%;
                                width: 100%;
                                border-radius: 50%;
                                top: 0;
                                left: 0;
                                z-index: 1;
                            }
                        }

                        img {
                            height: 100%;
                            width: 100%;
                            border-radius: 50%;
                        }
                    }
                }

                .selected-user-wrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    opacity: 0;
                    min-height: 47px;

                    // @media (max-width: 1366px) {
                    //     min-height: 41px;
                    // }

                    .avatar-img {
                        margin: 0 9px;

                        &:first-of-type {
                            margin-left: 0;
                        }
                    }

                    &.opacity-1 {
                        opacity: 1;
                    }
                }
            }

            .button-wrapper {
                margin: 40px 0;

                @media (max-width: 1366px) {
                    margin: 20px 0;
                }

                @media (max-width: 400px) {
                    flex-direction: column;
                    margin: 20px auto;
                    align-items: center;

                    .board-btn {
                        width: 100%;

                        &.transparent-btn {
                            margin-bottom: 20px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        &.create-group-notification-section {
            .group-creation-wrapper {
                .search-wrapper {
                    margin-bottom: 10px;
                }

                .create-group-wrapper {
                    margin-bottom: 30px;
                    background-color: #efefef;
                    border-radius: 8px;
                    padding: 16px;

                    .create-group-details {
                        position: relative;

                        .close-icon {
                            position: absolute;
                            top: 8px;
                            right: 15px;
                            cursor: pointer;
                        }

                        .new-group {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            color: #6F8BDF;
                            font-weight: 600;

                            img {
                                margin-right: 15px;
                            }
                        }

                        .users-details {
                            border-bottom: 1px solid #a2a2a2;
                            padding-bottom: 30px;

                            .user {
                                margin-top: 30px;

                                h4 {
                                    font-size: 16px;
                                    font-weight: 600;
                                    margin-bottom: 0;
                                    cursor: pointer;
                                }

                                &.with-input {
                                    display: flex;
                                    align-items: center;
                                    color: #6F8BDF;

                                    input {
                                        margin-left: 30px;
                                        background-color: #fff;
                                        border-radius: 8px;
                                        border: none;
                                        padding: 16px 24px;
                                    }
                                }
                            }
                        }

                        .button-wrapper {
                            margin: 30px 0 10px;
                        }
                    }
                }
            }
        }
    }

}