.create-submission-modal {
    .modal-dialog {
        width: 726px;
        max-width: 100%;

        @media (max-width: 768px) {
            margin: 15px auto;
            max-width: 90%;
        }

        @media (min-width:1920px) {
            width: 1440px;
        }

    }

    .submission-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
        position: relative;
        @media (max-width: 1366px) {
            padding: 25px 0;
        }

        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            z-index: 9;
        }

        .submission-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                margin: 35px 0;
                font-family: 'PT Serif';
                font-weight: 700;
                font-size: 32px;
                @media (max-width: 1366px) {
                    margin: 15px 0;
                    font-size: 26px;
                }
            }

            .top-img{
                @media (max-width: 1366px){
                    height: 120px;
                }
            }

            .submission-process-details {
                width: 560px;
                max-width: 100%;
                margin: auto;

                @media (max-width: 992px){
                    width: 90%;
                }

                @media (min-width: 1920px) {
                    width: 1100px;
                }

                .input-field {
                    label {
                        display: none;
                    }
                }

                .upload-document-wrapper {
                    h4 {
                        font-size: 18px;
                        font-weight: 700;
                        margin: 20px 0 15px;
                    }

                    .upload-document {
                        background-color: #efefef;
                        border: 1px solid #6F8BDF;
                        border-radius: 8px;
                        padding: 16px;

                        @media (max-width: 1366px) {
                            padding: 12px;
                        }

                        .uploaded-content {
                            display: flex;
                            align-items: flex-start;
                            max-height: 116px;
                            overflow-y: auto;
                            scrollbar-width: thin;
                            @media (max-width: 1366px) {
                                font-size: 14px;
                                max-height: 100px;
                            }
                            &::-webkit-scrollbar {
                                background-color: #5B5A78;
                                width: 8px;
                                border-radius: 4px;
                            }
                            &::-webkit-scrollbar-thumb{
                                background-color: #6F8BDF;
                                border-radius: 4px;
                                width: 8px;
                            }
                            p {
                                color: #2B292D;
                                line-height: 19px;
                                padding: 0 15px;
                            }
                        }
                    }
                }

                
                .button-wrapper {
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
    
                    @media (max-width: 1366px) {
                        margin-top: 20px;
                    }
    
                    .board-btn {
                        min-width: 281px;
                    }
                    &.bottom-button-wrapper{
                        margin-top: 50px;
                        @media (max-width: 1366px) {
                            margin-top: 25px;
                        }
                    }
                }

            }


        }
    }
}