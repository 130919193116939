.home-genre-modal {
    .modal-dialog {
        width: 902px;
        max-width: 100%;

        @media (max-width:1366px) {
            max-width: 90%;
        }

        @media (max-width:1100px) {
            max-width: 80%;
        }

        @media (max-width:768px) {
            max-width: 90%;
        }

        @media (max-width: 576px) {
            margin: auto;
        }

        @media (min-width: 1920px) {
            width: 1100px;
        }
    }

    .modal-content {
        // border-radius: 16px !important;
        padding: 0;
        background-color: unset;
        border: unset;

        .modal-body {
            display: flex;
            position: relative;
            justify-content: center;

            .controls-group {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;

                @media (max-width: 768px) {
                    transform: unset;
                    top: 100% !important;
                }

                .control {
                    background-color: #6F8BDF;
                    height: 50px;
                    width: 50px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    cursor: pointer;

                    @media (max-width: 992px) {
                        height: 40px !important;
                        width: 40px !important;
                    }

                    &.left-control {
                        right: calc(100% + 20px) !important;

                        @media (max-width: 992px) {
                            right: calc(100% - 40px) !important;
                        }

                        @media (max-width: 768px) {
                            right: 90px !important;
                        }
                    }

                    &.right-control {
                        left: calc(100% + 20px);

                        @media (max-width: 992px) {
                            left: calc(100% - 40px) !important;
                        }

                        @media (max-width: 768px) {
                            right: 15px !important;
                            left: unset !important;
                        }
                    }
                }
            }

            .modal-body-left {
                background-color: #efefef;
                min-width: 340px;
                height: 470px;
                max-height: 100%;
                border-radius: 16px 0 0 16px;

                @media (max-width: 992px) {
                    display: none;
                }

                @media (min-width: 1920px) {
                    max-width: 600px;
                    // height: 700px;
                }

                .image-wrapper {
                    width: calc(100% - 50px);
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 16px 0 0 16px;
                    }

                    &.no-img-wrapper {
                        img {
                            height: 50px;
                            width: 50px;
                            opacity: 0.6;
                        }
                    }
                }
            }

            .modal-body-right {
                background-color: #fff;
                min-width: 500px;
                box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
                border-radius: 16px;
                border-bottom-right-radius: 18px;
                margin-left: -72px;
                position: relative;
                height: 470px;
                max-height: 100%;

                @media (max-width: 992px) {
                    margin-left: 0;
                    min-width: unset;
                    width: 80%;
                }

                @media (max-width: 768px) {
                    width: 100%;
                    min-height: 400px;
                }

                @media (min-width: 1920px) {
                    max-width: 800px;
                    // height: 700px;
                }

                .modal-right-header {
                    padding: 24px;
                    padding-bottom: 0;

                    @media (max-width: 768px) {
                        padding: 20px 20px 0;
                    }

                    h3 {
                        font-size: 16px;
                        color: #5B5A78;

                        @media (max-width: 768px) {
                            font-size: 14px;
                            margin-bottom: 4px;
                        }
                    }

                    .novel {
                        font-weight: 700;
                        font-size: 24px;
                        color: #2B292D;
                        margin-bottom: 10px;

                        @media (max-width: 768px) {
                            font-size: 20px;
                            margin-bottom: 4px;
                        }

                        @media (max-width: 576px) {
                            font-size: 18px;
                        }
                    }

                    .author-details {
                        display: flex;
                        cursor: pointer;
                        a{
                            color: #6F8BDF;
                            font-family: 'Inter';
                            font-weight: 700;
                            margin-bottom: 0;
                            cursor: pointer;
                            @media (max-width: 576px) {
                                font-size: 14px;
                            }
                        }

                        .writer {
                            margin-left: 32px;
                            position: relative;
                            cursor: pointer;

                            &::before {
                                position: absolute;
                                content: '';
                                height: 4px;
                                width: 4px;
                                background-color: #6F8BDF;
                                top: 10px;
                                left: -16px;
                                border-radius: 50%;
                            }
                        }
                    }

                    .close-icon {
                        position: absolute;
                        top: 18px;
                        right: 18px;
                        cursor: pointer;
                        z-index: 9;

                        @media(max-width:1366px) {
                            top: 8px;
                        }
                    }
                }

                .modal-right-body {
                    margin-top: 20px;
                    padding: 0 5px 56px 24px;
                    max-height: 50%;
                    // overflow: auto;
                    scrollbar-width: thin;

                    &::-webkit-scrollbar {
                        width: 10px;
                        background-color: #5B5A78;
                    }

                    &::-webkit-scrollbar-thumb {
                        width: 8px;
                        background-color: #8c8ba3;
                    }

                    @media (max-width: 768px) {
                        padding: 0 5px 56px 20px;
                    }

                    @media (min-width: 1920px) {
                        max-height: 80%;
                    }

                    .body-text-wrapper {
                        padding-right: 19px;
                        scrollbar-width: thin;
                        max-height: 190px;
                        overflow: auto;
                        @media (min-height: 900px){
                            max-height: 350px;
                        }

                        @media (max-width: 768px) {
                            max-height: 250px;
                            overflow-y: auto;
                        }

                        @media (max-width: 576px) {
                            max-height: 220px;
                        }


                        &::-webkit-scrollbar {
                            width: 8px;
                            background-color: #5B5A78;
                        }

                        &::-webkit-scrollbar-thumb {
                            width: 8px;
                            background-color: #8c8ba3;
                        }

                        .text-wrapper {
                            h3 {
                                font-size: 16px;
                                color: #2B292D;
                                font-weight: 600;
                            }

                            p {
                                color: #5B5A78;
                                line-height: 21px;

                                @media (max-width: 768px) {
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }

                    .read-details-wrapper {
                        display: flex;
                        align-items: center;
                        margin: 20px 0;

                        @media (max-width: 992px) {
                            flex-wrap: wrap;
                        }

                        @media (max-width: 576px) {
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        .icon-wrapper {
                            display: flex;
                            align-items: center;

                            &:not(:first-of-type) {
                                margin-left: 25px;

                                @media (max-width: 576px) {
                                    margin-left: 0;
                                    margin-top: 8px;
                                }
                            }

                            span {
                                margin-left: 10px;
                                color: #F6B21A;
                                font-size: 14px;
                                font-weight: 600;
                                font-family: 'Inter';
                                letter-spacing: -0.03em;

                                @media (max-width: 576px) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                }

                .button-wrapper {
                    margin: 30px 0;
                    display: flex;

                    .board-btn {
                        flex-basis: 45%;

                        &.transparent-btn {
                            margin-left: 30px;

                            @media (max-width: 992px) {
                                margin-left: 0;
                                margin-top: 20px;
                            }
                        }
                    }

                    @media (max-width: 992px) {
                        flex-direction: column;
                        margin: 20px 0;
                        max-width: 230px;
                        justify-content: center;
                    }
                }

                .modal-right-footer {
                    background-color: #6F8BDF;
                    padding: 16px 24px;
                    color: #fff;
                    border-radius: 0 0 16px 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    cursor: pointer;

                    @media (max-width: 768px) {
                        padding: 5px 15px;

                        .text {
                            font-size: 12px;
                        }
                    }
                }

                .no-data-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    height: 100%;

                    .close-icon {
                        position: absolute;
                        top: 18px;
                        right: 18px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

}