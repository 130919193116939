.content-section{
    &.admin-feedback-section{
        padding: 38px 35px !important;
        @media (max-width: 1366px){
            padding: 38px 20px !important;
        }
        @media (max-width: 576px){
            padding: 38px 10px !important;
        }
        .admin-feedback-wrapper{
            .feedback-message-wrapper{
                background-color: #efefef;
                border-radius: 16px;
                padding: 24px;
                .feedback-avatar-details{
                    .avatar{
                        display: flex;
                        align-items: center;
                        @media (max-width: 576px){
                            flex-direction: column;
                        }
                        img{
                            height: 63px;
                            width: 63px;
                            border-radius: 50%;
                            @media (max-width: 576px){
                                margin-bottom: 15px;
                            }
                        }
                        .details{
                            margin-left: 16px;
                            @media (max-width: 576px){
                                align-self: flex-start;
                                margin-left: 0;
                            }
                            .id{
                                font-size: 12px;
                                color: #5B5A78;
                                font-family: 'Inter';

                            }
                            .name{
                                font-size: 18px;
                                font-weight: 600;
                                margin: 2px 0;
                                line-height: 1;
                            }
                            .email{
                                color: #6F8BDF;
                                font-family: 'Inter';
                            }
                        }
                    }
                }
                .feedback-details{
                    padding: 20px 0;
                    border-bottom: 1px solid #A2A2A2;
                    h3{
                        font-size: 24px;
                        font-weight: 700;
                    }
                    p{
                        color: #5B5A78;
                        text-align: justify;
                        padding-right: 15px;
                        line-height: 21px;
                    }
                }
                .button-wrapper{
                    .board-btn{
                        background-color: #6F8BDF;
                        &.active{
                            background-color: #3D59AB;
                        }
                    }
                }
            }
            .button-wrapper{
                margin-top: 23px;
                .board-btn{
                    min-width: 170px;
                }
            }
            .reply-feedback-wrapper{
                padding: 0;
                margin-top: 30px;
                opacity: 0;
                transition: all 0.2s;
                textarea{
                    max-width: 50%;
                    @media (max-width: 768px){
                        max-width: 100%;
                    }
                }
            }
        }
    }
}