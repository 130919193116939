.content-section {
    &.admin-users-section {
        padding-right: 30px !important;
        margin-bottom: 45px !important;
        @media (max-width: 992px){
           padding-right: 20px !important;
        }
        @media (max-width: 576px){
           padding-right: 10px !important;
        }
        .admin-users-wrapper {
            .admin-users-container {
                .admin-users-row {
                    .table-wrapper {
                        padding-right: 0;
                        .users-title-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 20px;

                            .title-wrapper {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                max-width: 220px;
                                width: 100%;
                                @media (max-width: 576px){
                                    max-width: 150px;
                                }
                                @media (max-width: 400px){
                                    max-width: 115px;
                                }

                                h3 {
                                    font-size: 24px;
                                    font-weight: 700;
                                    margin-bottom: 0;
                                    @media (max-width: 768px){
                                        font-size: 22px;
                                    }
                                    @media (max-width: 576px){
                                        font-size: 20px;
                                    }
                                    @media (max-width: 400px){
                                        font-size: 18px;
                                    }
                                }

                                .filter-wrapper {
                                    border: 1px solid #2B292D;
                                    height: 50px;
                                    width: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 12px;
                                    cursor: pointer;
                                    position: relative;

                                    @media (max-width: 1366px) {
                                        height: 40px;
                                        width: 40px;
                                        border-radius: 8px;
                                    }
                                    @media (max-width: 576px) {
                                        height: 30px;
                                        width: 30px;
                                        border-radius: 6px;
                                        img{
                                            height: 70%;
                                            width: 70%;
                                        }
                                    }

                                    .active-icon{
                                        display: none;
                                    }

                                    &.active {
                                        border-color: #6F8BDF;
                                        .icon{
                                            display: none;
                                        }
                                        .active-icon{
                                            display: block;
                                        }
                                    }

                                    .option-items {
                                        position: absolute;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        top: calc(100% + 15px);
                                        background-color: #fff;
                                        border: 1px solid #2b292d;
                                        padding: 10px;
                                        min-width: 133px;
                                        border-radius: 8px;
                                        z-index: 9;

                                        .title{
                                            font-weight: 600;
                                        }

                                        .item {
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 14px;
                                            padding: 0 6px;
                                            margin-top: 10px;

                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }

                                            label {
                                                font-size: 12px;
                                            }
                                        }

                                        &::before {
                                            content: '';
                                            display: block;
                                            position: absolute;
                                            background-image: url('../../../assets/images/tooltip-left-icon.svg');
                                            background-position: center;
                                            background-size: 100% 100%;
                                            background-repeat: no-repeat;
                                            left: 50%;
                                            bottom: calc(100% - 12px);
                                            transform: translateX(-50%) rotate(-90deg);
                                            width: 30px;
                                            height: 30px;
                                            top: unset;
                                        }
                                    }
                                }
                            }

                            .button-wrapper {
                                .board-btn {
                                    min-width: 230px;
                                    @media (max-width: 992px){
                                        min-height: 40px;
                                    }
                                    @media (max-width: 576px){
                                        min-width: 140px;
                                    }
                                    @media (max-width: 400px){
                                        min-width: 110px;
                                        min-height: 35px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}