.personal-info-modal {
    &.apply-position-modal {
        .modal-dialog{
            @media (max-width: 576px){
                max-width: 90%;
                margin: auto;
            }
        }
        .apply-control-group{
            display: none;
        }
        .close-modal {
            position: absolute;
            right: 20px;
            top: 10px;
            cursor: pointer;
        }

        .apply-wrapper {
            padding: 20px 83px;

            @media (max-width:1366px) and (max-height: 600px) {
                padding: 10px 83px;
            }

            @media (max-width: 992px){
                padding: 10px 10px;
            }

            .apply-section {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 162px;
                    margin-top: 40px;
                    @media (max-width: 1366px){
                        height: 100px;
                        margin-top: 0;
                    }
                    @media (max-width: 1280px){
                        height: 80px;
                    }

                }

            }

            h3 {
                margin: 40px 0 !important;
                font-size: 32px;
                font-weight: 700;
                font-family: "PT Serif";
                text-align: center;
                @media (max-width: 1366px){
                    margin: 20px 0 !important;
                    font-size: 28px;
                }
                @media (max-width: 1280px){
                    margin: 15px 0 !important;
                    font-size: 26px;
                }
                @media (max-width: 576px){
                    font-size: 22px;
                }
            }

            .upload-wrapper {
                background-color: #efefef;
                border-radius: 8px;
                min-height: 116px;
                display: flex;
                justify-content: center;
                align-items: center;


                .upload-file {
                    text-align: center;
                    width: 100%;



                    .input-field {
                        width: 100%;
                        flex-basis: 49%;

                        input {
                            width: 100%;
                            padding: 0;
                            height: 100%;
                            max-height: 116px;
                            background-color: unset;
                        }

                        label {
                            display: none;
                        }
                    }

                    .custom-file-input::-webkit-file-upload-button {
                        visibility: hidden;
                    }

                    .custom-file-input::before {
                        content: '';
                        display: inline-block;
                        background-color: #efefef;
                        border-radius: 3px;
                        padding: 5px 8px;
                        outline: none;
                        white-space: nowrap;
                        -webkit-user-select: none;
                        cursor: pointer;
                        text-shadow: 1px 1px #fff;
                        font-weight: 700;
                        font-size: 10pt;
                        width: 100%;
                        min-height: 116px;
                    }

                    .custom-file-input:hover::before {
                        border-color: black;
                    }

                    .custom-file-input:active::before {
                        background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
                    }
                }
            }

            .button-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                .board-btn {
                    margin: 24px 0;
                    font-size: 14px;
                    min-width: 281px;
                    min-height: 50px;
                    align-self: center;
                    background-color: #6F8BDF;

                    @media (max-width:1600px) and (max-height:1000px) {
                        padding: 6px 16px;
                        line-height: 21px;
                        margin: 50px 0;
                    }

                    @media (max-width:1366px) {
                        margin: 16px 0;
                    }
                    @media (max-width:1366px) and (max-height: 600px) {
                        margin: 10px 0;
                        min-height: 40px;
                    }
                    @media (max-width: 576px){
                        min-width: 230px;
                    }

                }
            }
        }
    }
}