.content-section {
    &.open-application-section {

        .open-application-wrapper {
            .open-application-container {
                padding-right: 32px;
                @media (max-width: 1366px){
                    padding-left: 5px;
                }
                @media (max-width: 1280px){
                    padding-right: 20px;
                }
                @media (max-width: 576px){
                    padding-right: 10px;
                    padding-left: 10px;
                }
                .application-card-wrapper{
                    animation: zoomIn 0.6s forwards;
                }
            }
        }
    }
}

// Animation
@keyframes zoomIn {
    0% {
       transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}