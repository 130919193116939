.admin-section {
    display: flex;
    width: 100%;
    overflow: hidden;

    .main-section {
        width: 100%;
        max-height: initial !important;
        min-width: calc(100% - 312px);

        .content-section {
            &.admin-content-section {
                padding: 38px 20px 38px 15px;
                overflow: auto;
                max-height: calc(100vh - 117px);
                scrollbar-width: none;

                @media (max-width: 1366px) {
                    max-height: calc(100vh - 100px);
                }

                @media (max-width: 1280px) {
                    padding-left: 10px;
                }

                @media (max-width: 992px) {
                    max-height: calc(100vh - 80px);
                }

                @media (max-width: 576px) {
                    padding-left: 0;
                    padding-right: 8px;
                }

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .admin-home-wrapper {
                width: 100%;
                margin-bottom: 50px;

                @media (max-width: 1024px) {
                    margin-bottom: 0;
                }

                h2 {
                    font-family: 'Lato';
                    font-weight: 700;
                    font-size: 24px;
                    color: #2B292D;
                    margin-bottom: 30px;

                    @media (max-width: 1366px) {
                        font-size: 20px;
                        margin-bottom: 20px;
                    }

                    @media (max-width: 1024px) {
                        margin: 20px 0;
                    }

                    @media (max-width: 1024px) {
                        font-size: 18px;
                    }
                }

                .admin-home-container {
                    @media (max-width: 576px) {
                        padding-right: 5px;
                    }
                }

                .admin-row-wrapper {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .no-data {
                        text-align: center;
                    }

                    .top-novels-no-data {
                        height: 214px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 25px;
                    }
                }
            }
        }
    }
}