.position-modal {
    .modal-body-left {
        background-color: #efefef;
        min-width: 320px;
        min-height: 468px;
        border-radius: 16px 0 0 16px;

        @media (max-width: 992px) {
            display: none;
        }

        @media (min-width: 1920px) {
            min-width: 300px;
        }

        .image-wrapper {
            width: calc(100% - 50px);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 100%;
                width: 100%;
                border-radius: 16px 0 0 16px;
            }
        }

        .modal-body-right {
            max-width: unset;
            min-width: 500px;
        }
    }

    .button-wrapper {
        position: absolute;
        bottom: 0px;
        left: 20px;

        .board-btn {
           font-weight: 700;
            font-size: 16px;
            min-width: 103px;
        }

    }
}