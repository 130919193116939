.content-section {
    &.open-application-section {
        
        .open-application-wrapper {
            .open-application-container {
                padding-right: 32px;
                @media (max-width: 1366px){
                    padding-left: 5px;
                }
                .toggle-select{
                    margin-bottom: 28px;
                }
                .application-card-wrapper{
                    animation: zoomIn 0.6s forwards;
                }
                .application-heading{
                    margin-bottom: 30px;
                }
            }
        }
    }
}

// Animation
@keyframes zoomIn {
    0% {
       transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}