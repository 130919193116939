.token-store-section {
    .token-store-wrapper {
        .price-card-section {
            padding-right: 25px;
            .price-card-container {
                padding: 0;

                .price-card-wrapper {
                    margin-top: 50px;
                    @media (max-width:767px) {
                               
                        margin-top: 60px;
                         
                     }

                    .price-card {
                        background-color: #efefef;
                        border-radius: 13px;
                        justify-content: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 40px 0;
                        transition: all 0.5s;
                        position: relative;

                        @media (max-width: 1600px) and (max-height: 1000px) {
                            padding: 30px 0;
                        }

                        @media (max-width:1366px) {
                            padding: 25px 0;
                            margin-top: 10px;
                        }

                        .active-subscription {
                            background-color: #F6B21A;
                            color: #2B292D;
                            width: 80%;
                            margin: auto;
                            padding: 6px;
                            text-align: center;
                            border-radius: 6px 6px 0 0;
                            font-weight: 700;
                            position: absolute;
                            bottom: 100%;
                            @media (max-width:1366px){
                                font-size: 14px;
                            }
                            @media (max-width:1100px) {
                               
                                font-size: 10px;
                                
                            }
                            
                        }

                        h3 {
                            font-weight: 600;
                            font-size: 24px;
                            font-family: 'Lato';
                            color: #2B292D;
                            margin-bottom: 0;

                            @media (max-width:1366px) {
                                font-size: 20px;
                            }
                        }

                        .book-img {
                            margin: 45px 0;

                            @media (max-width: 1600px) and (max-height:1000px) {
                                margin: 15px 0;
                                height: 80px;
                            }

                            @media (max-width:1366px) {
                                margin: 30px 0;
                                height: 70px;
                            }
                        }

                        .price {
                            font-size: 30px;
                            font-family: 'Lato';
                            color: #5B5A78;

                            @media (max-width:1366px) {
                                font-size: 26px;
                            }

                            @media (max-width:1366px) and (max-height:630px) {
                                font-size: 24px;
                            }

                            @media (max-width:1366px) and (max-height:600px) {
                                font-size: 22px;
                            }

                            .month {
                                color: #6F8BDF;
                                font-size: 13px;
                            }
                        }

                        .button-wrapper {
                            .board-btn {
                                margin: 25px 0;
                                font-size: 14px;
                                min-width: 185px;

                                @media (max-width:1600px) and (max-height:1000px) {
                                    padding: 6px 16px;
                                    line-height: 21px;
                                    margin: 16px 0;
                                }

                                @media (max-width:1366px) {
                                    margin: 12px 0;
                                    font-size: 12px;
                                    min-width: 165px;
                                    padding: 6px 12px;
                                    line-height: 18px;
                                }
                                @media (max-width:1100px) {
                               
                                    min-width: 125px;
                                    
                                }
                            }
                        }

                        .list-wrapper {
                            ul {
                                list-style: none;
                                max-width: 100%;
                                margin: auto;
                                padding-left: 0px;


                                li {
                                    display: flex;
                                    margin: 5px 0;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 21px;
                                    margin-bottom: 12px;

                                    @media (max-width:1366px) {
                                        font-size: 12px;
                                        line-height: 20px;
                                    }

                                    @media (max-width:1366px) and (max-height:600px) {
                                        font-size: 10px;
                                        line-height: 16px;
                                    }

                                    img {
                                        margin-right: 11px;
                                    }
                                }
                            }
                        }
                    }

                    &.subscribed-card {
                        .price-card {
                            background-color: #2B292D;
                            transform: scaleY(1.1);

                            @media (max-width:1366px) and (max-height:600px) {
                                transform: scaleY(1.08);
                            }

                            h3 {
                                color: #6F8BDF;
                            }

                            .price {
                                color: #efefef;
                            }

                            li {
                                color: #efefef;
                            }

                            .transparent-btn {
                                background-color: #6F8BDF;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}