.edit-subscribe-section {
    padding-top: 10px !important;
    padding-left: 0 !important;

    .edit-subscribe-section-wrapper {
        .edit-subscribe-card-section {
            padding-right: 25px;

            @media (max-width: 1280px) {
                padding-right: 15px;
            }

            @media (max-width: 1200px) {
                padding-right: 12px;
                padding-left: 8px;
            }


            .edit-subscribe-card-container {
                padding: 0;

                .edit-subscribe-row {
                    @media (max-width: 1200px) {
                        flex-wrap: nowrap;
                        overflow-x: auto;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }

                .edit-subscribe-card-wrapper {
                    // margin-top: 20px;
                    margin-top: 85px;

                    &.disabled-wrapper {
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 12px;
                            height: calc(100% - 68px);
                            width: calc(100% - 24px);
                            background-color: rgba($color: #efefef, $alpha: 0.7);
                            z-index: 9999;
                            border-radius: 13px;
                        }
                    }

                    @media (max-width:767px) {

                        margin-top: 60px;

                    }

                    .edit-subscribe-card {
                        background-color: #efefef;
                        border-radius: 13px;
                        justify-content: center;
                        display: flex;
                        flex-direction: column;
                        // align-items: center;
                        padding: 20px 22px;
                        transition: all 0.5s;
                        position: relative;
                        text-align: center;

                        @media (max-width: 1200px) {
                            padding: 20px 15px;
                        }

                        .img-skeleton {
                            margin: 30px 0;
                        }

                        .edit {
                            color: #6F8BDF;
                            font-weight: 700;
                            margin-bottom: 15px;
                            cursor: pointer;
                        }

                        h3 {
                            font-weight: 600;
                            font-size: 24px;
                            font-family: 'Lato';
                            color: #2B292D;
                            margin-bottom: 0;

                            @media (max-width:1366px) {
                                font-size: 20px;
                            }
                        }

                        .book-img {
                            margin: 45px 0;
                            height: 100px;
                            width: fit-content;

                            @media (max-width: 1600px) and (max-height:1000px) {
                                margin: 15px 0;
                                height: 80px;
                            }

                            @media (max-width:1366px) {
                                margin: 30px 0;
                                height: 70px;
                            }
                        }

                        .price {
                            font-size: 30px;
                            font-family: 'Lato';
                            color: #5B5A78;

                            @media (max-width:1366px) {
                                font-size: 26px;
                            }

                            @media (max-width:1366px) and (max-height:630px) {
                                font-size: 24px;
                            }

                            @media (max-width:1366px) and (max-height:600px) {
                                font-size: 22px;
                            }

                            .month {
                                color: #6F8BDF;
                                font-size: 13px;
                            }
                        }

                        .list-wrapper {
                            margin-top: 30px;

                            ul {
                                list-style: none;
                                max-width: 100%;
                                margin: auto;
                                padding-left: 0px;


                                li {
                                    display: flex;
                                    margin: 5px 0;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 21px;
                                    margin-bottom: 12px;
                                    justify-content: center;
                                    text-align: left;

                                    @media (max-width:1366px) {
                                        font-size: 12px;
                                        line-height: 20px;
                                    }

                                    @media (max-width:1366px) and (max-height:600px) {
                                        font-size: 10px;
                                        line-height: 16px;
                                    }

                                    img {
                                        margin-right: 11px;
                                    }
                                }
                            }
                        }

                        .subscription-toggle {
                            border-top: 1px solid #6F8BDF;
                            padding-top: 17px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .switch-label {
                                max-height: 30px;

                                .react-switch-bg {
                                    height: 30px !important;
                                    width: 50px !important;
                                }

                                .react-switch-handle {
                                    height: 20px !important;
                                    width: 20px !important;
                                    top: 4px !important;
                                }
                            }

                            .label {
                                display: none;
                            }

                            .text {
                                font-weight: 700;
                                font-size: 15px;

                                @media (max-width: 1200px) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .edit-info-card {
                        border-radius: 13px;
                        justify-content: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 43px 22px 34px;
                        transition: all 0.5s;
                        background-color: #2B292D;
                        position: relative;
                        margin-top: -30px;

                        @media (max-width: 1366px) {
                            padding: 35px 18px 28px;
                        }

                        h3 {
                            font-weight: 600;
                            font-size: 24px;
                            font-family: 'Lato';
                            margin-bottom: 0;
                            text-align: center;
                            line-height: 32px;
                            color: #6F8BDF;

                            @media (max-width:1366px) {
                                font-size: 20px;
                            }
                        }

                        .active-subscription {
                            background-color: #F6B21A;
                            color: #2B292D;
                            width: 80%;
                            margin: auto;
                            border-radius: 16px 16px 0 0;
                            font-weight: 700;
                            position: absolute;
                            bottom: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            min-height: 24px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @media (max-width:1366px) {
                                font-size: 14px;
                            }

                            @media (max-width:1100px) {
                                font-size: 10px;
                            }
                        }

                        .edit-input-field-wrapper {
                            margin: 27px 0 20px;
                            width: 100%;

                            input {
                                margin-bottom: 20px;
                                padding-left: 45px;
                                padding-right: 10px;

                                @media (max-width: 1366px) {
                                    &::placeholder {
                                        font-size: 13px;
                                    }
                                }
                            }

                            label {
                                display: none;
                            }

                            .board-select-wrapper {
                                margin-bottom: 23px;
                            }

                            .ant-select-selection-item {
                                padding-left: 35px !important;

                                &::before {
                                    background-image: url('../../../assets/images/calendar.svg');
                                    height: 17px;
                                    width: 19px;
                                    left: 4px;
                                }
                            }
                        }

                        .button-wrapper {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;

                            .board-btn {
                                flex-basis: 48%;
                                width: 100%;
                            }

                            .transparent-btn {
                                border-color: #6F8BDF;
                                color: #6F8BDF;
                            }
                        }
                    }
                }
            }
        }
    }
}