.subscription-graph-bg {
    background-color: #efefef;
    padding: 20px;
    margin-top: 24px;
    border-radius: 16px;

    .graph-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #a2a2a2;
        padding-bottom: 10px;

        .title {
            font-size: 18px;
            font-weight: 700;
        }

        .duration {
            font-size: 14px;
            font-weight: 600;

            img {
                margin-left: 8px;
            }
        }
    }

    .admin-chart-bar-wrapper {
        margin: 30px auto;
        .rehcharts-bar {
            &.admin-chart-bar {
                width: 300px !important;
                max-width: 90% !important;
                max-height: 100% !important;

                .recharts-cartesian-axis-line {
                    display: none !important;
                }
            }
        }
    }
}