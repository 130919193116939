.content-section {
    &.admin-content-section {
        .admin-users-wrapper {
            .admin-users-container {
                .admin-users-row {
                    .table-wrapper {
                        .users-title-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 20px;

                            .title-wrapper {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                max-width: 220px;
                                width: 100%;

                                h3 {
                                    font-size: 24px;
                                    font-weight: 700;
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .board-btn{
                            min-width: 170px;
                        }
                    }
                }
            }
        }
    }
}