body {
    max-height: 100vh;
    font-family: 'Lato';
}


.input-field {
    position: relative;
}

input {

    font-family: 'Lato' !important;

    &::placeholder {
        font-family: 'Lato';
    }

    &:focus {
        box-shadow: unset !important;
        border-color: unset !important;
        outline: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    @media (max-width: 576px) {
        font-size: 11px !important;
    }
}

input[type=number] {
    -moz-appearance: textfield;
}

.opacity-1 {
    opacity: 1 !important;
    transition: all 0.2s;
}

.spinner-style {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.no-data {
    height: 100%;
    font-weight: 600;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.__PrivateStripeElement {
    top: 4px;
}

.checkbox-wrapper {
    .styled-checkbox {
        position: absolute;
        opacity: 0;

        &+label {
            position: relative;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
        }

        &+label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 24px;
            height: 24px;
            background: #a2a2a2;
            border-radius: 5px;
        }

        &:checked+label:before {
            background: #6F8BDF;
        }

        &:disabled+label {
            color: #b8b8b8;
            cursor: auto;
        }

        &:disabled+label:before {
            box-shadow: none;
            background: #ddd;
        }

        &:checked+label:after {
            content: '';
            position: absolute;
            left: 2px;
            top: 2px;
            width: 20px;
            height: 20px;
            background-image: url('../../assets/images/list-check-white.svg');
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
}

.toggle-select {
    display: flex;
    margin-bottom: 50px;
    padding-left: 15px;

    @media (max-width: 1366px) {
        margin-bottom: 30px;
    }

    .title {
        font-size: 16px;
        color: #A2A2A2;
        font-weight: 500;
        border-bottom: 4px solid #EFEFEF;
        max-width: 223px;
        width: 100%;
        text-align: center;
        font-family: 'Inter';
        transition: all 0.3s;
        cursor: pointer;
        padding-bottom: 8px;

        &.active {
            color: #6F8BDF;
            border-color: #6F8BDF;
        }

        @media (max-width: 1200px) {
            max-width: 200px;
        }

        @media (max-width: 768px) {
            font-size: 14px;
        }

        @media (max-width:576px) {
            font-size: 12px;
        }
    }
}

.disabled {
    pointer-events: none;
    background-color: unset;
    opacity: 0.5;

    img {
        opacity: 0.5;
    }
}

.disabled-btn {
    pointer-events: none;
    opacity: 0.7;
    user-select: none;
}

.error-message {
    display: block;
    color: red;
    font-size: 14px;
    margin: 6px 0;
    line-height: 16px;
    margin-bottom: 10px;
    opacity: 0;
    min-height: 16px;

    @media (max-width:1680px) {
        font-size: 12px;
        line-height: 14px;
        margin: 6px 0;
    }

    @media (max-width:1440px) {
        font-size: 11px;
        line-height: 13px;
    }

    @media (max-width: 1366px) {
        margin-top: 3px;
    }

    @media (max-width:1366px) and (max-height:630px) {
        font-size: 10px;
        margin-bottom: 6px;
    }

    @media(max-width: 576px) {
        font-size: 10px;
        margin: 4px 0 6px;
    }
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}