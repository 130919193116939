.content-section {
    &.member-profile-section {
        padding-left: 35px !important;

        @media (max-width: 1366px) {
            padding-left: 20px !important;
        }

        @media (max-width: 576px) {
            padding-left: 10px !important;
        }

        .home-card-container {
            padding-left: 0;

            .home-card-row {
                &.feature-card-row {
                    align-items: unset;
                }
            }
        }

        .home-card-wrapper {
            h3 {
                margin-bottom: 30px;
                font-size: 24px;
                font-weight: 700;
            }
        }

        .about-me-section {
            background-color: #EFEFEF;
            padding: 34px 30px;
            border-radius: 16px;
            margin-bottom: 50px;
            margin-right: 30px;

            @media (max-width: 1366px) {
                margin-right: 35px;
            }

            @media (max-width: 1280px) {
                margin-right: 20px;
            }

            @media (max-width: 767px) {
                margin-right: 15px;
                padding: 30px 0;
            }

            .about-me-container {
                color: #2B292D;

                .about-me-left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;

                    @media (max-width:768px) {
                        flex-direction: row;
                    }

                    .profile {
                        max-width: 180px;

                        @media (max-width:1366px) {
                            max-width: 140px;
                        }

                        img {
                            height: 180px;
                            width: 180px;
                            border-radius: 50%;
                            object-fit: cover;
                            border: 6px solid #6F8BDF;

                            @media (max-width:1366px) {
                                border-width: 3px;
                                height: 140px;
                                width: 140px;
                            }

                            @media (max-width:768px) {
                                border-width: 2px;
                                height: 120px;
                                width: 120px;
                            }

                            @media (max-width:576px) {
                                height: 100px;
                                width: 100px;
                            }

                        }

                        .name {
                            width: 100%;
                            text-align: center;
                            font-size: 26px;
                            margin-top: 24px;
                            font-weight: 700;
                            color: #2B292D;

                            @media (max-width:1366px) {
                                font-size: 20px;
                                margin-top: 15px;
                            }
                        }
                    }

                }

                .about-me-mid {
                    max-width: 85%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media (max-width:768px) {
                        margin-top: 20px;
                        max-width: 90%;
                    }

                    @media (max-width:576px) {
                        margin-top: 20px;
                        max-width: 100%;
                    }

                    h2 {
                        font-size: 24px;
                        font-weight: 600;

                        @media (max-width:1366px) {
                            font-size: 20px;
                        }
                    }

                    p {
                        line-height: 20px;
                        color: #5B5A78;
                        max-height: 150px;
                        overflow: auto;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        @media (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                }

                .about-me-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;

                    @media (max-width: 767px) {
                        text-align: center;
                        margin-top: 30px;
                    }

                    .image-wrapper {
                        text-align: right;

                        img {
                            width: 80%;

                            @media (max-width:767px) {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}