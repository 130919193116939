.content-section {
    &.finances-section {
        padding-right: 20px !important;
        .finances-wrapper {
            .finances-container {
                padding-right: 5px;

                
                @media (max-width: 1366px) {
                    padding-left: 5px;
                }
                @media (max-width: 1280px) {
                    padding-right: 0;
                }
                @media (max-width: 576px) {
                    padding-left: 10px;
                }

                .finances-card-row {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .finances-details-card-wrapper {
                        flex-basis: 20.5%;
                        width: 100%;

                        @media (max-width: 1200px) {
                            flex-basis: 23%;
                        }

                        @media (max-width: 1024px) {
                            flex-basis: 25%;
                        }

                        @media (max-width: 992px) {
                            flex-basis: 30%;
                        }

                        @media (max-width: 768px) {
                            flex-basis: 40%;
                        }

                        @media (max-width: 576px) {
                            flex-basis: 60%;
                        }

                        @media (max-width: 400px) {
                            flex-basis: 80%;
                        }

                        .finances-details-card {
                            background-color: #6F8BDF;
                            border-radius: 16px;
                            padding:24px;
                            color: #fff;
                            min-height: 270px;
                            height: 100%;
                            display: flex;
                            justify-content: space-between;
                            flex-direction: column;
                            @media (max-width: 1440px){
                                max-width: 250px;
                            }

                            @media (max-width: 1366px) {
                                padding: 20px 15px;
                            }

                            h3 {
                                font-size: 18px;
                                font-weight: 600;

                                @media (max-width: 1366px) {
                                    font-size: 16px;
                                }
                            }

                            .detail-items {
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;

                                @media (max-width: 1280px) {
                                    justify-content: space-between;
                                }

                                .item-wrapper {
                                    &:nth-of-type(2) {
                                        margin-left: 35px;

                                        @media (max-width: 1280px) {
                                            margin-left: 0;
                                        }
                                    }

                                    .item {
                                        flex-basis: 50%;
                                        margin-top: 20px;

                                        h4 {
                                            font-size: 14px;
                                            opacity: 0.8;
                                            margin-bottom: 0;
                                            font-weight: 600;
                                        }

                                        .number {
                                            font-size: 32px;
                                            font-weight: 700;
                                            max-width: 170px;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            cursor: pointer;

                                            @media (max-width: 1366px) {
                                                font-size: 28px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .toggle-select {
                    margin: 50px 0 0;
                    padding-left: 0;

                    .title {
                        max-width: 165px;
                    }
                }

                .subscribed-user-table-wrapper,
                .grt-table-wrapper {
                    h3 {
                        font-size: 24px;
                        font-weight: 700;
                        margin: 30px 0;
                        @media (max-width: 1366px){
                            font-size: 22px;
                            margin: 25px 0;
                        }
                    }
                }
            }
        }
    }
}