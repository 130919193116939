.sidebar {
    width: 312px !important;
    min-width: 312px !important;
    border-right: unset !important;
    // overflow: hidden;

    @media (max-width:1366px) {
        min-width: 250px !important;
        width: 250px !important;
    }

    &.ps-collapsed {
        width: 95px !important;
        min-width: 95px !important;

        @media (max-width: 1920px) and (max-height: 600px) {
            .logo-header {
                width: 95px !important;
            }
        }

        @media (max-width:1366px) {
            width: 80px !important;
            min-width: 80px !important;

            .logo-header {
                width: 80px !important;
            }
        }

        .bottom-menu {
            .css-vj11vy {
                .switch-anchor {
                    .css-1bdadyq {
                        transition: all 0.5s;
                        padding: 0 17px;

                        @media (max-width:1366px) {
                            padding: 0 10px;
                        }
                    }
                }
            }
        }

        .admin-sidebar-title {
            max-width: 56px;
            overflow: hidden;
            transition: all 0.3s;

            @media (max-width: 1366px) {
                max-width: 46px;
            }
        }
    }

    &.ps-toggled {
        width: 250px !important;
        left: 0 !important;
    }

    .ps-sidebar-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100vh;
        max-height: 100%;
        overflow: hidden;
        padding: 25px 0 50px 0;
        background-color: #EFEFEF;
        width: 100%;
        border-radius: 0 16px 16px 0;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width:1920px) and (max-height:600px) {
            overflow: auto;
            padding-top: 0;

            .bottom-menu {
                margin-top: 100px;
            }

            .logo-header {
                position: fixed;
                z-index: 99;
                background-color: #EFEFEF;
                width: 312px;
                padding: 25px 0;
                border-top-right-radius: 16px;
                transition: all 0.3s;
            }
        }

        @media (max-width: 1366px) {
            padding: 0 0 20px 0;
        }

        @media (max-width: 1366px) and (min-height: 900px) {
            padding-top: 20px;
        }
    }

    .top-menu,
    .bottom-menu {
        width: 100%;

        .css-vj11vy {
            .logo-header {
                padding-bottom: 30px;

                @media (max-width:1600px) and (max-height:900px) {
                    padding: 25px 0;
                }

                @media (max-width:1366px) {
                    width: 250px;
                }

                @media (max-width:1366px) and (max-height:620px) {
                    padding: 15px 0;
                }

                .css-1bdadyq {
                    padding: 0 30px;

                    &:hover {
                        background-color: unset;
                    }

                    @media (max-width:1366px) {
                        padding-right: 15px;
                        padding: 0 20px;
                    }

                    @media (max-width:992px) {
                        padding: 0 20px;
                    }
                }
            }
        }

        .logo-wrapper {
            display: flex;
            align-items: center;

            .text {
                margin-left: 20px;
                color: #2B292D;
                font-weight: 600;
                font-size: 24px;
                font-family: 'PT Serif';

                @media (max-width:1366px) {
                    font-size: 20px;
                    margin-left: 25px;
                }
            }

            img {
                @media (max-width: 992px) {
                    pointer-events: none;
                }
            }

            .toggle {
                margin-left: auto;
                cursor: pointer;

                img {
                    @media (max-width: 992px) {
                        display: none;
                    }
                }
            }
        }

        .menu-item {
            .icon-text {
                display: flex;
                align-items: center;

                .text {
                    margin-left: 25px;
                    font-family: 'Lato';
                    font-weight: 600;
                    color: #2B292D;

                    @media (max-width:1366px) {
                        margin-left: 20px;
                        font-size: 14px;
                    }
                }

                img{
                    min-width: 21px;
                    height: 21px;
                }

                .menu-icon-active {
                    opacity: 0;
                    display: none;
                }

                &.active {
                    .text {
                        color: #6F8BDF;
                    }

                    .menu-icon {
                        opacity: 0;
                        display: none;
                    }

                    .menu-icon-active {
                        opacity: 1;
                        display: block;
                    }
                }
            }

            .css-1bdadyq {
                padding-left: 35px;

                @media (max-width:1366px) {
                    height: 45px;
                    padding-left: 25px;
                }

            }
        }
    }

    .top-menu {
        .menu-item {
            @media (max-width:1920px) and (max-height:600px) {
                &:nth-of-type(2) {
                    margin-top: 100px;
                }
            }

            @media (max-width:1366px) and (max-height:550px) {
                &:nth-of-type(2) {
                    margin-top: 80px;
                }
            }
        }

        .admin-sidebar-title {
            font-weight: 700;
            font-size: 24px;
            font-family: "PT Serif";
            padding-left: 38px;

            @media (max-width: 1366px) {
                padding-left: 28px;
            }
        }
    }

    .bottom-menu {
        .css-vj11vy {
            .switch-anchor {
                .css-1bdadyq {
                    padding: 0 35px;
                    transition: all 0.5s;
                    cursor: pointer;

                    @media (max-width: 1366px) {
                        padding: 0 25px;
                    }

                    &:hover {
                        color: #2B292D;
                    }

                }

                .react-switch-bg {
                    width: 50px !important;
                    height: 26px !important;
                }

                .react-switch-handle {
                    height: 20px !important;
                    width: 20px !important;
                    transform: translateX(4px);
                    top: 3px !important;
                }
            }

            .switch-main {
                display: flex;
                align-items: center;
                gap: 10px;

                .switch-label {
                    display: flex;
                    align-items: center;

                    .label {
                        margin-left: 20px;
                        font-weight: 600;
                        font-family: 'Lato';

                        @media (max-width:1366px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}