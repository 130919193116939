.search-wrapper {
    position: relative;
    margin-top: 30px;

    @media (max-width: 1366px) {
        margin-top: 5px;
    }

    label {
        display: none !important;
    }

    .input-field {
        .left-icon {
            @media (max-width: 1366px) {
                top: 9px;
            }
        }
    }

    &.admin-search-wrapper {
        @media (max-width: 1366px) {
            margin: 0 0 20px !important;

        }

        .suggested-wrapper {
            background-color: #efefef;
            border: 1px solid #6F8BDF;
            border-radius: 16px;
            box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
            padding: 20px;
            max-width: 276px;
            max-height: 357px;
            overflow: auto;
            width: 100%;
            position: absolute;
            left: calc(100% + 20px);
            bottom: calc(100% - 55px);
            scrollbar-width: none;

            @media (max-width: 1366px) {
                bottom: calc(100% - 45px);
                max-width: 210px;
                max-height: 280px;
                padding: 15px;
            }

            @media (max-width: 1100px) {
                bottom: 100%;
                right: 0;
                left: unset;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .suggested-item {
                max-height: 357px;
                overflow-y: auto;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                @media (max-width: 1366px) {
                    max-height: 280px;
                }

                .item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    cursor: pointer;

                    @media (max-width: 1366px) {
                        margin-bottom: 12px;
                    }

                    img {
                        border-radius: 50%;

                        @media (max-width: 1366px) {
                            height: 26px;
                            width: 26px;
                        }
                    }

                    .avatar-img {
                        margin-right: 16px;

                        @media (max-width: 1366px) {
                            margin-right: 10px;
                        }
                    }

                    .avatar-name {
                        font-size: 14px;
                        max-width: 120px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        @media (max-width: 1366px) {
                            font-size: 13px;
                        }
                    }
                }

            }
        }
    }

}