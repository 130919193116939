.modal-backdrop {
    &.show {
        z-index: 9 !important;
    }
}

.push-group-chat-modal {
    z-index: 9 !important;

    .modal-dialog {
        width: 726px;
        max-width: 100%;

        @media (max-width: 768px) {
            margin: 15px auto;
            max-width: 90%;
        }

        @media (min-width:1920px) {
            width: 1440px;
        }

    }

    .modal-content {
        border-radius: 16px !important;
    }

    .group-chat-header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        border: unset;
        padding-top: 53px;
        padding-bottom: 0;

        @media (max-width: 1600px) and (max-height:1000px) {
            padding-top: 30px;
        }

        @media (max-width:1366px) {
            padding-top: 25px;
        }

        @media (max-width:1366px) and (max-height:600px) {
            padding-top: 20px;
        }

        .top-img {
            margin-bottom: 35px;

            @media (max-width: 1440px) and (max-height: 900px) {
                margin-bottom: 15px;

                img {
                    height: 120px;
                }
            }

            @media (max-width: 1366px) {
                margin-bottom: 10px;

                img {
                    height: 100px;
                }
            }
        }

        .head-title {
            font-family: 'PT Serif';
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 50px;
            text-align: center;

            @media (max-width: 1600px) and (max-height:1000px) {
                margin-bottom: 40px;
                font-size: 28px;
            }

            @media (max-width:1440px) and (max-height:900px) {
                margin-bottom: 30px;
            }


            @media (max-width:1366px) {
                margin-bottom: 10px;
                font-size: 24px;
            }

            @media (max-width:1366px) and (max-height:600px) {
                font-size: 26px;
            }

            @media (max-width: 1280px) {
                margin-bottom: 10px;
                font-size: 24px;
            }

            @media (max-width: 768px) {
                font-size: 20px;
            }
        }

        .close-icon {
            position: absolute;
            top: 22px;
            left: calc(100% + 25px);
            cursor: pointer;
            z-index: 9;

            @media(max-width:1366px) {
                top: 8px;
            }

            @media (max-width: 768px) {
                top: 0;
                right: 10px;

                img {
                    height: 16px;
                    width: 16px;
                }
            }

            @media (max-width:576px) {
                right: 8px;
            }
        }
    }

    .modal-body {
        padding: 0 30px 50px;

        @media (max-width: 1366px) {
            padding-bottom: 25px;
        }

        @media (max-width:768px) {
            padding: 0 10px 20px;
        }

        .modal-body-details-wrapper {

            max-width: 560px;
            margin: auto;

            @media (min-width: 1920px) {
                max-width: 1100px;
            }

            .new-chat-section,
            .group-chat-section {
                .input-field {
                    label {
                        min-height: 20px;
                        margin: 8px 0;
                    }
                }

                .selected,
                .suggested-wrapper {
                    margin-top: 15px;
                    display: flex;

                    .avatar-img {
                        height: 32px;
                        width: 32px;

                        @media (max-width: 1366px) {
                            height: 26px;
                            width: 26px;
                        }

                        &:not(:first-of-type) {
                            margin-left: 10px;
                        }

                        &.selected-avatar {
                            position: relative;

                            @media (max-width: 1366px) {
                                height: 26px;
                                width: 26px;
                            }

                            &::before {
                                position: absolute;
                                content: '';
                                background-image: url('../../../../../assets/images/list-check.svg');
                                background-position: center;
                                background-size: 80% 80%;
                                background-repeat: no-repeat;
                                background-color: #2B292D;
                                opacity: 0.8;
                                height: 100%;
                                width: 100%;
                                border-radius: 50%;
                                top: 0;
                                left: 0;
                                z-index: 1;
                            }
                        }

                        img {
                            height: 100%;
                            width: 100%;
                            border-radius: 50%;
                        }
                    }
                }

                .selected-user-wrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    opacity: 0;
                    min-height: 47px;

                    @media (max-width: 1366px) {
                        min-height: 41px;
                    }

                    .avatar-img {
                        margin: 0 9px;

                        &:first-of-type {
                            margin-left: 0;
                        }
                    }

                    &.opacity-1 {
                        opacity: 1;
                    }

                    .selected {
                        @media (max-width: 1366px) {
                            margin-top: 0;
                        }
                    }
                }

                .suggested-wrapper {
                    background-color: #efefef;
                    border: 1px solid #6F8BDF;
                    border-radius: 16px;
                    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
                    padding: 20px;
                    max-width: 276px;
                    width: 100%;
                    position: absolute;
                    left: calc(100% + 20px);
                    bottom: calc(100% - 55px);

                    @media (max-width: 1366px) {
                        bottom: calc(100% - 45px);
                        max-width: 210px;
                        max-height: 280px;
                        padding: 15px;
                    }

                    @media (max-width: 1100px) {
                        bottom: 100%;
                        right: 0;
                        left: unset;
                    }


                    .suggested-item {
                        max-height: 357px;
                        overflow-y: auto;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        @media (max-width: 1366px) {
                            max-height: 280px;
                        }

                        .item {
                            display: flex;
                            align-items: center;
                            margin-bottom: 16px;
                            cursor: pointer;

                            @media (max-width: 1366px) {
                                margin-bottom: 12px;
                            }

                            img {
                                border-radius: 50%;

                                @media (max-width: 1366px) {
                                    height: 26px;
                                    width: 26px;
                                }
                            }

                            .avatar-img {
                                margin-right: 16px;

                                @media (max-width: 1366px) {
                                    margin-right: 10px;
                                }
                            }

                            .avatar-name {
                                font-size: 14px;
                                max-width: 120px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                                @media (max-width: 1366px) {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }

                .search-wrapper {
                    .input-field {
                        input {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .new-chat-section {
                .input-field {
                    input {
                        margin-bottom: 50px;
                    }

                    label {
                        display: none;
                    }
                }
            }

            .group-chat-section {

                .group-chat-header {
                    @media (max-width: 1366px) {
                        padding-top: 35px;
                    }
                }

                label {
                    display: none;
                }

                input {
                    margin-bottom: 30px;

                    @media (max-width: 1366px) {
                        margin-bottom: 20px;
                    }
                }

                .button-wrapper {
                    display: flex;
                    justify-content: center;
                    margin-top: 15px;
                    margin-bottom: 40px;

                    .board-btn {
                        min-width: 265px;

                        &.board-btn {
                            margin-left: 30px;
                        }

                        &.transparent-btn {
                            margin-left: 0px;
                        }

                        @media (max-width: 1024px) {
                            min-width: 215px;
                        }

                        @media (max-width: 768px) {
                            min-width: 185px;
                        }

                        @media (max-width: 576px) {
                            min-width: unset;
                            flex-basis: 48%;
                            font-size: 12px;

                            &.transparent-btn {
                                margin-left: 10px;
                            }
                        }

                        @media (max-width: 350px) {
                            font-size: 10px;
                        }
                    }

                    &.chat-button {
                        margin-top: 30px;
                    }
                }

            }
        }

    }
}