.modal-backdrop {
    &.show {
        z-index: 9 !important;
    }
}

.join-club-modal {
    z-index: 9 !important;

    .modal-dialog {
        width: 1280px;
        max-width: 100%;

        @media (max-width:1366px) {
            max-width: 80%;
        }

        @media (max-width: 576px) {
            margin: 15px auto;
            max-width: 90%;
        }

        @media (min-width:1920px) {
            width: 1440px;
        }

    }

    .modal-content {
        border-radius: 16px !important;
    }

    .modal-header {
        justify-content: center;
        position: relative;
        border: unset;
        padding-top: 53px;

        @media (max-width: 1600px) and (max-height:1000px) {
            padding-top: 30px;
        }

        @media (max-width:1366px) {
            padding-top: 25px;
        }

        @media (max-width:1366px) and (max-height:600px) {
            padding-top: 20px;
        }

        .modal-title {
            .head-title {
                font-family: 'PT Serif';
                font-size: 32px;
                font-weight: 700;
                margin: auto;
                text-align: center;
                margin-bottom: 60px;
                max-width: 80%;


                @media (max-width:1600px) and (max-height:800px) {
                    margin-bottom: 30px;
                }

                @media (max-width: 1600px) and (max-height:1000px) {
                    margin-bottom: 40px;
                    font-size: 28px;
                }

                @media (max-width:1366px) {
                    margin-bottom: 20px;
                    font-size: 28px;
                }

                @media (max-width:1366px) and (max-height:600px) {
                    font-size: 26px;
                }

                @media (max-width: 1280px) {
                    margin-bottom: 10px;
                    font-size: 24px;
                }

                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }
        }

        .close-icon {
            position: absolute;
            top: 18px;
            right: 18px;
            cursor: pointer;
            z-index: 9;

            @media(max-width:1366px) {
                top: 8px;
            }

            @media (max-width: 768px) {
                top: 0;
                right: 10px;

                img {
                    height: 16px;
                    width: 16px;
                }
            }

            @media (max-width:576px) {
                right: 8px;
            }
        }
    }

    .modal-body {
        padding: 0 30px;

        @media (max-width:768px) {
            padding: 0 10px;
        }

        .price-card-section {
            .price-card-wrapper {
                .price-card {
                    background-color: #efefef;
                    border-radius: 13px;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 40px 0;
                    transition: all 0.5s;
                    cursor: pointer;

                    @media (max-width: 1600px) and (max-height: 1000px) {
                        padding: 30px 0;
                    }

                    @media (max-width:1366px) {
                        padding: 25px 0;
                    }

                    @media (max-width: 1280px) {
                        padding: 15px 0;
                    }

                    @media (max-width: 1024px) {
                        margin-bottom: 15px;
                    }

                    @media (max-width: 576px) {
                        max-width: 200px;
                        margin: 0 auto 15px;
                    }

                    &:hover {
                        background-color: #2B292D;
                        transform: scaleY(1.1);

                        @media (max-width:1366px) and (max-height:600px) {
                            transform: scaleY(1.08);
                        }

                        h3 {
                            color: #6F8BDF;
                        }

                        .price {
                            color: #efefef;
                        }

                        li {
                            color: #efefef;
                        }

                        .transparent-btn {
                            background-color: #6F8BDF;
                            color: #fff;
                        }
                    }

                    .active-subscription {
                        background-color: #F6B21A;
                        color: #2B292D;
                        width: 80%;
                        margin: auto;
                        padding: 6px;
                        text-align: center;
                        border-radius: 6px 6px 0 0;
                        font-weight: 700;
                        position: absolute;
                        bottom: 100%;
                        @media (max-width:1366px){
                            font-size: 14px;
                        }
                        @media (max-width:1100px) {
                           
                            font-size: 10px;
                            
                        }
                        
                    }

                    h3 {
                        font-weight: 600;
                        font-size: 24px;
                        font-family: 'Lato';
                        color: #2B292D;
                        margin-bottom: 0;

                        @media (max-width:1366px) {
                            font-size: 20px;
                        }

                        @media (max-width:1280px) {
                            font-size: 18px;
                        }
                    }

                    .book-img {
                        margin: 45px 0;

                        @media (max-width: 1600px) and (max-height:1000px) {
                            margin: 15px 0;
                            height: 80px;
                        }

                        @media (max-width:1366px) {
                            margin: 20px 0;
                            height: 60px;
                        }
                    }

                    .price {
                        font-size: 30px;
                        font-family: 'Lato';
                        color: #5B5A78;

                        @media (max-width:1366px) {
                            font-size: 26px;
                        }

                        @media (max-width:1366px) and (max-height:630px) {
                            font-size: 24px;
                        }

                        @media (max-width:1366px) and (max-height:600px) {
                            font-size: 22px;
                        }

                        .month {
                            color: #6F8BDF;
                            font-size: 13px;
                        }
                    }

                    .button-wrapper {
                        .board-btn {
                            margin: 24px 0;
                            font-size: 14px;
                            min-width: 185px;

                            @media (max-width:1600px) and (max-height:1000px) {
                                padding: 6px 16px;
                                line-height: 21px;
                                margin: 16px 0;
                            }

                            @media (max-width:1366px) {
                                margin: 12px 0;
                                font-size: 12px;
                                min-width: 165px;
                                padding: 6px 12px;
                                line-height: 18px;
                            }

                            @media (max-width: 1280px) {
                                min-width: 130px;
                                min-height: 30px;
                                padding: 4px 0;
                                border-radius: 8px;
                            }
                        }
                    }

                    .list-wrapper {
                        ul {
                            list-style: none;
                            max-width: 70%;
                            margin: auto;
                            padding-left: 10px;

                            @media (max-width:1366px) {
                                max-width: 80%;
                            }

                            li {
                                display: flex;
                                margin: 5px 0;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 21px;

                                @media (max-width:1366px) {
                                    font-size: 12px;
                                    line-height: 18px;
                                }

                                @media (max-width:1366px) and (max-height:600px) {
                                    font-size: 10px;
                                    line-height: 14px;
                                }

                                @media (max-width: 1024px) {
                                    font-size: 11px;
                                }

                                .blue-text {
                                    color: #6F8BDF;
                                    margin-right: 2px;
                                }

                                img {
                                    margin-right: 11px;
                                }
                            }
                        }
                    }
                }
                &.subscribed-card {
                    .price-card {
                        background-color: #2B292D;
                        transform: scaleY(1.1);

                        @media (max-width:1366px) and (max-height:600px) {
                            transform: scaleY(1.08);
                        }

                        h3 {
                            color: #6F8BDF;
                        }

                        .price {
                            color: #efefef;
                        }

                        li {
                            color: #efefef;
                        }

                        .transparent-btn {
                            background-color: #6F8BDF;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .modal-footer {
        border: unset;
        padding-bottom: 53px;
        margin-top: 40px;
        justify-content: center;

        @media (max-height:1000px) {
            padding-bottom: 30px;
        }

        @media (max-width:1366px) {
            margin-top: 20px;
            padding-bottom: 25px;
        }

        @media (min-width:1920px) {
            margin-top: 60px;
        }

        .steps-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .step {
                background: #A2A2A2;
                height: 4px;
                border-radius: 4px;
                width: 40px;

                &.active {
                    background: #6F8BDF;
                }

                &:nth-of-type(2) {
                    margin: 0 20px;
                }
            }
        }
    }

    &.personal-info-modal {
        .modal-dialog {
            width: 726px;
            max-width: 100%;

            @media (max-width: 768px) {
                max-width: 90%;
            }

            @media (min-width:1600px) {
                width: 900px;
            }

            @media (min-width:1680px) {
                width: 950px;
            }
        }

        .modal-header {
            @media (max-width: 1920px) and (max-height: 900px) {
                padding-top: 30px;
            }

            @media (max-width: 1600px) and (max-height: 850px) {
                padding-top: 20px;
            }

            @media (max-width: 1366px) {
                padding-top: 15px;
            }

            @media (max-width: 1366px) and (max-height: 630px) {
                padding-top: 10px;
            }

            .head-title {
                display: none;
            }
        }

        .modal-body {
            @media (max-width: 1600px) {
                padding: 0;
            }
        }

        .personal-info-section {
            .personal-info-header {
                text-align: center;

                img {
                    height: 130px;

                    @media (max-width: 1920px) and (max-height: 900px) {
                        height: 120px;
                    }

                    @media (max-width: 1600px) and (max-height: 850px) {
                        height: 90px;
                    }

                    @media (max-width: 1366px) and (max-height: 630px) {
                        height: 70px;
                    }

                    @media (max-width: 1280px) {
                        height: 60px;
                    }
                }

                h3 {
                    font-size: 24px;
                    font-family: 'PT Serif';
                    font-weight: 700;
                    font-size: 32px;
                    color: #2B292D;
                    margin: 15px 0;

                    @media (max-width: 1920px) and (max-height: 900px) {
                        margin: 20px 0;
                    }

                    @media (max-width: 1680px) and (max-height: 950px) {
                        font-size: 26px;
                        margin: 10px 0;
                    }

                    @media (max-width:1600px) and (max-height: 730px) {
                        font-size: 24px;
                    }

                    @media (max-width:1366px) {
                        font-size: 24px;
                        margin: 10px 0;
                    }

                    @media (max-width: 1366px) and (max-height: 630px) {
                        font-size: 20px;
                        margin-top: 5px;
                    }

                    @media (max-width: 768px) {
                        margin: 15px 0;
                    }
                    &.payment-head{
                        margin: 40px 0 60px;
                        @media (max-width: 1366px){
                            margin: 20px 0 40px;
                        }
                    }
                }
            }

            .personal-info-input-wrapper {
                padding: 0 53px;

                @media (max-width: 768px) {
                    padding: 0 40px;
                }

                @media (max-width: 576px) {
                    padding: 0 20px;
                }

                .phone-input-wrapper {
                    width: 100%;
                }

                .input-field {
                    input {
                        &::placeholder {
                            font-weight: 500;
                        }
                    }
                }

                .stripe-form {
                    .__PrivateStripeElement {
                        top: 4px;

                        @media (max-width: 1366px) {
                            top: 0;
                        }

                        @media (max-width: 576px) {
                            top: 4px;
                        }
                    }

                    .form-group {
                        position: relative;

                        .right-icon,
                        .left-icon {
                            position: absolute;
                            top: 8px;
                            right: 19px;
                            cursor: pointer;
                            margin-top: -1px;

                            @media (max-width:1366px) {
                                top: 8px;

                                img {
                                    height: 14px;
                                }
                            }

                            @media (max-width:576px) {
                                top: 7px;
                            }
                        }

                        .left-icon {
                            left: 7px;
                            right: unset;
                            cursor: unset;
                        }
                    }

                    .form-control {
                        margin-bottom: 30px;
                        background-color: #efefef;
                        border-radius: 8px;
                        height: 52px;
                        max-height: 100%;
                        border: unset;
                        padding: 12px 52px;
                        font-family: 'Lato';

                        @media (max-width: 1600px) and (max-height: 800px) {
                            height: 46px;
                        }

                        @media (max-width:1366px) {
                            font-size: 14px;
                            height: 40px;
                        }

                        @media (max-width: 576px) {
                            height: 35px;
                            font-size: 11px;
                            padding: 6px 45px;
                        }

                        &.StripeElement--empty {
                            // padding-top: 16px;
                            padding-bottom: 0;

                            @media (max-width:1366px) {
                                padding: 12px 52px;
                                // padding-top: 9px;
                            }

                            @media(max-width: 576px) {
                                padding: 6px 45px;
                            }
                        }

                        &::placeholder {
                            color: #A2A2A2;
                            font-weight: 500;
                        }

                        &:focus {
                            outline: none;
                            box-shadow: unset !important;
                            background-color: #efefef;
                        }
                    }

                    .input-row {
                        display: flex;
                        justify-content: space-between;

                        @media (max-width: 768px) {
                            flex-direction: column;
                        }

                        .stripe-input-wrapper {
                            position: relative;

                            .right-icon,
                            .left-icon {
                                position: absolute;
                                top: 7px;
                                right: 19px;
                                cursor: pointer;
                                margin-top: -1px;

                                @media (max-width:1366px) {
                                    top: 8px;

                                    img {
                                        height: 14px;
                                    }
                                }

                                @media (max-width: 576px) {
                                    top: 6px;
                                }
                            }

                            .left-icon {
                                left: 7px;
                                right: unset;
                                cursor: unset;

                                &.cvv-icon {
                                    height: 10px;
                                    top: 14px;

                                    @media (max-width:1366px) {
                                        height: 8px !important;
                                        top: 10px;
                                    }

                                    @media(max-width: 576px) {
                                        top: 10px !important;
                                    }
                                }
                            }


                        }

                        .form-control {
                            width: 300px;
                            max-width: 100%;
                            color: #A2A2A2 !important;

                            @media (min-width: 1600px) {
                                width: 350px;
                            }

                            @media (min-width: 1680px) {
                                width: 380px;
                            }

                            @media (max-width: 768px) {
                                width: unset;
                            }

                            input {
                                &::placeholder {
                                    color: #A2A2A2;
                                    font-weight: 600;
                                }
                            }
                        }

                        .left-icon {

                            @media (max-width:1366px) {
                                top: 8px;
                            }
                        }
                    }
                    .radio-wrapper{
                        margin-bottom: 20px;
                        .ant-radio-group{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            .ant-radio-wrapper{
                                &:nth-of-type(2){
                                    margin: 0 80px;
                                }
                                span{
                                    font-family: 'Lato' !important;
                                    &:not(:first-of-type){
                                        font-size: 18px;
                                        font-weight: 600;
                                        display: flex;
                                        margin-left: 30px;
                                    }
                                }
                                .ant-radio-inner{
                                    background-color: #EFEFEF !important;
                                    height: 24px;
                                    width: 24px;
                                    border: unset !important;
                                    &::after{
                                        background-color: #6F8BDF !important;
                                        transform: scale(0.5);
                                    }
                                }
                            }
                        }
                    }
                }


                .radio-row {
                    display: flex;
                    justify-content: space-between;

                    .input-radio {
                        width: 300px;
                        max-width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 0 40px;
                        &:first-of-type{
                            margin-left: 50px;
                            @media (max-width: 576px){
                                margin-left: 0;
                                justify-content: flex-start;
                            }
                        }
                        &:not(:first-of-type){
                            justify-content: flex-start;
                            margin-left: 30px;
                        }

                        label{
                            margin-bottom: 0;
                            font-size: 18px;
                            font-weight: 600;
                            cursor: pointer;
                            @media (max-width: 576px){
                                font-size: 16px;
                            }
                        }

                        .radio-container {
                            position: relative;
                            cursor: pointer;

                            input {
                                opacity: 0;
                                cursor: pointer;

                                &:checked {
                                    ~ {
                                        .checkmark {
                                            background-color: #EFEFEF;

                                            &:after {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }

                            .input {
                                position: absolute;
                                top: 50%;
                                left: calc(100% + 40px);
                                height: 24px;
                                width: 24px;
                                border-radius: 50%;
                                transform: translateY(-50%);
                                z-index: 5;
                                @media (max-width: 576px){
                                    height: 20px;
                                    width: 20px;
                                    left: calc(100% + 30px)
                                }
                            }

                            .checkmark {
                                &:after {
                                    top: 7px;
                                    left: 7px;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50%;
                                    background: #6F8BDF;
                                    @media (max-width: 576px){
                                        top: 6px;
                                        left: 6px;
                                        height: 8px;
                                        width: 8px;
                                    }
                                }
                            }
                        }

                        .checkmark {
                            position: absolute;
                            top: 50%;
                            left: calc(100% + 40px);
                            height: 24px;
                            width: 24px;
                            background-color: #EFEFEF;
                            border-radius: 50%;
                            transform: translateY(-50%);
                            @media (max-width: 576px){
                                height: 20px;
                                width: 20px;
                                left: calc(100% + 30px)
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                        }
                    }
                }
            }

            .button-wrapper {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 10px;

                .board-btn {
                    font-weight: 500;
                    font-size: 16px;
                    min-width: 281px;
                    margin: 10px 0;

                    @media (max-width:1600px) and (max-height:850px) {
                        margin-top: 0;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    @media (max-width:1366px) {
                        margin: 0;
                        font-size: 14px;
                        min-width: 250px;
                    }

                    @media (max-width: 1366px) and (max-height:630px) {
                        font-size: 12px;
                        line-height: 16px;
                    }

                    @media (max-width:576px) {
                        min-width: 200px;
                    }
                }
            }
        }

        .modal-footer {
            margin-top: 15px;

            @media (max-width: 1600px) and (max-height: 850px) {
                margin-top: 10px;
                padding-bottom: 20px;
                padding-top: 0;
            }

            @media (min-width: 1921px) {
                margin-top: 30px;
            }
        }
    }
}

.InputContainer[data-max]{
    @media (max-width: 576px){
        font-size: 11px !important;
    }
}