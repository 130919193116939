@font-face {
    font-family: 'Calibri';
    src: url('Calibri.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TimesNewRoman';
    src: url('Times-New-Roman.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('montserrat-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('rubik-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('raleway-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Georgia';
    src: url('georgia-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('Helvetica.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lucida';
    src: url('Lucida-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Bold.woff2') format('woff2'),
        url('PTSerif-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-BoldItalic.woff2') format('woff2'),
        url('PTSerif-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Italic.woff2') format('woff2'),
        url('PTSerif-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Bold.woff2') format('woff2'),
        url('PTSerif-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Italic.woff2') format('woff2'),
        url('PTSerif-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Regular.woff2') format('woff2'),
        url('PTSerif-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Regular.woff2') format('woff2'),
        url('PTSerif-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Regular.woff2') format('woff2'),
        url('PTSerif-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.woff2') format('woff2'),
        url('Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Light.woff2') format('woff2'),
        url('Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Medium.woff2') format('woff2'),
        url('Lato-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.woff2') format('woff2'),
        url('Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Semibold.woff2') format('woff2'),
        url('Lato-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Thin.woff2') format('woff2'),
        url('Lato-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Regular'), url('Inter-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Inter Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Italic'), url('Inter-Italic.woff') format('woff');
}


@font-face {
    font-family: 'Inter Thin BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Thin BETA'), url('Inter-Thin-BETA.woff') format('woff');
}


@font-face {
    font-family: 'Inter Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Medium'), url('Inter-Medium.woff') format('woff');
}


@font-face {
    font-family: 'Inter Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Semi Bold'), url('Inter-SemiBold.woff') format('woff');
}


@font-face {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Bold'), url('Inter-Bold.woff') format('woff');
}


@font-face {
    font-family: 'Inter Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Bold'), url('Inter-ExtraBold.woff') format('woff');
}



@font-face {
    font-family: 'Inter Black';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Black'), url('Inter-Black.woff') format('woff');
}