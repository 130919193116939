.slider-card {
    background-color: #A2A2A2;
    height: 208px;
    min-height: 100%;
    // flex-basis: 14.28%;
    width: 100%;
    max-width: 141px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    position: relative;
    z-index: 9;
    cursor: pointer;
    overflow: hidden;

    img {
        height: 100%;
        border-radius: 16px;
        width: 100%;
        opacity: 1;
        object-fit: cover;
    }

    .hover-data {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #6F8BDF, $alpha: 0.8);
        border-radius: 16px;
        transition: all 0.2s;
        bottom: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        p{
            color: #2B292D;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            line-height: 20px;
        }
    }
    &:hover{
        .hover-data{
            bottom: 0;
        }
    }
}