.input-field,
.phone-input-wrapper {
    position: relative;

    label {
        display: none;

        &.error-message {
            display: block;
            color: red;
            font-size: 14px;
            margin: 6px 0;
            line-height: 16px;
            margin-bottom: 10px;
            opacity: 0;
            min-height: 16px;

            @media (max-width:1680px) {
                font-size: 12px;
                line-height: 14px;
                margin: 6px 0;
            }

            @media (max-width:1440px) {
                font-size: 11px;
                line-height: 13px;
            }

            @media (max-width: 1366px) {
                margin-top: 3px;
                min-height: 14px;
            }

            @media (max-width:1366px) and (max-height:630px) {
                font-size: 10px;
                margin-bottom: 6px;
            }

            @media(max-width: 576px) {
                font-size: 10px;
                margin: 4px 0 6px;
            }
        }
    }

    input {
        background-color: #efefef;
        border-radius: 8px;
        height: 52px;
        max-height: 100%;
        border: unset;
        padding: 6px 52px 6px 52px;
        font-family: 'Lato';

        @media (max-width: 1600px) and (max-height: 800px) {
            height: 46px;
        }

        @media (max-width:1366px) {
            font-size: 14px;
            height: 40px;
        }

        @media(max-width: 576px) {
            font-size: 11px;
            height: 35px;
            padding: 6px 40px;
        }

        &::placeholder {
            font-family: 'Lato' !important;
            color: #A2A2A2;
            font-weight: 600;
            font-size: 14px;
            @media(max-width: 576px) {
                font-size: 12px;
            }
        }

        &:focus {
            outline: none;
            box-shadow: unset !important;
            background-color: #efefef;
        }
    }

    .right-icon,
    .left-icon {
        position: absolute;
        top: 14px;
        right: 19px;
        cursor: pointer;
        margin-top: -1px;

        @media (max-width:1366px) {
            top: 7px;

            img {
                height: 14px;
            }
        }

        @media(max-width: 576px) {
            top: 5px;

            img {
                height: 10px;
            }
        }
    }

    .left-icon {
        left: 15px;
        right: unset;
        cursor: unset;
    }

    .cvv-icon {
        height: 10px;

        @media (max-width:1366px) {
            height: 8px !important;
        }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        left: 13px;
        position: absolute;
        background-image: url('../../assets/images/calendar.svg');
    }
}