.content-section {
    &.account-section {
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .side-img {
            text-align: right;
            padding-right: 55px;
            img {
                @media (max-height: 950px) {
                    height: 350px;
                }
                @media (max-width:576px) {
                    height: 200px;
                }
            }
        }
        .account-details-section {
            .bank-cards {
                flex-wrap: wrap;
            }
            .account-wrapper {
                .bank-heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .add {
                        margin-right: 20px;
                    }
                    @media (max-width: 576px) {
                        flex-direction: column;
                        align-items: unset;
                        .add{
                            max-width: 40px;
                            margin-left: 0; 
                            align-self: flex-end;
                            margin-right: 10px;
                        }
                    }
                }
                h3 {
                    font-size: 24px;
                    font-weight: 700;
                    @media (max-width: 1366px) {
                        font-size: 22px;
                    }
                    @media (max-width: 576px) {
                        font-size: 18px;
                    }
                }
                .bank-cards-wrapper {
                    margin-top: 30px;
                    flex-wrap: nowrap;
                    .bank-card-wrapper {
                        flex-basis: 28%;
                        padding: 0 15px;
                        @media (max-width: 1024px){
                            flex-basis: 35%;
                            padding: 0 10px;
                        }
                        @media (max-width: 768px){
                            flex-basis: 40%;
                        }
                        @media (max-width: 576px){
                            flex-basis: 50%;
                        }
                        @media (max-width: 450px){
                            flex-basis: 70%;
                        }
                        .bank-card {
                            background-color: #A2A2A2;
                            padding: 24px;
                            border-radius: 16px;
                            color: #fff;
                            @media (max-width: 1024px){
                                padding: 16px;
                                border-radius: 12px;
                            }
                            @media (max-width: 576px){
                                padding: 10px;
                                border-radius: 8px;
                            }
                            h4 {
                                font-size: 20px;
                                @media (max-width: 1024px){
                                    font-size: 18px;
                                }
                                @media (max-width: 576px){
                                    font-size: 16px;
                                    margin-bottom: 6px;
                                }
                            }
                            p {
                                margin-bottom: 0;
                                font-size: 14px;
                                font-weight: 600;
                                @media (max-width: 768px){
                                    font-size: 12px;
                                }
                            }
                            &.blue-bg {
                                background-color: #6F8BDF;
                            }
                        }
                        .button-wrapper {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 21px;
                            @media (max-width: 1200px) {
                                margin-bottom: 20px;
                                min-width: 150px;
                                
                            }
                            @media (max-width: 1024px){
                                flex-direction: column;
                            }
                            .board-btn {
                                min-width: 235px;
                                @media (max-width: 1024px) {
                                    min-width: 50%;
                                    img{
                                        height: 14px;
                                        width: 14px;
                                    }
                                }
                                &.black-btn{
                                    margin-right: 10px;
                                    min-width: 150px;
                                    @media (max-width: 1024px){
                                        margin-right: 0;
                                        margin-bottom: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}