.application-card-wrapper {
  .application-card-details {
    background-color: #efefef;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 30px;
    @media (max-width: 1366px) {
      padding: 20px;
    }
    @media (max-width: 1200px) {
      padding: 16px;
    }

    .application-card-head {
      display: flex;
      justify-content: space-between;

      .writer-details {
        .info {
          display: flex;
          @media (max-width: 1200px){
            flex-direction: column;
          }

          p {
            color: #6F8BDF;
            font-family: 'Inter';
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 0;
            @media (max-width: 1366px) {
              font-size: 14px;
            }
            @media (max-width: 992px) {
              font-size: 13px;
            }

            &:nth-of-type(2) {
              margin-left: 20px;
              position: relative;
              max-width: 160px;
              // overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;

              &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: -13px;
                transform: translateY(-50%);
                height: 4px;
                width: 4px;
                border-radius: 50%;
                background-color: #6F8BDF;
              }

              @media (max-width: 1366px){
                max-width: 150px;
              }

              @media (max-width: 1200px){
                margin-left: 0;
                &::before{
                  display: none;
                }
                max-width: 200px;
              }
              @media (max-width: 1100px){
                max-width: 170px;
              }
              @media (max-width: 800px){
                max-width: 160px;
              }
              @media (max-width: 768px){
                max-width: 300px;
              }
              @media (max-width: 576px){
                max-width: 200px;
              }
              @media (max-width: 400px){
                max-width: 130px;
              }
              @media (min-width: 1600px){
                max-width: 250px;
              }
            }
          }
        }

        h3 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 0;
          color: #2B292D;
          margin-top: 10px;
          @media (max-width: 1366px) {
            margin-top: 8px;
            font-size: 22px;
          }
          @media (max-width: 1200px) {
            font-size: 20px;
          }
        }
      }

      .button-wrapper {
        .transparent-btn {
          min-width: 206px;
          @media (max-width: 1280px) {
            min-width: 160px;
          }
          @media (max-width: 1200px) {
            font-size: 14px;
            min-width: 135px;
            img {
              height: 18px;
              width: 18px;
            }
          }
          @media (max-width: 400px) {
            font-size: 10px;
            min-width: 100px;
            img {
              height: 13px;
              width: 13px;
              margin-right: 4px;
            }
          }
        }
      }
    }

    .application-card-body {
      h3 {
        font-size: 16px;
        color: #5B5A78;
        margin-top: 20px;
        margin-bottom: 10px;
        @media (max-width: 1366px) {
          margin-top: 10px;
        }
        @media (max-width: 1200px) {
          margin-top: 8px;
        }
      }

      p {
        color: #5B5A78;
        line-height: 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid #6F8BDF;
        @media (max-width: 1366px) {
          font-size: 14px;
          padding-bottom: 20px;
          line-height: 19px;
        }
      }
    }

    .application-card-footer {
      .button-wrapper {
        display: flex;
        margin-top: 30px;
        @media (max-width: 1366px) {
          margin-top: 20px;
        }

        .board-btn {
          flex-basis: 40%;
          @media (max-width: 1200px) {
            font-size: 10px;
          }

          &.orange-btn {
            margin: 0 25px;
            flex-basis: 30%;
            @media (max-width: 1200px) {
              margin: 0 15px;
              flex-basis: 25%;
            }
          }

          &.black-btn {
            flex-basis: 30%;
            @media (max-width: 1200px) {
              flex-basis: 25%;
            }
          }
        }
      }
    }
  }

  .card-spinner {
    height: 215px;
    min-height: 215px;
  }
}