.content-section{
    &.quil-section{
        padding: 37px 30px !important;
    }
.quil-editor-wrapper{
    .ql-toolbar, .ql-snow {
        border: 1px solid #6F8BDF;  
            border-radius: 16px 16px 0 0;
            border-bottom: none;
            background-color: #EFEFEF;
    }
    .quil-top-actions{
        display: flex;
        width: 100%;
        .input-field{
            flex-basis: 70%;
        }
        .action-wrapper{
            flex-basis: 30%;
            .upload-file-wrapper{
                height: 52px;
                min-height: 52px;
                margin-left: 10px;  
                .icon-wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin-right: 8px;
                    }
                }
            }
            .error-message{
                margin-left: 8px;
            }
        }
    }
    .quill{
        // .ql-toolbar{
        //     border: 1px solid #6F8BDF;  
        //     border-radius: 16px 16px 0 0;
        // }
        .ql-container{
            border: 1px solid #6F8BDF;  
            border-radius:  0 0 16px 16px;
            min-height: 622px;
        }
        .ql-container,.ql-toolbar{
            background-color: #EFEFEF;
        }
    }
    .button-wrapper{
        display: flex;
        justify-content: center;
        margin-top: 36px;
        .board-btn{
            min-width: 281px;
        }
    }
    .sound-actions-wrapper{
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    .sound-actions{
        display: flex;
        align-items: center;
        margin: 12px 0 32px;
        margin-left: 30px;
        &:first-of-type{
            margin-left: 0;
        }
        .button-wrapper{
            margin-top: 0;
            justify-content: unset;
        }
        .action{
            min-height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            background-color: #6F8BDF;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}
}