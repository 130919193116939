.profile-card-wrapper {
    flex-basis: 12.5%;
    @media (max-width: 1280px){
        flex-basis: 15.5%;
    }
    @media (max-width: 1024px){
        flex-basis: 17.5%;
    }
    @media (max-width: 768px){
        flex-basis: 20.5%;
    }

    .profile-card {
        background-color: #EFEFEF;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 9;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: 2px solid #6F8BDF;
        margin: auto;
        overflow: hidden;
        cursor: pointer;

        @media (min-width:1600px) {
            height: 110px;
            width: 110px;
        }
        @media (min-width:1920px) {
            height: 150px;
            width: 150px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            overflow: hidden;
            border-radius: 50%;
        }
    }

    .text {
        font-weight: 700;
        font-size: 18px;
        color: #2B292D;
        font-family: 'Lato';
        margin-top: 10px;
        // max-width: 110px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        @media (max-width:1366px) {
            font-size: 14px;
        }

        @media (max-width:1024px) {
            font-size: 13px;
        }
    }
}