.homepage-section {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;

    .main-section {
        width: 100%;
        // max-height: calc(100vh - 97px);
        max-height: 100vh;
        min-width: calc(100% - 312px);

        .content-section {
            padding: 38px 0 38px 15px;
            overflow: auto;
            max-height: calc(100vh - 91px);
            scrollbar-width: none;

            @media (max-width: 1366px) {
                max-height: calc(100vh - 91px);
            }

            @media (max-width: 1280px) {
                padding-left: 10px;
            }

            @media (max-width: 992px) {
                max-height: calc(100vh - 80px);
            }

            @media (max-width: 576px) {
                padding-left: 0;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .section-description {
                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 30px;
                    color: #000000;
                }
            }
            .home-card-wrapper {
                width: 100%;
                margin-bottom: 50px;

                @media (max-width: 1024px) {
                    margin-bottom: 0;
                }

                h2 {
                    font-family: "Lato";
                    font-weight: 700;
                    font-size: 24px;
                    color: #2b292d;
                    margin-bottom: 30px;

                    @media (max-width: 1366px) {
                        font-size: 20px;
                        margin-bottom: 20px;
                    }

                    @media (max-width: 1024px) {
                        margin: 20px 0;
                    }

                    @media (max-width: 1024px) {
                        font-size: 18px;
                    }
                }

                .home-card-row {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    scrollbar-width: none;
                    align-items: center;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                    .skeleton-list {
                        span {
                            display: flex;
                            gap: 0 5px;
                        }
                    }
                    .no-data {
                        text-align: center;
                    }

                    .card-data {
                        flex-basis: 14%;
                        max-width: 185px;
                        padding-right: 0;

                        @media (min-width: 1600px) {
                            max-width: 210px;
                        }

                        @media (min-width: 1680px) {
                            max-width: 240px;
                        }

                        @media (min-width: 1920px) {
                            max-width: 350px;
                        }

                        @media (min-width: 2100px) {
                            max-width: 400px;
                        }

                        @media (max-width: 1366px) {
                            max-width: 200px;
                        }

                        @media (max-width: 1280px) {
                            flex-basis: 16.66%;
                        }

                        @media (max-width: 1024px) {
                            flex-basis: 23%;
                            max-width: 140px;
                        }

                        @media (max-width: 768px) {
                            flex-basis: 27%;
                        }

                        @media (max-width: 576px) {
                            flex-basis: 37%;
                        }

                        @media (max-width: 470px) {
                            flex-basis: 45%;
                        }

                        @media (max-width: 400px) {
                            flex-basis: 55%;
                        }

                        &.card-toggle-flex {
                            flex-basis: 11.5%;

                            @media (max-width: 1280px) {
                                flex-basis: 13.5%;
                            }

                            @media (max-width: 1100px) {
                                flex-basis: 14.5%;
                            }

                            @media (max-width: 1024px) {
                                flex-basis: 15.5%;
                            }

                            @media (max-width: 992px) {
                                flex-basis: initial;
                            }
                        }
                    }

                    .slider-card {
                        background-color: #efefef;
                        width: 100%;
                        // @media (max-width:1440px) and (max-height:900px){
                        //    height: 191px;
                        //    max-width: 151px;
                        // }

                        @media (max-width: 1366px) {
                            max-width: 141px;
                        }

                        @media (min-width: 1600px) {
                            max-width: 200px;
                            height: 270px;
                        }

                        @media (min-width: 1920px) {
                            max-width: 270px;
                            height: 310px;
                        }

                        @media (min-width: 2100px) {
                            max-width: 310px;
                            height: 370px;
                        }

                        img {
                            opacity: 1;
                        }
                    }

                    .see-more {
                        height: 60px;
                        width: 60px;
                        border-radius: 20%;
                        background-color: #6f8bdf;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #efefef;
                        margin: 0 10px;
                        cursor: pointer;
                    }

                    .profile-card-wrapper {
                        padding-right: 0;
                    }
                }

                .book-detail-card-wrapper {
                    @media (max-width: 1280px) {
                        flex-basis: 30%;
                    }

                    @media (max-width: 768px) {
                        flex-basis: 35%;
                    }

                    @media (max-width: 650px) {
                        flex-basis: 45%;
                    }

                    @media (max-width: 576px) {
                        flex-basis: 55%;
                    }

                    @media (max-width: 440px) {
                        flex-basis: 80%;
                    }

                    &.detail-card-toggle-flex {
                        flex-basis: 21%;

                        @media (max-width: 1280px) {
                            flex-basis: 21%;
                        }

                        @media (max-width: 1100px) {
                            flex-basis: 26%;
                        }
                    }
                }
            }
        }
    }

    .toggle-sidebar-icon {
        display: none;

        @media (max-width: 992px) {
            display: block;
            min-height: 100vh;
            background-color: #efefef;
            width: 50px;
            text-align: center;
            padding-top: 30px;
            border-radius: 0 10px 10px 0;

            img {
                cursor: pointer;
            }
        }

        @media (max-width: 768px) {
            width: 40px;

            img {
                height: auto;
                width: 25px;
            }
        }

        @media (max-width: 576px) {
            padding-top: 20px;
        }
    }
}
