.book-detail-card-wrapper {
    flex-basis: 26%;
    max-width: 295px;
    display: flex;
    @media (max-width: 1440px){
        padding-right: 0 !important;
    }

    .card-details {
        background-color: #EFEFEF;
        border-radius: 16px;
        font-family: 'Lato';
        padding: 20px 0;
        width: 100%;
        @media (max-width: 1440px){
            max-width: 270px;
        }

        .card-description {
            max-width: 90%;
            margin: auto;

            h3 {
                font-weight: 600;
                font-size: 18px;
                color: #5B5A78;
                margin-bottom: 0;
                max-width: 90%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @media (max-width:1200px) {
                    font-size: 16px;
                    margin-bottom: 4px;
                }

                @media (max-width:768px) {
                    font-size: 14px;
                    margin-bottom: 4px;
                }
            }

            p {
                font-weight: 600;
                font-size: 24px;
                color: #2B292D;
                margin: 6px 0;
               

                @media (max-width:1200px) {
                    font-size: 18px;
                    margin-bottom: 4px;
                }

                @media (max-width: 1024px) {
                    font-size: 16px;
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                }

                &:nth-of-type(2) {
                    text-decoration: none;
                    color: #6F8BDF;
                    font-family: 'Inter';
                    font-size: 16px;

                    @media (max-width:1366px) {
                        font-size: 14px;
                        margin-bottom: 0;
                    }

                    @media (max-width:768px) {
                        font-size: 12px;
                    }
                }

                &.para-title {
                    max-width: 90%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

    }
    .details{
        cursor: pointer;
    }
}