.landing-page-section {
    &.admin-auth-page-section {
        background-image: url('../../../assets/images/login-bg.png');
        background-position: left;
        background-size: 87% 100%;
        justify-content: center;

        @media (max-width: 992px) {
            background-position: center;
            padding: 20px 0 80px;
        }

        @media (max-width: 576px) {
            background-size: 95% 100%;
        }

        .auth-content-row {
            position: relative;
            z-index: 9;
            padding: 0 50px;

            @media (max-width: 576px) {
                padding: 0 10px;
            }

            .auth-left-content {
                min-width: unset;
                margin: 75px auto;
                width: 87%;

                @media (max-width: 1024px) {
                    width: 80%;
                    margin: 25px auto;
                }

                @media (max-width: 576px){
                    width: 85%;
                }

                .content-details {
                    h1 {
                        font-weight: 700;
                        font-size: 64px;

                        @media (max-width: 1600px) and (max-height: 700px) {
                            font-size: 48px;
                        }

                        @media (max-width: 1440px) and (max-height: 900px) {
                            font-size: 48px;
                            line-height: 1;
                        }

                        @media (max-width: 1024px) {
                            font-size: 40px;
                        }

                        @media (max-width: 768px) {
                            font-size: 34px;
                        }

                        @media (max-width: 576px) {
                            font-size: 28px;
                        }
                    }

                    h2 {
                        margin-bottom: 30px;
                        white-space: wrap;

                        @media (max-width: 1366px) {
                            margin-bottom: 16px;
                            font-size: 18px;
                        }

                        @media (max-width: 1024px) {
                            font-size: 16px;
                        }

                        @media (max-width: 768px) {
                            font-size: 14px;
                        }

                        @media (max-width: 576px) {
                            font-size: 12px;
                        }
                    }
                }

                .input-wrapper {
                    input {
                        margin-top: 10px;
                    }

                    .error-message {
                        @media (max-width: 1366px) {
                            margin-top: 8px;
                        }
                    }
                }

                .button-wrapper {
                    display: flex;
                    width: 100%;

                    @media (max-width: 1440px) and (max-height: 900px) {
                        justify-content: unset;
                    }

                    @media (max-width: 992px) {
                        justify-content: center;
                    }

                    @media (max-width: 576px) {
                        margin-top: 10px;
                    }

                    .board-btn {
                        max-width: 100%;
                        flex-basis: 48%;

                        @media (max-width: 992px) {
                            max-width: 230px;
                        }

                        @media (max-width: 768px) {
                            max-width: 180px;
                            padding: 6px 0;
                            font-size: 12px;
                        }

                        @media (max-width: 576px) {
                            max-width: 150px;
                            padding: 4px 0;
                            font-size: 10px;
                            height: 26px;
                            border-radius: 8px;
                        }

                        &.transparent-btn {
                            margin-left: 40px;

                            @media (max-width: 1440px) and (max-height: 900px) {
                                margin-left: 20px;
                            }

                            @media (max-width: 768px) {
                                margin-left: 12px;
                            }
                        }
                    }

                    a {
                        text-decoration: none;
                    }

                    &.forgot-password-button-wrapper {
                        justify-content: space-between;

                        @media (max-width: 992px) {
                            justify-content: center;
                        }


                        .board-btn {
                            &.reset-btn {
                                @media (max-width: 1440px) and (max-height: 900px) {}

                                @media (max-width: 768px) {}
                            }
                        }
                    }

                    &.confirm-password-button-wrapper {
                        justify-content: center;
                    }

                }

                h2 {
                    padding-right: 27%;

                    @media (max-width:1300px) {
                        font-size: 18px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 2px;
                        transform: translateY(-50%);
                        width: 25%;
                        height: 5px;
                        background-color: #F6B21A;
                        border-radius: 56px;

                        @media (max-width:1300px) {
                            height: 4px;
                        }
                    }

                    &.forgot-title {
                        padding-right: 8%;

                        @media (max-width: 1366px) {
                            padding-right: 18%;
                        }

                        @media (min-width: 1680px) {
                            padding-right: 22%;
                        }

                        &::after {
                            width: 11%;

                            @media (max-width: 1366px) {
                                width: 18%;
                            }

                            @media (min-width:1680px) {
                                width: 20%;
                            }
                        }
                    }
                }
            }

            .input-field input {
                @media (max-width: 768px) {
                    padding: 2px 10px 2px 35px
                }
            }

            .auth-right-content {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                img {
                    @media (max-width: 1600px) {
                        width: 400px;
                    }


                    @media (max-width: 1366px) {
                        width: 350px;
                    }

                    @media (max-width: 1024px) {
                        width: 250px;
                    }

                    @media (max-width: 576px) {
                        width: 200px;
                    }

                    @media (min-width:1920px) {
                        width: 600px;
                    }
                }
            }

        }

    }
}